import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { COMMUNITY_REPORT_TYPE } from './constant';
import Title from 'src/refactor_components/common/Title';

const CommunityReport = () => {
  return (
    <PrevHeaderBarWithTitleTemplate
      title="질문 글 신고하기"
      headerbarType={'relative'}
    >
      <div className="flex flex-col gap-20 pt-20 pb-20 pl-20">
        <div className="flex flex-col gap-12">
          <h3 className="leading-21 font-medium text-[#C9C7D1]">신고 사유</h3>
          {COMMUNITY_REPORT_TYPE.map(type => (
            <span className="w-full py-[16.5px] px-16 bg-[#1F1E25] rounded-8 border-1 border-solid border-[#2E2B36]">
              {type}
            </span>
          ))}
        </div>
        <div className="flex flex-col gap-8">
          <Title text="내용" essential={true}>
            <span className="text-[#9170F2] text-[12px]">최대 500자</span>
          </Title>
          <textarea
            className="focus:bg-white focus:text-black w-full bg-transparent border border-[#494949] p-10 text-16 rounded-[5px] h-300 placeholder:text-[#999]"
            placeholder="질문 내용을 자세히 작성해주세요"
          />
        </div>
        <button className="px-20 py-16 bg-[#E13A3A] text-center rounded-8">
          신고하기
        </button>
      </div>
    </PrevHeaderBarWithTitleTemplate>
  );
};

export default CommunityReport;
