import DegreeIcon from '#assets/svg/graduation-cap.svg';
import AwardIcon from '#assets/svg/awards_icon.svg';
import Certificate from '#assets/svg/certification_icon.svg';

import { Dispatch, PropsWithChildren, ReactNode } from 'react';
import { BoxIcon } from '#atoms';

type TrainerQualificationProps = {
  degree: {
    major: string;
  }[];
  licenses: {
    name: string;
  }[];
  awards: number;
};

const QualificationItem = ({ children }: { children: ReactNode }) => {
  return (
    <div className="w-[calc(34%-12px)] h-80 text-center flex flex-col gap-8 mx-0 my-auto flex-wrap">
      {children}
    </div>
  );
};

const QualificationMore = ({
  onHandleCustomClick,
  targetTab
}: {
  onHandleCustomClick?: Dispatch<number>;
  targetTab: number;
}) => {
  return (
    <button
      type="button"
      onClick={() => onHandleCustomClick && onHandleCustomClick(targetTab)}
      className="text-[14px] text-[#8E899F] text-center w-full border-0 pt-16 border-t-1 border-solid border-[#24222A]"
    >
      <button className="w-full flex justify-center items-center gap-4">
        자세히 보기
        <BoxIcon type="next-arrow" onlyIcon size={10} />
      </button>
    </button>
  );
};

const TrainerQualification = ({
  data,
  title,
  children
}: PropsWithChildren<{ data: TrainerQualificationProps; title: string }>) => {
  return (
    <div className="flex flex-col gap-32">
      {data?.awards || data?.degree?.length || data?.licenses?.length ? (
        <>
          <p className="text-[18px] font-bold">{title}</p>
          <div className="flex gap-12 flex-wrap justify-evenly items-center">
            {data
              ? data.degree.map((value, index) => {
                  return (
                    <QualificationItem key={index}>
                      <img src={DegreeIcon} alt="icon" />
                      <p className="text-[12px]">{value.major}</p>
                    </QualificationItem>
                  );
                })
              : null}
            {data
              ? data.licenses.map((value, index) => {
                  return (
                    <QualificationItem key={index}>
                      <img src={Certificate} alt="icon" />
                      <p className="text-[12px]">{value.name}</p>
                    </QualificationItem>
                  );
                })
              : null}
            {data && data.awards ? (
              <QualificationItem>
                <img src={AwardIcon} alt="icon" />
                <p className="text-[12px]">수상경력 {data.awards}회</p>
              </QualificationItem>
            ) : null}
          </div>
        </>
      ) : null}

      <div className="flex flex-col gap-12">{children}</div>
    </div>
  );
};

TrainerQualification.More = QualificationMore;
export default TrainerQualification;
