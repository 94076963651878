import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { Modal } from '#molecules';

import { useGetDiseaseKinds } from '#api/disease_kind';
import { useGetPainPartKinds } from '#api/pain-part-kinds';

import HealthInfoOptions from 'src/r_page/Information-community/Write/StepOptions';
import HealthInfoSelect from 'src/r_page/Information-community/Write/HealthInfoSelect';
import Step2ConfirmButton from 'src/r_page/Information-community/Write/Step/Step2ConfirmButton';

import { HEALTH_INFO_REGISTERNAMES_PARSE } from 'src/r_page/Information-community/constant';
import { useFormContext } from 'react-hook-form';

interface HealthInfoSelectModalProps {
  setAdditionalHealthInfoModalOpen: Dispatch<SetStateAction<string>>;
  healthInfoType: string;
}
const HealthInfoSelectModal = ({
  setAdditionalHealthInfoModalOpen,
  healthInfoType
}: HealthInfoSelectModalProps) => {
  const methods = useFormContext();
  const [option, setOption] = useState<number | null>(null);
  const [intensity, setIntensity] = useState<string | number | null>(null);
  const { data: diseaseData } = useGetDiseaseKinds(healthInfoType);
  const { data: painPartData } = useGetPainPartKinds(healthInfoType);

  const optionList = useMemo(() => {
    if (healthInfoType === 'disease') {
      return diseaseData;
    } else if (healthInfoType === 'painParts') {
      return painPartData;
    }
  }, [diseaseData, painPartData, healthInfoType]);

  const onModalSubmit = () => {
    if (optionList && option !== null) {
      methods.setValue(healthInfoType, [
        ...methods.getValues(healthInfoType),
        { ...optionList[option], level: intensity }
      ]);
    }
    setAdditionalHealthInfoModalOpen('');
  };

  return (
    <Modal onClose={() => setAdditionalHealthInfoModalOpen('')}>
      <div className="flex flex-col w-full gap-24">
        <h1 className="font-semibold text-[20px] text-[#BCB8C3]">
          {HEALTH_INFO_REGISTERNAMES_PARSE[healthInfoType]}
        </h1>
        <div className="flex flex-col gap-20">
          <HealthInfoOptions
            healthInfoType={healthInfoType}
            optionList={optionList}
            setOption={setOption}
          />
          <HealthInfoSelect
            healthInfoType={healthInfoType}
            setIntensity={setIntensity}
          />
        </div>
      </div>
      <Step2ConfirmButton
        setIsOpen={setAdditionalHealthInfoModalOpen}
        onModalSubmit={onModalSubmit}
      />
    </Modal>
  );
};

export default HealthInfoSelectModal;

// {
//   "height": 0,
//   "weight": 0,
//   "sleepingHourAvg": 0,
//   "qualityOfSleep": 0,
//   "diseases": [
//     {
//       "healthInfoId": "string",
//       "diseaseKindId": "string",
//       "name": "string",
//       "level": 0
//     }
//   ],
//   "painParts": [
//     {
//       "healthInfoId": "string",
//       "kindId": "string",
//       "name": "string",
//       "level": 0
//     }
//   ],
//   "surgeryParts": [
//     {
//       "healthInfoId": "string",
//       "surgeryPartKindId": "string",
//       "name": "string",
//       "isCured": true
//     }
//   ],
//   "exerciseExperienceParts": [
//     {
//       "healthInfoId": "string",
//       "kindId": "string",
//       "name": "string",
//       "level": 0
//     }
//   ]
// }
