export const TRANIER_COMMUNITY_TPYE_LIST: Record<string, Array<string>> = {
  'trainer-community': ['ALL', '칼럼', '노하우', '고민', '일상'],
  'article-community': [
    'ALL',
    // '인기글',
    '운동 방법',
    '부상 / 재활',
    '영양 / 식단',
    '다이어트 / 벌크업',
    '대회 / 바디프로필'
  ]
};

export const INFORMATION_QUESTION_TYPE_LIST = [
  {
    label: '운동 방법',
    value: 'METHOD'
  },
  {
    label: '부상 / 재활',
    value: 'INJURY_REHABILITATION'
  },
  {
    label: '영양 / 식단',
    value: 'NUTRITION_DIET'
  },
  {
    label: '다이어트 / 벌크업',
    value: 'LOSE_WEIGHT_BULK_UP'
  },
  {
    label: '대회 / 바디 프로필',
    value: 'COMPETITION_BODY_PROFILE'
  }
];

export const HEALTH_INFO_REGISTERNAMES = [
  {
    label: '운동 경력',
    registerName: 'career'
  },
  {
    label: '병력',
    registerName: 'disease'
  },
  {
    label: '수술 이력 / 통증',
    registerName: 'painParts'
  }
];

export const HEALTH_INFO_REGISTERNAMES_PARSE: Record<string, string> = {
  career: '운동 경력',
  disease: '병력',
  painParts: '수술 이력 / 통증'
};
