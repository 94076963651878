import { InfiniteQueryObserverResult } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';

const useObserver = (
  fetchNextPage: () => Promise<InfiniteQueryObserverResult>,
  hasNextPage?: boolean
) => {
  const targetRef = useRef<HTMLDivElement>(null);

  const observeOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.3
  };

  const observer = new IntersectionObserver(entries => {
    const entry = entries[0];

    if (entry.isIntersecting) {
      fetchNextPage();
    } else {
      return;
    }
  }, observeOptions);

  useEffect(() => {
    if (targetRef.current === null) return;

    observer.observe(targetRef.current);

    if (!hasNextPage) observer.unobserve(targetRef.current);

    return () => {
      observer.disconnect();
    };
  }, [targetRef, hasNextPage]);

  return [targetRef] as const;
};

export default useObserver;
