import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 12px;
  font-size: 14px;

  border-bottom: 1px solid #1f1e25;
  padding: 12px 0;
  cursor: pointer;
`;

export const QuestionTitle = styled.h3`
  overflow: scroll;
  color: var(--gray-15---main-font, #eae8ed);
  text-overflow: ellipsis;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.3px;
`;

export const Questionername = styled.div`
  color: #8e899f;
`;

export const QuestionInfo = styled.div`
  font-size: 12px;
  color: #5c576b;
`;

export const Slash = styled.div`
  color: var(--gray-600---border2, #2e2b36);
  text-align: center;
`;

export const QuestionInfoWithSlash = styled.div`
  border-left: 1px solid #2e2b36;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 2px 0 4px;
  color: #8e899f;
  max-width: 90px;
`;
