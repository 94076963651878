import { Link } from 'react-router-dom';

import * as S from './style';
import useDateFormatter from 'src/refactor_shared/hooks/useDateFormatter';

export type ThreadType = {
  id: string;
  gyms?: { id: string; name: string }[];
  writerId: string;
  category: string;
  sequence: number;
  title: string;
  createdAt: string;
  image: string | null;
  answerCount: number;
  name: string;
  startAthletic: string;
};

const CommunityFeeds = <T,>({
  to,
  thread
}: {
  to: string;
  thread: T extends ThreadType ? T : never;
}) => {
  const date = useDateFormatter(thread.createdAt);
  return (
    <Link to={to}>
      <S.Container>
        <div className="flex flex-col gap-4 h-70">
          <div className="w-fit rounded-8 text-[10px] text-[#8E899F] bg-[#2E2B36] py-4 px-8">
            {PARSE_CATEGORY[thread.category]}
          </div>
          <S.QuestionTitle className="line-clamp-1">
            {thread.title}
          </S.QuestionTitle>
          <div className="flex gap-4 text-[12px] font-normal leading-10">
            <S.Questionername>{thread.name}</S.Questionername>
            <S.QuestionInfoWithSlash>{date}</S.QuestionInfoWithSlash>
            <IsAnswer to={to} answerCount={thread.answerCount} />
          </div>
        </div>
        {thread.image && (
          <img
            src={thread.image}
            className="object-cover w-70 h-70 rounded-8"
          />
        )}
      </S.Container>
    </Link>
  );
};

const IsAnswer = ({ to, answerCount }: { to: string; answerCount: number }) => {
  return (
    <S.QuestionInfoWithSlash>
      {to.includes('qna') ? '답변' : '댓글'} {answerCount}
    </S.QuestionInfoWithSlash>
  );
};

export default CommunityFeeds;

export const PARSE_CATEGORY: Record<string, string> = {
  ALL: '전체',
  KNOWHOW: '노하우',
  DAILY: '일상',
  CONCERN: '고민',
  COLUMN: '칼럼',
  METHOD: '운동 방법',
  INJURY_REHABILITATION: '부상 / 재활',
  NUTRITION_DIET: '영양 / 식단',
  LOSE_WEIGHT_BULK_UP: '다이어트 / 벌크업',
  COMPETITION_BODY_PROFILE: '대회 / 바디 프로필'
};
