import { Route, Routes } from 'react-router';
import CommunityPage from '../../../r_page/community';
import CommunityReport from 'src/r_page/community/Report';

import InformationCommunityFeed from 'src/r_page/Information-community/Feed';
import InformationCommunitySave from 'src/r_page/Information-community/Save';
import InformationCommunityWrite from 'src/r_page/Information-community/Write';

import QnaCommunityFeed from 'src/r_page/qna-community/Feed';
import QnaCommunitySave from 'src/r_page/qna-community/Save';

export function CommunityView() {
  return (
    <Routes>
      <Route path="report" element={<CommunityReport />} />

      <Route path="information/">
        <Route index element={<CommunityPage />} />
        <Route path="save" element={<InformationCommunitySave />} />
        <Route path=":id" element={<InformationCommunityFeed />} />
      </Route>

      <Route path="qna/">
        <Route index element={<CommunityPage />} />
        <Route path="write" element={<InformationCommunityWrite />} />
        <Route path="save" element={<QnaCommunitySave />} />
        <Route path=":id" element={<QnaCommunityFeed />} />
        <Route path="edit/:id" element={<InformationCommunityWrite />} />
      </Route>
    </Routes>
  );
}
