import styled from 'styled-components';

interface ChipButtonProps {
  isSelected?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  gap: 0 8px;
  cursor: pointer;

  position: relative;

  /* ${({ theme }) => theme.media.mobile} {
    width: 100;
    left: -20px;
  } */
`;

export const ChipItem = styled.div`
  position: relative;

  /* ${({ theme }) => theme.media.mobile} {
    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  } */
`;

export const ChipButton = styled.label<ChipButtonProps>`
  display: block;
  padding: 8px 12px;
  border-radius: 50px;
  color: #c9c7d1;
  white-space: nowrap;
  min-width: 48px;
  text-align: center;
  cursor: pointer;
  background-color: #2e2b36;
  font-size: 14px;
  /* ${({ theme }) => theme.typography('$body_03')}; */
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;

  &:checked + ${ChipButton} {
    background-color: #7750e9;
    color: #fff;
  }
  &:disabled + ${ChipButton} {
    background-color: #7750e9;
    color: #fff;
  }
`;
