export const paramsSetter = (search: string, tag: string) => {
  const params = new URLSearchParams();

  if (search) {
    params.append('search', search);
  }

  if (tag) {
    // PARSE_CATEGORY에서 tag(value)에 해당하는 key를 찾음
    const category = Object.entries(PARSE_CATEGORY).find(
      ([, value]) => value === tag
    )?.[0]; // key를 가져옴 (value === tag인 경우)

    if (category) {
      params.append('category', decodeURIComponent(category));
    }
  }

  return params;
};

const PARSE_CATEGORY: Record<string, string> = {
  // 전체: 'ALL',
  KNOWHOW: '노하우',
  DAILY: '일상',
  CONCERN: '고민',
  COLUMN: '칼럼',
  // TEST: '인기글',
  METHOD: '운동 방법',
  INJURY_REHABILITATION: '부상 / 재활',
  NUTRITION_DIET: '영양 / 식단',
  LOSE_WEIGHT_BULK_UP: '다이어트 / 벌크업',
  COMPETITION_BODY_PROFILE: '대회 / 바디프로필'
};
