import { useState } from 'react';
import SearchIcon from '../community/Icon/SearchIcon';
import { LocationItem, Modal, PriceModal } from '#molecules';
import PlaceIcon from 'src/assets/Icon/PlaceIcon.svg';
import { CategorySelectorContainer } from 'src/components/pages/Coach/styles';
import { useNavigate } from 'react-router';
import { CoachGridSelector } from '#organisims';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { $modalState } from '#states/atoms/modalState';
import SearchBar from 'src/refactor_components/common/SearchBar';
import BoldCancelIcon from 'src/assets/Icon/BoldCancelIcon.svg';
import { isUser } from '#utils/isUser';

const TrainerList = () => {
  const [showModal, setShowModal] = useRecoilState($modalState);
  const [isSearching, setIsSearching] = useState(false);
  const [location, setLocation] = useState('');

  console.log('location', location);
  const categories = ['전체', '원포인트 레슨'];
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState('');
  const [searchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState(
    searchParams.get('tab') || '전체'
  );

  return (
    <div className="px-20">
      <div className="flex flex-col gap-12">
        <div className="flex items-center justify-between py-16 text-[20px] text-[#C9C7D1] font-semibold leading-26px">
          {!isSearching ? (
            <>
              내 주변 트레이너
              <SearchIcon onClick={() => setIsSearching(true)} />
            </>
          ) : (
            <>
              <SearchBar
                setSearchData={setSearchData}
                placeholder="이름, 전문 분야를 검색해 보세요"
              />
              <button onClick={() => setIsSearching(false)}>
                <img src={BoldCancelIcon} alt="" />
              </button>
            </>
          )}
        </div>
        <div className="flex flex-col gap-12">
          <div className="flex items-center gap-3 py-8">
            <img src={PlaceIcon} alt="" />
            <LocationItem setLoc={setLocation} />
          </div>
          <CategorySelectorContainer>
            {/* <CategorySelector /> */}
            {categories.map(category => (
              <button
                className={`w-[50%] text-center py-[1rem] px-[1.5rem] rounded-12 ${
                  selectedCategory === category
                    ? 'text-[#fff] bg-[#7750E9]'
                    : 'bg-[#242327] text-[#959595]'
                }`}
                key={category}
                //   isSelected={selectedCategory === category}
                onClick={() => {
                  navigate(`/trainerList?tab=${category}`);
                  setSelectedCategory(category);
                }}
              >
                {category}
              </button>
            ))}
          </CategorySelectorContainer>
        </div>
      </div>
      {selectedCategory && (
        <CoachGridSelector
          tab={selectedCategory}
          loc={location}
          searchData={searchData}
        />
      )}
      {showModal && <PriceModal setShowModal={setShowModal} />}
    </div>
  );
};

export default TrainerList;
