import { AltImage } from '#assets/svg';
import { RoundIcon, Text } from '#atoms';
import { CoachAccount } from '#types/account';
import { getCoachCareer } from '#utils/timeFormatter';
import { Link } from 'react-router-dom';
import {
  CoachInfoItemWrap,
  GradeArea,
  ImageArea,
  InfoArea,
  NameArea,
  PriceArea
} from './styles';

interface Props {
  data: CoachAccount;
  onClick?: () => void;
}

export const CoachInfoItem = ({ data, onClick }: Props) => {
  const {
    name,
    profile,
    shortIntroduction,
    myUrl,
    startAthletic,
    distance,
    price,
    priceType,
    round,
    clinicalDataCount
  } = data;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Link
      to={`/expert/${encodeURIComponent(myUrl || '')}`}
      style={{
        width: '100%',
        display: 'block'
      }}
    >
      <CoachInfoItemWrap onClick={handleClick}>
        <ImageArea className={profile.length ? '' : 'alt'}>
          {profile.length ? <img src={profile[0].resizedLink} /> : <AltImage />}
        </ImageArea>
        <InfoArea>
          <NameArea>
            <Text textStyle="bb">{name} 전문가</Text>
            {distance !== undefined && (
              <Text textStyle="c2">{(distance / 1000).toFixed(1)}km</Text>
            )}
          </NameArea>
          {shortIntroduction && (
            <Text textStyle="c2" className="short-intro">
              {shortIntroduction}
            </Text>
          )}
          <div>
            <PriceArea data-text-color="point4">
              <RoundIcon text="PT" />
              <Text textStyle="c2" className="bold">
                {`${priceType === 'OT' ? round ?? 1 : 1}회`}{' '}
                {(priceType === 'OT'
                  ? price * (round ?? 1)
                  : price
                )?.toLocaleString('ko-KR')}
                원
              </Text>
            </PriceArea>
            <GradeArea>
              {/* <Star />
            <Text textStyle="c2">{Number(grade).toFixed(1)}</Text>{' '} */}
              <Text textStyle="c2">경력 {getCoachCareer(startAthletic)}년</Text>
              <Text textStyle="c2">트레이닝 성과 {clinicalDataCount}건</Text>
              {/* <Text textStyle="c2">수업리뷰 {reviewCount}건</Text> */}
            </GradeArea>
          </div>
        </InfoArea>
      </CoachInfoItemWrap>
    </Link>
  );
};
