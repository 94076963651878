import { Text } from '#atoms';
import Verified from '#assets/svg/verified.svg';

import { dottedDateFormatter } from '#utils/dateFormatter';

import { IBase } from '#types/api';
import { CoachInfoBox, DetailList, InfoDetailBox, LabelBox } from './style';
import EmptyContent from 'src/refactor_shared/ui/empty-content';

interface CriteriaItemsInterface extends IBase {
  [key: string]: string;
  startAt: string;
  endAt: string;
  at: string;
  gotAt: string;
  name: string;
}

const Criterias = ({
  criteriaItems,
  criteriaTitle,
  startTimeParam = 'startAt',
  endTimeParam = 'endAt',
  certificateParam,
  subCriteriaTitle
}: {
  criteriaItems: CriteriaItemsInterface[];
  criteriaTitle: string;
  startTimeParam?: string;
  endTimeParam?: string;
  certificateParam?: string;
  subCriteriaTitle?: string;
}) => {
  if (!criteriaItems || !criteriaItems?.length) return <></>;
  return (
    <>
      <CoachInfoBox>
        <LabelBox>
          <Text textStyle="t3">{criteriaTitle}</Text>
        </LabelBox>
        <DetailList>
          {criteriaItems
            .filter(item => item.status === 'NORMAL')
            .map(item => (
              <InfoDetailBox key={item.id}>
                <div className="flex justify-between items-center gap-16">
                  <div>
                    <p className="flex items-center gap-4">
                      <span>{item.name}</span>
                      <span>
                        {subCriteriaTitle ? item[subCriteriaTitle] : null}
                      </span>
                      {item.isVerified ? <img src={Verified} /> : null}
                    </p>
                    <div className="text-[12px] text-[#8E899F]">
                      {certificateParam ? (
                        <p>
                          {item[certificateParam]
                            ? dottedDateFormatter(item[certificateParam])
                            : ''}
                        </p>
                      ) : (
                        <p className="flex itemcenter gap-4">
                          <span>
                            {item[startTimeParam]
                              ? dottedDateFormatter(item[startTimeParam])
                              : ''}
                          </span>
                          {item[startTimeParam] ? <span>~</span> : null}
                          <span>
                            {item[endTimeParam]
                              ? dottedDateFormatter(item[endTimeParam])
                              : ''}
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </InfoDetailBox>
            ))}
        </DetailList>
      </CoachInfoBox>
    </>
  );
};

export default Criterias;
