import classNames from 'classnames';
import React, { useState } from 'react';
import { NextArrow } from '#assets/svg';
import { ICoachLectureState } from '#states/atoms/coachLectureState';
import { SelectBoxWrap, PlaceholderItem, Item } from './styles';
import { ISelectedLecture } from './types';
import { IGetLecture } from '#types/api';
import { Lecture } from '#types/lectures';

interface Props {
  placeholder: string;
  data: Lecture[];
  selectedItemName: string | undefined;
  setSelectedItem: React.Dispatch<
    React.SetStateAction<ISelectedLecture | null>
  >;
}

export const SelectBox = ({
  placeholder,
  data,
  setSelectedItem,
  selectedItemName
}: Props) => {
  const [showOptions, setShowOptions] = useState(false);

  const handleBoxClick = (event: React.MouseEvent<HTMLUListElement>) => {
    const target = event.target as HTMLUListElement;
    if (!showOptions) {
      setShowOptions(true);
      return;
    }

    if (target.tagName === 'LI') {
      setSelectedItem({
        id: target.dataset['lectureId'] ?? '',
        name: target.innerText
      });
    }

    setShowOptions(false);
  };

  return (
    <SelectBoxWrap
      onClick={handleBoxClick}
      className={classNames({ on: showOptions })}
    >
      <PlaceholderItem>
        {selectedItemName && !showOptions ? selectedItemName : placeholder}
        <NextArrow />
      </PlaceholderItem>
      {data.map(item => (
        <Item key={item.id} data-lecture-id={item.id}>
          {item.title}
        </Item>
      ))}
    </SelectBoxWrap>
  );
};
