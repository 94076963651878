import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 939px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: #756f7e;
`;

export const Wrap = styled.div`
  display: flex;

  width: 100%;
  height: 50px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100px;
  height: 50px;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.066px;
`;
