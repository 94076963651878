import { Switch } from '#atoms';
import { Dispatch, SetStateAction } from 'react';

const WriteSwitch = ({
  title,
  initState,
  onToggle
}: {
  title: string;
  initState: boolean;
  onToggle: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div className="flex items-center justify-between">
      {title}
      <Switch onToggle={() => onToggle(prev => !prev)} initState={initState} />
    </div>
  );
};

export default WriteSwitch;
