interface Props {
  url: string;
  className?: string;
  controls?: boolean;
}

export function VideoRenderer({
  url,
  className = 'w-90 h-90 object-cover',
  controls
}: Props) {
  const extractThumnail = `${url}#t=0.001`;
  return (
    <>
      <video
        playsInline={true}
        src={extractThumnail}
        preload="metadata"
        className={className}
        controls={controls}
      >
        <source src={extractThumnail} type="video/mp4" />
      </video>
    </>
  );
}
