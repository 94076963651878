import { ReactNode } from 'react';

const EmptyContent = ({ children }: { children: ReactNode }) => {
  return (
    <div className="text-[#5C576B] text-[14px] flex justify-center items-center px-24 py-20 rounded-8 border border-solid border-[#24222A]">
      {children}
    </div>
  );
};

export default EmptyContent;
