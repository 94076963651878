import { useQuery } from '@tanstack/react-query';
import { axios } from '#api/axios';

import TrainerQualification from 'src/refactor_components/trainer-profile/trainer-qualification';
import Criterias from 'src/refactor_components/my-page/profile/criterias';

type TrainerInfoProps = {
  qualification: {
    degree: {
      major: string;
    }[];
    licenses: {
      name: string;
    }[];
    awards: number;
  };
};

const TrainerInfo = ({ data, id }: { data: TrainerInfoProps; id: string }) => {
  const { data: coachInfo } = useQuery({
    queryKey: ['get-trainer-qualification', id],
    queryFn: async () => await axios.get(`accounts/coach/qualification/${id}`),
    select: data => data.data,
    enabled: !!id
  });

  return coachInfo ? (
    <div className="px-20 py-40 flex flex-col gap-60 w-full">
      <TrainerQualification data={data.qualification} title="검증된 자격사항">
        <div className="flex flex-col">
          <Criterias
            criteriaItems={coachInfo?.degrees}
            criteriaTitle="학력"
            startTimeParam="from"
            endTimeParam="at"
            subCriteriaTitle="major"
          />
          <Criterias criteriaItems={coachInfo?.careers} criteriaTitle="경력" />
          <Criterias
            criteriaItems={coachInfo?.licenses}
            criteriaTitle="자격증"
            certificateParam="gotAt"
          />
          <Criterias
            criteriaItems={coachInfo?.awards}
            criteriaTitle="수상"
            certificateParam="gotAt"
          />
          <Criterias
            criteriaItems={coachInfo?.educations}
            criteriaTitle="교육"
            certificateParam="gotAt"
          />
        </div>
      </TrainerQualification>
    </div>
  ) : null;
};

export default TrainerInfo;
