const BookmarkQna = () => {
  return (
    <div className="flex flex-col gap-16 border-b-1 border-solid border-[#24222A]">
      <div className="flex font-bold gap-8">
        <p className="text-[#9170F2]">Q.</p>
        <p className="text-[#FFFFFF]">
          교통사고 후유증으로 인해 하체 근육 불균형이 생겼는데 웨이트 어떻게
          할지 모르겠어요
        </p>
      </div>
      <div className="text-[14px] rounded-12 rounded-tl-0 bg-[#1F1E25] ml-24 flex flex-col gap-5">
        <p className="text-[#9170F2]">김나나 트레이너의 답변</p>
        <p className="text-[#8E899F]">2024.05.24</p>
        <p className="text-[#EAE8ED]">
          천천히 양쪽 근육이 균형을 잡을 때까지 상대적으로 약한 곳의 웨이트를
          지속적으로 늘려주세요.
        </p>
      </div>
    </div>
  );
};

export default BookmarkQna;
('');
