import { useGetHealthInfos } from '#api/health-infos';

import { HEALTH_INFO_HEIGHT } from '../constant';

const WriteBodyInfo = () => {
  const { data } = useGetHealthInfos();

  return (
    <div className="w-full flex bg-[#1F1E25] py-16 px-12 rounded-12">
      {HEALTH_INFO_HEIGHT.map(info => (
        <div
          key={info.label}
          className="flex flex-col items-center justify-center w-full gap-12"
        >
          <span className="text-[#5C576B] text-[14px]">{info.label}</span>
          <div className="flex gap-2 font-medium text-[16px] text-[#C9C7D1] bg-[#2E2B36] text-center rounded-4 p-8">
            {data ? data[info.value] : ''}
            <span className="text-[#8E899F]">{info.measurement}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default WriteBodyInfo;
