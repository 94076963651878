export const TRANIER_COMMUNITY_TPYE_LIST: Record<string, Array<string>> = {
  'trainer-community': ['ALL', '칼럼', '노하우', '고민', '일상'],
  'information-community': [
    'ALL',
    // '인기글',
    '운동 방법',
    '부상 / 재활',
    '영양 / 식단',
    '다이어트 / 벌크업',
    '대회 / 바디프로필'
  ]
};
