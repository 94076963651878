import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  padding: 20px;
  padding-top: 60px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
`;
