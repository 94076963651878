interface Props {
  onClick?: () => void;
  width?: string;
  height?: string;
}
const SaveIcon = ({ onClick, height, width }: Props) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      cursor={'pointer'}
    >
      <path
        d="M19 3H5.01L5 21L12 18L19 21V3ZM17 18L12 15.82L7 18V5H17V18Z"
        fill="#8E899F"
      />
    </svg>
  );
};

export default SaveIcon;
