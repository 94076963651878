export const ShareIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
    >
      <path
        d="M10 9.71992C9.49333 9.71992 9.04 9.91992 8.69333 10.2333L3.94 7.46659C3.97333 7.31325 4 7.15992 4 6.99992C4 6.83992 3.97333 6.68659 3.94 6.53325L8.64 3.79325C9 4.12659 9.47333 4.33325 10 4.33325C11.1067 4.33325 12 3.43992 12 2.33325C12 1.22659 11.1067 0.333252 10 0.333252C8.89333 0.333252 8 1.22659 8 2.33325C8 2.49325 8.02667 2.64659 8.06 2.79992L3.36 5.53992C3 5.20659 2.52667 4.99992 2 4.99992C0.893333 4.99992 0 5.89325 0 6.99992C0 8.10659 0.893333 8.99992 2 8.99992C2.52667 8.99992 3 8.79325 3.36 8.45992L8.10667 11.2333C8.07333 11.3733 8.05333 11.5199 8.05333 11.6666C8.05333 12.7399 8.92667 13.6133 10 13.6133C11.0733 13.6133 11.9467 12.7399 11.9467 11.6666C11.9467 10.5933 11.0733 9.71992 10 9.71992Z"
        fill="#A9A5B6"
      />
    </svg>
  );
};
