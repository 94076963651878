import { XButton } from '#assets/svg';
import { BoxIcon, Text } from '#atoms';
import { isFunction } from 'lodash';
import React, { PropsWithChildren, ReactNode, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  ButtonPrev,
  ChildrenContainer,
  Container,
  HeaderConatiner,
  RightContents
} from './styles';

interface Props {
  prevRoute?: string;
  title: string;
  headerbarType?: 'absolute' | 'relative';
  defaultBackground?: boolean;
  willBeScroll?: boolean;
  rightContents?: ReactNode;
  onPrevCallback?: (prevRoute?: string) => void;
  childrenContainerStyle?: React.CSSProperties;
  /* BackButton의 onClick custom function */
  onPrev?: () => void;
  ContainerStyle?: React.CSSProperties;
  hasBorder?: boolean;
  hasXButton?: boolean;
  onXButtonClick?: () => void;
  onPreventPrevCallback?: (prevRoute?: string) => void;
  headerbarStyle?: React.CSSProperties;
  hide?: boolean;
}

export function PrevHeaderBarWithTitleTemplate({
  prevRoute,
  title,
  headerbarType = 'absolute',
  defaultBackground,
  rightContents,
  onPrevCallback,
  onPrev,
  hide,
  ContainerStyle,
  hasBorder = true,
  hasXButton,
  children,
  onXButtonClick,
  childrenContainerStyle = {},
  onPreventPrevCallback,
  headerbarStyle
}: PropsWithChildren<Props>) {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickArrow = useCallback(() => {
    // 기본 동작을 무시
    if (onPreventPrevCallback) {
      onPreventPrevCallback(location.pathname);
      return;
    }

    if (isFunction(onPrev)) {
      onPrev();
      return;
    }

    if (prevRoute) {
      navigate(prevRoute);
      return;
    } else {
      navigate(-1);
    }

    if (onPrevCallback) {
      onPrevCallback(location.pathname);
    }
  }, [prevRoute, location.pathname, onPrev]);

  return (
    <Container style={ContainerStyle}>
      <HeaderConatiner
        headerbarType={headerbarType}
        defaultBackground={defaultBackground}
        hasBorder={hasBorder}
        style={headerbarStyle}
      >
        {hasXButton ? (
          <XButton
            onClick={e => {
              e.stopPropagation();
              onXButtonClick && onXButtonClick();
            }}
          />
        ) : (
          <BoxIcon
            onClick={onClickArrow}
            type="prev-arrow"
            boxColor="transparent"
            size={18}
            onlyIcon
          />
        )}
        <Text textStyle="t3">{title}</Text>
        <RightContents>{rightContents}</RightContents>
      </HeaderConatiner>
      <ChildrenContainer
        style={childrenContainerStyle}
        headerbarType={headerbarType}
        id="child-container"
      >
        {children}
      </ChildrenContainer>
    </Container>
  );
}
