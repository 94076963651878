export const formatTime = (input: string) => {
  if (input.includes(':')) {
    const [hours, minutes = '00'] = input.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padEnd(2, '0')}`;
  } else if (input.length === 2) {
    return `${input.padStart(2, '0')}:00`;
  } else if (input.length === 3 || input.length === 4) {
    const hours = input.slice(0, -2);
    const minutes = input.slice(-2);
    return `${hours.padStart(2, '0')}:${minutes}`;
  } else if (input.length === 1) {
    return `0${input}:00`;
  } else {
    return '';
  }
};

export const formatLeftDays = (time: string) => {
  const parseDate = time.split('T')[0];
  const startDate = new Date(parseDate);
  const today = new Date();

  const timeDifference = Number(today) - Number(startDate);
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return { parseDate, daysDifference };
};
