import { useGetClinicalDataList } from '#api/clinical-data';
import { useGetCoachIdFromMyUrl } from '#api/my-url';
import { Text } from '#atoms';
import { ClinicalDataItem } from '#organisims';
import { useParams } from 'react-router';
import { Container } from './styles';
import EmptyContent from 'src/refactor_shared/ui/empty-content';

export function CoachClinicalDataPage() {
  const { myUrl } = useParams();
  const { data: coachId } = useGetCoachIdFromMyUrl(
    decodeURIComponent(myUrl || '') ?? ''
  );

  const { data: clinicalDataList } = useGetClinicalDataList({
    coachId: coachId ?? '',
    offset: 100
  });

  return (
    <div>
      <Container>
        <Text textStyle="bb">
          총 {clinicalDataList?.total}개의 트레이닝 성과
        </Text>
        {clinicalDataList?.total ? (
          clinicalDataList.data.map((data, idx) => (
            <ClinicalDataItem data={data} key={idx} />
          ))
        ) : (
          <EmptyContent>등록된 트레이닝 성과가 없습니다</EmptyContent>
        )}
      </Container>
    </div>
  );
}
