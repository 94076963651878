export const QUESTIONER_INFO_LIST = [
  {
    healthInfoType: '키'
  },
  {
    healthInfoType: '체중'
  }
];

export const QUESTION_SECOND_INFO_LIST = [
  {
    healthInfoType: '성별'
  },
  {
    healthInfoType: '병력'
  },
  {
    healthInfoType: '수술 이력'
  },
  {
    healthInfoType: '통증 (부위/정도)'
  }
];
