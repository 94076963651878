import { PropsWithChildren, ReactElement, useMemo, useState } from 'react';
interface FunnelStepInterface extends PropsWithChildren<{ step: string }> {}

const useFunnel = (initialStep: string) => {
  const [currentFunnelStep, setCurrentFunnelStep] = useState(initialStep);

  const Step = (props: FunnelStepInterface): ReactElement => {
    return <>{props.children}</>;
  };

  const Funnel = ({
    children
  }: {
    children: Array<ReactElement<{ step: string }>>;
  }) => {
    const currentStep = useMemo(
      () =>
        children.find(childStep => childStep.props.step === currentFunnelStep),
      [currentFunnelStep]
    );

    return <>{currentStep}</>;
  };

  return { Funnel, Step, currentFunnelStep, setCurrentFunnelStep } as const;
};

export default useFunnel;
