interface Props {
  isLike?: boolean;
}

const QuestionIcon = ({ isLike }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
    >
      <path
        d="M5.50004 6.83331C5.04004 6.83331 4.66671 7.20665 4.66671 7.66665C4.66671 8.12665 5.04004 8.49998 5.50004 8.49998C5.96004 8.49998 6.33337 8.12665 6.33337 7.66665C6.33337 7.20665 5.96004 6.83331 5.50004 6.83331ZM9.50004 6.83331C9.04004 6.83331 8.66671 7.20665 8.66671 7.66665C8.66671 8.12665 9.04004 8.49998 9.50004 8.49998C9.96004 8.49998 10.3334 8.12665 10.3334 7.66665C10.3334 7.20665 9.96004 6.83331 9.50004 6.83331ZM7.50004 0.333313C3.82004 0.333313 0.833374 3.31998 0.833374 6.99998C0.833374 10.68 3.82004 13.6666 7.50004 13.6666C11.18 13.6666 14.1667 10.68 14.1667 6.99998C14.1667 3.31998 11.18 0.333313 7.50004 0.333313ZM7.50004 12.3333C4.56004 12.3333 2.16671 9.93998 2.16671 6.99998C2.16671 6.80665 2.18004 6.61331 2.20004 6.42665C3.77337 5.72665 5.02004 4.43998 5.67337 2.84665C6.88004 4.55331 8.86671 5.66665 11.1134 5.66665C11.6334 5.66665 12.1334 5.60665 12.6134 5.49331C12.7534 5.96665 12.8334 6.47331 12.8334 6.99998C12.8334 9.93998 10.44 12.3333 7.50004 12.3333Z"
        fill={isLike ? '#9170F2' : '#A9A5B6'}
      />
    </svg>
  );
};

export default QuestionIcon;
