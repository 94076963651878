const PlaceholderIcon = ({
  isSelect,
  width,
  height,
  onClick
}: {
  width?: string;
  height?: string;
  isSelect?: string;
  onClick?: () => void;
}) => {
  return (
    <svg
      onClick={onClick}
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#24222A" />
      <mask
        id="mask0_7487_6140"
        maskUnits="userSpaceOnUse"
        x="10"
        y="10"
        width="28"
        height="28"
      >
        <path
          d="M28.1661 19.3337C28.1661 21.6358 26.3016 23.5003 23.9994 23.5003C21.6972 23.5003 19.8327 21.6358 19.8327 19.3337C19.8327 17.0315 21.6972 15.167 23.9994 15.167C26.3016 15.167 28.1661 17.0315 28.1661 19.3337ZM15.166 31.0003C15.166 30.4081 15.4587 29.8524 16.0254 29.3294C16.5955 28.8032 17.409 28.3445 18.3505 27.9674C20.2342 27.2128 22.5044 26.8337 23.9994 26.8337C25.4944 26.8337 27.7645 27.2128 29.6482 27.9674C30.5897 28.3445 31.4032 28.8032 31.9733 29.3294C32.54 29.8524 32.8327 30.4081 32.8327 31.0003V32.8337H15.166V31.0003Z"
          fill={isSelect || '#756F7E'}
          stroke="#171719"
        />
      </mask>
      <g mask="url(#mask0_7487_6140)">
        <rect x="10" y="10" width="28" height="28" fill="#5C576B" />
      </g>
      <path
        d="M27.6792 34.721C27.6792 34.8775 27.7508 35.0276 27.8782 35.1383C28.0056 35.249 28.1783 35.3112 28.3585 35.3112H29.0377V30.5898H28.3585C28.1783 30.5898 28.0056 30.652 27.8782 30.7627C27.7508 30.8734 27.6792 31.0235 27.6792 31.18V32.3603H27V33.5407H27.6792V34.721ZM39.9057 31.18C39.9057 31.0235 39.8341 30.8734 39.7067 30.7627C39.5793 30.652 39.4066 30.5898 39.2264 30.5898H38.5472V35.3112H39.2264C39.4066 35.3112 39.5793 35.249 39.7067 35.1383C39.8341 35.0276 39.9057 34.8775 39.9057 34.721V33.5407H40.5849V32.3603H39.9057V31.18Z"
        fill="#8E899F"
      />
      <path
        d="M36.5098 30C37.26 30 37.8683 30.6082 37.8683 31.3585V34.5431C37.8683 35.2934 37.26 35.9016 36.5098 35.9016V30Z"
        fill="#8E899F"
      />
      <path
        d="M29.7168 31.3585C29.7168 30.6082 30.325 30 31.0753 30V35.9016C30.325 35.9016 29.7168 35.2934 29.7168 34.5431V31.3585Z"
        fill="#8E899F"
      />
      <rect
        x="31.7539"
        y="32.3604"
        width="4.07547"
        height="1.18033"
        fill="#8E899F"
      />
    </svg>
  );
};

export default PlaceholderIcon;
