import EmptyContent from 'src/refactor_shared/ui/empty-content';

const TrainerIntro = ({
  introduction,
  shortIntroduction
}: {
  shortIntroduction?: string;
  introduction: string;
}) => {
  return (
    <div className="flex flex-col gap-20">
      <p className="text-[18px] font-bold pt-20">소개글</p>
      {shortIntroduction ? (
        <div>
          <div className="flex justify-center items-start gap-10 pb-24 mb-16 border-b-1 border-solid border-[#7750E9]">
            <p className="text-[#5C576B]">"</p>
            <p className="text-center text-[18px]">{shortIntroduction}</p>
            <p className="text-[#5C576B]">"</p>
          </div>
          <p className="text-[14px] whitespace-pre-line text-[#C9C7D1]">
            {introduction ? introduction : ''}
          </p>
        </div>
      ) : (
        <EmptyContent>소개글이 없습니다</EmptyContent>
      )}
    </div>
  );
};

export default TrainerIntro;
