import { Link } from 'react-router-dom';

import WriteIcon from 'src/r_page/community/Icon/WriteIcon';
import HealthInfoIcon from 'src/assets/Icon/Icon/Normal/Group 2.svg';
import PlaceIcon from 'src/assets/Icon/PlaceIcon.svg';
import Section from 'src/r_page/main/section';
import { axios } from '#api/axios';
import { useQuery } from '@tanstack/react-query';
import { formatTime } from 'src/refactor_shared/lib/parseTime';

type ScheduleType = {
  createdAt: string;
  gymName: string;
  round: number;
  title: string;
  totalRound: number;
}[];
const TodaySchedule = () => {
  const { data: schedules } = useQuery({
    queryKey: ['today-schedules'],
    queryFn: async () =>
      await axios.get<ScheduleType>('/consumer/home/schedules'),
    select: data => data.data
  });

  return (
    <>
      {schedules && schedules.length > 0 ? (
        <Section sectionTitle="오늘 일정">
          <div className="flex items-center justify-between bg-[#2E2B36] rounded-12 overflow-x-auto snap-x">
            {schedules.map(item => (
              <div className="flex items-center justify-between flex-grow flex-shrink-0 w-full p-16 snap-center snap-always">
                <div className="flex flex-col gap-8">
                  <div className="flex gap-16 text-[#C9C7D1] items-center">
                    <span>{formatTime(item.createdAt.split('T')[1])}</span>
                    <span className="text-[18px] font-mediums">
                      {item.title}
                    </span>
                  </div>
                  <div className="flex gap-16 text-[#C9C7D1]">
                    <span className="text-[14px] text-[#8E899F]">
                      트레이너 | {item.gymName}
                    </span>
                  </div>
                </div>
                <div className="text-[14px]">
                  {item.round}{' '}
                  <span className="text-[#8E899F] text-[12px]">
                    / {item.totalRound}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </Section>
      ) : null}
      <div className="flex  flex-col gap-16 rounded-8 text-[#C9C7D1] text-[17px] font-medium w-full justify-between max-[865px]:flex-wrap ">
        <div className="flex gap-16">
          <Link
            to={'/community/information'}
            className="flex gap-6 bg-[#24222A] p-16 rounded-8 w-[calc(30%+37px)] max-h-54  justify-center items-center tracking-[-0.41px]"
          >
            운동 가이드
            <img src={HealthInfoIcon} alt="" />
          </Link>
          <Link
            to={'/community/qna'}
            className="flex bg-[#24222A] gap-6 p-16 rounded-8  max-h-54 w-[calc(70%-31px)] justify-center items-center tracking-[-0.41px]"
          >
            전문가에게 질문하기 <WriteIcon />
          </Link>
        </div>
        <Link
          to={'/trainerList'}
          className="flex bg-[#24222A] gap-6 py-16 justify-center  max-h-54 items-center rounded-8 w-full tracking-[-0.41px]"
        >
          내 주변 전문가 찾기 <img src={PlaceIcon} alt="" />
        </Link>
      </div>
    </>
  );
};

export default TodaySchedule;
