import { FileUpload } from 'src/refactor_shared/ui/FileUpload';

import Title from 'src/refactor_components/common/Title';

const WriteImages = ({ registerName }: { registerName: string }) => {
  return (
    <div className="flex flex-col">
      <Title text="사진/동영상 추가" />
      <FileUpload registerName={registerName} maxCount={5} />
    </div>
  );
};

export default WriteImages;
