import Hyeonggi from 'src/assets/white-list/hyeonggi.png';
import Yeonggi from 'src/assets/white-list/yeonggi.png';
import Kyeongmoon from 'src/assets/white-list/kyeongmoon.png';
import Bokyeon from 'src/assets/white-list/bokyeon.png';

export const TrainerWhiteListData = [
  {
    name: '이영한',
    introduce: 'PT, 신장이 아닌 심장으로 하는 것',
    profileLink: 'https://fibud.kr/expert/skstm12',
    id: 'b53ea8af-24f7-4140-bccf-a5e7936be884',
    profileImage:
      'https://fibud-bucket.s3.ap-northeast-2.amazonaws.com/fibud/prod/resized/profile/cb1187a2-0313-44a7-979a-00e72ef7f814.webp',
    tag: ['재활', '체력증진', '통증관리']
  },
  {
    name: '조영기',
    introduce: '3대 재벌 전담 트레이너',
    profileLink: 'https://fibud.kr/expert/whdudrl76',
    id: '85f1de05-1407-476a-9440-8327db01e3a5',
    profileImage: Yeonggi,
    tag: ['재활', '통증관리', '다이어트']
  },
  {
    name: '남경문',
    introduce: '10년 차 상위 1% 운동 전문가',
    profileLink: 'https://fibud.kr/expert/victornam',
    id: '33fafb1e-6021-4868-86e6-ed472f344122',
    profileImage: Kyeongmoon,
    tag: ['기능성 트레이닝', '다이어트', '체형교정']
  },
  {
    name: '김형기',
    introduce: '한체대 운동건강관리 석사',
    profileLink: 'e5463dc5-256f-4163-8f9e-1559c4840045',
    id: 'e5463dc5-256f-4163-8f9e-1559c4840045',
    profileImage: Hyeonggi,
    tag: ['기능성 트레이닝', '보디빌딩', '체형교정']
  },
  {
    name: '정복연',
    introduce: '경희대 스포츠의과학 석사',
    profileLink: 'https://fibud.kr/expert/jungbokyoun',
    id: '63a80682-395c-4885-889d-54ec4e201146',
    profileImage: Bokyeon,
    tag: ['보디빌딩', '다이어트', '체형교정']
  },
  {
    name: '김수민',
    introduce: '온전한 내 몸 움직임 되찾기',
    profileLink: 'https://fibud.kr/expert/S_claire.kim',
    id: 'c48bab4e-c43a-4292-bfad-ff869ecd5b52',
    profileImage:
      'https://fibud-bucket.s3.ap-northeast-2.amazonaws.com/fibud/prod/resized/profile/70d802cb-a320-4dbd-a204-f8ade9a64663.webp',
    tag: ['기능성 트레이닝', '통증관리', '체형교정']
  }
];
