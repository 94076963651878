import { useMutation } from '@tanstack/react-query';

import { PrevHeaderBarWithTitleTemplate } from '#templates';

import { Switch } from '#atoms';
import { axios } from '#api/axios';

import { useToast } from '#providers/ToastProvider';

function SettingsAlarm() {
  const { showToast } = useToast();

  const { mutate } = useMutation({
    mutationFn: async (payload: string) =>
      await axios.post('/alim/notifications', { type: payload }),
    onSuccess: () =>
      showToast({
        type: 'success',
        message: '알림 설정이 변경되었습니다.'
      }),
    onError: () =>
      showToast({
        type: 'fail',
        message: '일시적인 오류입니다. 다시 시도해주세요.'
      })
  });

  const onToggle = async (value: string) => {
    return await mutate(value);
  };

  return (
    <PrevHeaderBarWithTitleTemplate title="알림 설정" prevRoute="/settings">
      <div className="flex flex-col gap-40 p-20">
        {ALARM_ITEMS.map(value => {
          return (
            <div className="flex flex-col w-full gap-12">
              <div className="flex justify-between items-center text-[18px]">
                {value.title}{' '}
                <Switch
                  initState={true}
                  onToggle={() => onToggle(value.value)}
                />
              </div>
              <p className="text-[#C9C7D1] text-[14px]">{value.description}</p>
              {/* {value?.children ? value.children : null} */}
            </div>
          );
        })}
      </div>
    </PrevHeaderBarWithTitleTemplate>
  );
}
export default SettingsAlarm;

const ALARM_ITEMS = [
  {
    value: 'QNA_REPLY',
    title: '질문 답변',
    description: '내가 올린 운동 질문에 답변이 작성되면 알림을 받아보세요'
    // children: (
    //   <div className="rounded-8 p-16 bg-[#1F1E25] flex flex-col gap-16">
    //     <div className="flex justify-between itmes-center">
    //       <Title text="활동 지역 선택" essential />
    //       <p className="text-[#8E899F]">{3}/3</p>
    //     </div>
    //     <div className="flex items-center gap-8">
    //       <div className="px-12 py-8 rounded-50 bg-[#7750E9] text-[#EAE8ED]">
    //         서울시 강남구
    //       </div>
    //       <div className="px-12 py-8 rounded-50 bg-[#7750E9] text-[#EAE8ED]">
    //         서울시 강남구
    //       </div>
    //       <div className="px-12 py-8 rounded-50 bg-[#7750E9] text-[#EAE8ED]">
    //         서울시 강남구
    //       </div>
    //     </div>
    //     <button className="self-end">위치 추가/삭제</button>
    //   </div>
    // )
  },
  {
    value: 'EXERCISE_ANSWER',
    title: '운동 가이드',
    description: '내가 작성한 운동 가이드의 댓글에 대한 알림을 받아보세요'
  },
  {
    value: 'USER',
    title: '회원 관리',
    description: '결제 및 수업 예약, 운동 차트에 대한 알림을 받아보세요 '
  },
  {
    value: 'USER_CHAT',
    title: '1:1 채팅',
    description: '트레이너님의 새로운 채팅에 대한 알림을 받아보세요'
  },
  {
    value: 'USER_BENEFIT',
    title: '피벗 소식',
    description: '피벗의 공지사항, 업데이트에 대한 소식을 받아보세요'
  }
];
