import { IHealthInfo } from '#types/account';
import { QUESTIONER_INFO_LIST, QUESTION_SECOND_INFO_LIST } from './constant';

interface Props {
  data: IHealthInfo;
  age: string;
  sex: 'FEMALE' | 'MALE';
}

const QuestionerInfo = ({ data, age, sex }: Props) => {
  return (
    <div className="flex flex-col gap-20 px-20">
      <div className="flex flex-col gap-y-[16px]">
        {age && sex && (
          <span className="text-[#C9C7D1] font-semibold tracking-tight">
            질문자 정보
          </span>
        )}

        {data && (
          <div className="rounded-12 flex py-32 px-12 w-full bg-[#1F1E25]">
            {QUESTIONER_INFO_LIST.map(item => (
              <div className="flex flex-col items-center justify-center flex-1 gap-3">
                <span className="text-[rgb(92,87,107)] text-[13px] font-normal tracking-[-0.3px]">
                  {item.healthInfoType}
                </span>
                <span className="text-[#C9C7D1]">
                  {item.healthInfoType === '키' ? data.height : data.weight}
                  <span className="text-[#8E899F]">
                    {item.healthInfoType === '키' ? 'cm' : 'kg'}
                  </span>
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-12">
        {age && sex && (
          <div className="flex flex-col">
            <span className="text-[#5C576B] text-sm">{'나이 및 성별'}</span>
            <span className="text-[#C9C7D1] text-base">
              {age}세 {sex === 'FEMALE' ? '여성' : '남성'}
            </span>
          </div>
        )}

        {data && (
          <>
            {QUESTION_SECOND_INFO_LIST.map(({ healthInfoType }) => (
              <div className="flex flex-col gap-4 py-12 font-normal leading-20">
                <span className="text-[#5C576B] text-sm">{healthInfoType}</span>
                {healthInfoType !== '통증 (부위/정도)' ? (
                  <span className="text-[#C9C7D1] text-base">남성</span>
                ) : (
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-6 text-[#C9C7D1] text-base">
                      <div className="text-center bg-[#383441] text-[#8E899F] text-[12px] w-20 h-20 rounded-8">
                        1
                      </div>
                      <span>손목 / 5 - 극심한 불편함 존재</span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default QuestionerInfo;
