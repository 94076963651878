import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

interface SelectOption {
  label: string;
  value: string | number;
}

interface Props {
  value?: string;
  width?: number | string;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  options: string[] | SelectOption[];
  onChange?: (selectedValue: string, label?: string) => void;
  selectStyle?: React.CSSProperties;
  selectContainerStyle?: React.CSSProperties;
}

export default function Select({
  value,
  width,
  placeholder = 'Select options..',
  options,
  disabled,
  defaultValue,
  onChange,
  selectStyle,
  selectContainerStyle
}: Props) {
  const [selectedText, setSelectedText] = useState<string | undefined>(
    defaultValue
  );

  const selectId = 'select-id';

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    setSelectedText(value);

    if (onChange) {
      if (typeof options !== 'string') {
        const currentOptions = options as SelectOption[];

        const selectedOption = currentOptions.find(
          option => option.value === value
        );

        onChange(value, selectedOption?.label);
      } else {
        onChange(value);
      }
    }
  };

  return (
    <Container
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '4px',
        ...selectContainerStyle
      }}
    >
      <StyledSelect
        id={selectId}
        onChange={handleChange}
        disabled={disabled}
        value={selectedText} // Control the value with selectedText
        style={selectStyle}
      >
        {defaultValue && (
          <option value={''} hidden>
            {defaultValue}
          </option>
        )}
        {options.map((option, index) => {
          return (
            <option
              key={index}
              value={typeof option === 'string' ? option : option.value}
            >
              {typeof option === 'string' ? option : option.label}
            </option>
          );
        })}
      </StyledSelect>
      <Label htmlFor={selectId}>
        <img src="/images/ic-arrow-down-16.svg" alt="arrow_down" />
      </Label>
    </Container>
  );
}

const Container = styled.div`
  height: 37px;
  border-radius: 8px;
  background: var(--bg-600, #2e2a3a);
  position: relative;
  display: flex;
  flex: 1 1 0;

  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  color: var(--Gray-Scale-300, #d7d3de);

  /* NEW/body_02_regular */
  font-family: 'Pretendard Variable';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 131.25% */
  letter-spacing: -0.32px;
`;

const StyledSelect = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1 1 0;
  outline: none;
  padding: 16px 32px 16px 16px;
  border: 0px solid transparent;
  background-color: transparent;
`;

const Label = styled.label`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
`;
