import {
  ApplicationBodyInfo,
  ApplicationCarrer,
  ApplicationEtc,
  ApplicationHealth,
  ApplicationJob,
  ApplicationPayment,
  ApplicationPurpose,
  ApplicationScheduleFail,
  ApplicationSleep,
  ApplicationSuccess
} from '#pages';
import { Applications } from '#providers/Applications';
import { Progress, ProgressHeaderbarTemplate } from '#templates';
import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { ApplicationRegSuccess } from 'src/components/pages/ApplicationRegSuccess';
import { Container } from './styles';

// Context 사용을 위해 ProviderWrapper와 분리
export function ApplicationView() {
  return (
    <ProviderWrapper>
      <Processor />
    </ProviderWrapper>
  );
}

function Processor() {
  const { state } = useLocation();
  // const [{ lectureId }] = useRecoilState($confirmationFormState);
  // const { lectureId } = useRecoilValue($applicationData);
  const navigate = useNavigate();

  const myUrl = state?.data ? state.data.myUrl : null;

  // useCheckValidState({
  //   targetState: !!lectureId,
  //   navigateTo: myUrl ? `/expert/${myUrl}` : PATH.HOME
  // });

  return (
    <Routes>
      <Route
        path=""
        element={
          <Container>
            <Progress.Container>
              <ProgressHeaderbarTemplate
                title="수강 신청서"
                pages={[
                  <ApplicationBodyInfo />,
                  // <ApplicationCarrer />,
                  <ApplicationPurpose />,
                  <ApplicationSleep />,
                  <ApplicationJob />,
                  <ApplicationHealth />,
                  <ApplicationEtc />

                  // <Schedules>
                  //   <CoachSchedules>
                  //     <ApplicationSchedule />
                  //   </CoachSchedules>
                  // </Schedules>,
                ]}
                onCancel={() =>
                  myUrl
                    ? navigate(`/expert/${encodeURIComponent(myUrl)}`)
                    : navigate(-1)
                }
              />
            </Progress.Container>
          </Container>
        }
      />
      <Route path="payment" element={<ApplicationPayment />} />
      <Route path="payment-success" element={<ApplicationSuccess />} />
      <Route path="success" element={<ApplicationRegSuccess />} />
      <Route path="schedule-fail" element={<ApplicationScheduleFail />} />
    </Routes>
  );
}

function ProviderWrapper({ children }: React.PropsWithChildren) {
  return <Applications>{children}</Applications>;
}
