import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { PrevHeaderBarWithTitleTemplate } from '#templates';

import BookmarkInformation from 'src/r_page/my-page/bookmark/information';
import BookmarkQna from 'src/r_page/my-page/bookmark/qna';

const MyPageBookmark = () => {
  const { id } = useParams();

  return (
    <PrevHeaderBarWithTitleTemplate title="" prevRoute="../.">
      <div className="flex justify-around items-center border-b-1 border-solid border-[#7750e9] px-20">
        {TAB_LIST.map(({ to, label, value }) => (
          <Link
            key={value}
            to={to}
            className={`${
              id === value ? 'border-[#7750e9]' : 'border-transparent'
            } py-12 border-0 border-b-[3px] border-solid w-full text-center`}
          >
            {label}
          </Link>
        ))}
      </div>
      <div className="p-20 overflow-auto">
        {id === 'information' ? <BookmarkInformation /> : <BookmarkQna />}
      </div>
    </PrevHeaderBarWithTitleTemplate>
  );
};

export default MyPageBookmark;

const TAB_LIST = [
  {
    to: '/mypage/bookmark/information',
    label: '운동 가이드',
    value: 'information'
  },
  {
    to: '/mypage/bookmark/qna',
    label: '질문 답변',
    value: 'qna'
  }
];
