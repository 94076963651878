import request from '#api/axios';
import axios, { AxiosError } from 'axios';
import { produce } from 'immer';
import { useState } from 'react';
import { PreSignedUrlRequestDto, PreSignedUrlResponseDto } from './types';

interface UploadFilesParams {
  selectedFiles: File[];
  path: PreSignedUrlRequestDto['path'];
  onSuccess: ({
    imgUrl,
    id,
    fileType,
    file
  }: {
    imgUrl: string;
    id: string;
    fileType: 'videos' | 'images';
    file: File;
  }) => void;
  onError: (err: unknown) => void;
}

type Status = 'loading' | 'success' | 'error';

export interface FileInfo {
  file: File;
  status: Status;
  presignedUrl?: PreSignedUrlResponseDto['preSignedUrl'];
  id: string;
}

/**
 * presigned url을 발급받아 s3에 파일을 업로드하는 커스텀 훅
 * @returns uploadFiles: 파일 업로드 함수, isLoading: 파일 업로드 중 여부
 * @param selectedFiles 업로드할 파일 리스트
 * @param path 업로드할 파일 경로
 * @param onSuccess 개별 파일 업로드 성공 시 실행될 함수
 * @param onError 개별 파일 업로드 실패 시 실행될 함수
 */
export function useUploadFiles() {
  const [fileInfoList, setFileInfoList] = useState<FileInfo[]>([]);

  function prepareFilesForPresignedUrl(
    fileList: File[],
    path: PreSignedUrlRequestDto['path']
  ) {
    const fileArray = Array.from(fileList);

    const filesForPresignedUrl: PreSignedUrlRequestDto[] = fileArray.map(
      file => ({
        path,
        contentType: file.type,
        originName: file.name
      })
    );

    return filesForPresignedUrl;
  }

  // aws s3에 파일 업로드를 위한 사전 서명된 URL을 요청
  async function getPresignedUrl(data: PreSignedUrlRequestDto[]) {
    const res = await request<PreSignedUrlResponseDto[]>({
      url: '/files/presigner',
      method: 'POST',
      data
    });

    return res.data;
  }

  async function uploadFiles({
    selectedFiles,
    path,
    onError,
    onSuccess
  }: UploadFilesParams) {
    if (!selectedFiles) return;

    // aws presigned url 요청을 위해 준비
    const files = prepareFilesForPresignedUrl(selectedFiles, path);

    // 프리사인드 URL과 필드 가져오기
    const presignedUrlList = await getPresignedUrl(files);

    try {
      await Promise.allSettled(
        presignedUrlList.map(async (preSignedUrlObj, index) => {
          setFileInfoList(
            produce(draft => {
              draft.push({
                file: selectedFiles[index],
                status: 'loading',
                presignedUrl: preSignedUrlObj.preSignedUrl,
                id: preSignedUrlObj.id
              });
            })
          );

          const { preSignedUrl, id } = preSignedUrlObj;
          const { fields } = preSignedUrl;

          // FormData 객체 생성
          const formData = new FormData();

          // 프리사인드 URL 필드 추가
          Object.keys(fields).forEach(key => {
            formData.append(key, fields[key]);
          });

          formData.append('file', selectedFiles[index]);

          // 이미 완료된 파일을 포함하는 인덱스
          const currentIndex = fileInfoList.length + index;

          const fileType = selectedFiles[index].type.includes('image')
            ? 'images'
            : 'videos';

          try {
            // 파일 업로드 요청
            await axios.post(preSignedUrl.url, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
          } catch (err) {
            setFileInfoList(
              produce(draft => {
                draft[currentIndex].status = 'error';
              })
            );
            onError(err);
          }

          setFileInfoList(
            produce(draft => {
              draft[currentIndex].status = 'success';
            })
          );
          onSuccess({
            imgUrl: `${preSignedUrl.url}/${fields.Key}`,
            id,
            fileType,
            file: selectedFiles[index]
          });
        })
      );
    } catch (err) {
      // 에러 발생 시 처리
      console.error('Upload error:', err);
    } finally {
    }
  }

  return {
    fileInfoList,
    setFileInfoList,
    uploadFiles
  };
}
