import { useGetAccount } from '#api/accounts';
import { useGetCoachLectures } from '#api/lectures';
import { useGetCoachIdFromMyUrl } from '#api/my-url';
import { useRouteHistory } from '#hooks/useRouteHistory';
import { $coachLectureState } from '#states/atoms/coachLectureState';
import { $modalFamily, MODAL_TYPE } from '#states/atoms/modalState';
import {
  CoachDetailApplyModal,
  CoachDetailContent,
  CoachDetailLoginModal,
  FibudContainerTemplate,
  PrevHeaderBarWithTitleTemplate
} from '#templates';
import { Lecture } from '#types/lectures';
import MetaHelmet from '#utils/MetaHelmet';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

export const CoachDetailPage = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const { myUrl } = useParams();

  const decodedMyUrl = decodeURIComponent(myUrl || '');

  const {
    data: coachId,
    error,
    isError
  } = useGetCoachIdFromMyUrl(decodedMyUrl);
  const { clearRouteHistory } = useRouteHistory();

  const { data: coachData, isError: getAccountError } = useGetAccount(
    coachId ?? ''
  );
  const { data: lecturesData, isError: getLecturesError } = useGetCoachLectures(
    coachId ?? ''
  );

  // adfadsfasdf
  const setCoachLectureState = useSetRecoilState($coachLectureState);
  const [showApplyModal, setShowApplyModal] = useRecoilState(
    $modalFamily(MODAL_TYPE.APPLY)
  );
  const [showLoginModal, setShowLoginModal] = useRecoilState(
    $modalFamily(MODAL_TYPE.LOGIN)
  );
  const resetLoginModalState = useResetRecoilState(
    $modalFamily(MODAL_TYPE.LOGIN)
  );
  const resetApplyModalState = useResetRecoilState(
    $modalFamily(MODAL_TYPE.APPLY)
  );
  const [showCoachNameInHeader, setShowCoachNameInHeader] = useState(false);
  const [applyTagEvent, setApplyTagEvent] = useState<boolean>(false);

  useEffect(() => {
    if (!lecturesData || !coachData) return;

    const data = lecturesData.data.map((lecture: Lecture) => ({
      id: lecture.id,
      coachId: lecture.coachId,
      gymId: coachData.recommendGyms[0]?.id,
      price: lecture.price,
      recommendRoundPerWeek: lecture.recommendRoundPerWeek ?? 0,
      title: lecture.title,
      body: lecture.body,
      rounds: lecture.rounds,
      coachName: lecture.coachName,
      gymName: coachData.recommendGyms[0]?.name,
      isOt: lecture.isOt,
      isOnePoint: lecture.isOnePoint
    }));

    setCoachLectureState(data);

    return () => {
      resetApplyModalState();
      resetLoginModalState();
    };
  }, [lecturesData, coachData]);

  useEffect(() => {
    if (!coachData) return;

    // head 태그 내의 모든 script 태그를 가져옴
    const scriptsInHead = document.head.getElementsByTagName('script');

    const structuredData = Array.from(scriptsInHead).filter(
      script => script.type === 'application/ld+json'
    );

    function parseAddress(address: string) {
      const [cityProvince, district, ...rest] = address.split(' ');
      const streetAddress = rest.join(' ');

      return {
        addressRegion: cityProvince, // 서울특별시
        addressLocality: district, // 송파구
        streetAddress: streetAddress // 잠실동 175-14 지하 1층
      };
    }

    // 파싱한 주소 데이터
    const parsedAddress = parseAddress(coachData.recommendGyms[0].address);

    structuredData[0].textContent = JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'ProfilePage',
      dateCreated: coachData.createdAt,
      dateModified: coachData.updatedAt,
      mainEntity: {
        '@type': 'Person',
        name: coachData.name || '',
        alternateName: coachData.nickname || '',
        identifier: coachData.myUrl,
        description: coachData.introduction || '',
        image: coachData.profile[0]?.resizedLink || '',
        relatedLink: [
          ...coachData.association.map(item => `/team/${item.associationUrl}`),
          ...coachData.educations.map(item => `/edu/${item.educationUrl}`)
        ],
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: coachData.phone || '',
          name: coachData.name || '',
          contactType: 'Customer Service',
          email: coachData.email || ''
        },
        address: {
          '@type': 'PostalAddress',
          streetAddress: parsedAddress.streetAddress, // 파싱된 상세 주소
          addressLocality: parsedAddress.addressLocality, // 파싱된 구
          addressRegion: parsedAddress.addressRegion, // 파싱된 광역시
          addressCountry: 'KR' // 국가 코드 (대한민국)
        }
      }
    });
  }, [coachData]);

  const title = coachData
    ? `${coachData.name ?? ''} ${coachData.tags
        .map(tag => tag.name)
        .join(' | ')} 트레이너 - ${coachData.recommendGyms[0].name} `
    : '';

  const description = coachData
    ? coachData.introduction || coachData.shortIntroduction
    : '숫자만 세주는 트레이너 말고 진짜 전문가 ,PT는 피벗';

  if (isLoading) return <div></div>;

  if (!myUrl) return <></>;

  if (isError) return <Fallback error={error} />;

  return (
    <>
      <MetaHelmet
        title={title}
        description={description}
        img={
          `${coachData?.profile[0]?.resizedLink}`
            ? `${coachData?.profile[0]?.resizedLink}`
            : './ms-icon-70x70.png'
        }
        url={'https://fibud.kr/expert/' + decodedMyUrl.replace('/', '')}
      />

      <PrevHeaderBarWithTitleTemplate
        hide={coachData?.status !== 'ACCEPT'}
        title={showCoachNameInHeader ? `${coachData?.name} 전문가` : ''}
        defaultBackground
        // prevRoute="/expert?tab=전체"
        onPrevCallback={clearRouteHistory}
      >
        {(getAccountError || getLecturesError) && (
          <div style={{ color: 'white' }}>잘못된 접근입니다!</div>
        )}
        <FibudContainerTemplate>
          {coachData && coachData.id === coachId && (
            <CoachDetailContent
              coachData={coachData}
              lectureData={lecturesData?.data ?? []}
              setShowCoachNameInHeader={setShowCoachNameInHeader}
              applyTagEvent={applyTagEvent}
              setApplyTagEvent={setApplyTagEvent}
            />
          )}
        </FibudContainerTemplate>
        {showApplyModal && (
          <CoachDetailApplyModal
            setShowModal={setShowApplyModal}
            onApplyButtonClick={() => setApplyTagEvent(true)}
            coachId={coachId ? coachId : ''}
          />
        )}
        {showLoginModal && (
          <CoachDetailLoginModal setShowModal={setShowLoginModal} />
        )}
      </PrevHeaderBarWithTitleTemplate>
    </>
  );
};

const Fallback = ({ error }: { error: unknown }) => {
  const axiosError = error as AxiosError<{
    message: string;
  }>;

  return (
    <div className={'h-screen flex items-center'}>
      {axiosError.response?.data?.message}
    </div>
  );
};
