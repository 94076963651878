import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { BoxIcon, Text } from '#atoms';
import { PATH } from '#const/path';
import { Chats, useChat } from '#providers/Chat';
import { useUserInfo } from '#providers/UserInfo';
import { FibudContainerTemplate, HeaderBarTemplate } from '#templates';
import { ChatListContainer, MessageBox, UnReadCount } from './styles';

const dateTimeToLastTime = (timeStr: string) => {
  const inputTime = dayjs(timeStr);
  const time = dayjs();
  const diff = time.diff(inputTime);
  if (diff < 1000) {
    return '방금';
  }
  if (diff < 60 * 1000) {
    return `${Math.floor(diff / 1000)}초`;
  }
  if (diff < 60 * 60 * 1000) {
    return `${Math.floor(diff / (60 * 1000))}분`;
  }
  if (diff < 24 * 60 * 60 * 1000) {
    return `${Math.floor(diff / (60 * 60 * 1000))}시간`;
  }
  if (diff < 7 * 24 * 60 * 60 * 1000) {
    return `${Math.floor(diff / (24 * 60 * 60 * 1000))}일`;
  }
  if (diff < 30 * 7 * 24 * 60 * 60 * 1000) {
    return `${Math.floor(diff / (7 * 24 * 60 * 60 * 1000))}주`;
  }
  return `${Math.floor(diff / (30 * 7 * 24 * 60 * 60 * 1000))}달`;
};

export function ChatHome() {
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();
  const { socket, rooms, openChat, reqRoomList } = useChat();

  const handleChatButtonClick = (opponentId: string) => {
    const onSuccess = () => {
      navigate(PATH.CHAT_ROOM);
    };

    const onFail = () => {
      // setShowLoginModal(true);
      navigate(PATH.SIGN, {
        state: { blockedPagePath: location.pathname + location.search }
      });
    };

    openChat(opponentId, onSuccess, onFail);
  };

  useEffect(() => {
    if (!userInfo || !socket) return;

    reqRoomList(0);
  }, [userInfo, socket]);

  return (
    <HeaderBarTemplate
      TitleLeft={
        <BoxIcon
          type="prev-arrow"
          onClick={() => navigate(-1)}
          size={18}
          onlyIcon={true}
        />
      }
      TitleCenter={<Text textStyle="t3">채팅</Text>}
      // TitleRight={
      //   <>
      //     <BoxIcon type="setting" size={18} onlyIcon={true}></BoxIcon>
      //   </>
      // }
    >
      <Chats.EmptyRoom>
        <FibudContainerTemplate type="centered">
          <Text textStyle="bb">채팅 목록이 없습니다.</Text>
        </FibudContainerTemplate>
      </Chats.EmptyRoom>
      <Chats.HasRoom>
        <ChatListContainer>
          {rooms.map(
            (
              { opponent, opponentIsCoach, opponentName, unReadCnt, lastChat },
              idx
            ) => (
              <li
                key={idx}
                onClick={() => {
                  handleChatButtonClick(opponent);
                }}
              >
                <div>
                  <Text textStyle="t3">
                    {opponentName} {opponentIsCoach ? '전문가' : '회원님'}
                  </Text>
                  <Text>{dateTimeToLastTime(lastChat.createdAt)}</Text>
                </div>
                <MessageBox>
                  <Text>{lastChat.message}</Text>
                  {unReadCnt > 0 && (
                    <UnReadCount data-text-type="c1">{unReadCnt}</UnReadCount>
                  )}
                </MessageBox>
              </li>
            )
          )}
        </ChatListContainer>
      </Chats.HasRoom>
    </HeaderBarTemplate>
  );
}
