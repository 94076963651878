import { isUser } from '#utils/isUser';
import { useFormContext } from 'react-hook-form';

interface Props {
  disableOnLoading: boolean;
  isPatch: boolean;
}

const WriteRegister = ({ disableOnLoading, isPatch }: Props) => {
  const methods = useFormContext();
  return (
    <div className="fixed bottom-0 left-0 flex justify-center w-full">
      <div className="bg-[#1F1E25] px-20 pb-34 flex flex-col items-center justify-center gap-16 pt-16 max-w-939 w-full rounded-t-8">
        {isUser() ? (
          <p className="text-[10px] text-center text-[#8E899F]">
            전문가의 정확한 답변을 위해 나이와 성별 정보가 공개돼요.
          </p>
        ) : null}
        <button
          className="px-20 py-8  bg-[#7750E9] rounded-8 w-full text-center"
          disabled={disableOnLoading}
        >
          질문 {isPatch ? '수정하기' : '등록하기'}
        </button>
      </div>
    </div>
  );
};

export default WriteRegister;
