import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { axios } from '#api/axios';

import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { TabListViewer } from '#molecules';

import TrainerProfile from 'src/refactor_components/trainer-profile/trainer-profile';

import TrainerHome from 'src/r_page/trainer-profile/tab/home';
import TrainerInfo from 'src/r_page/trainer-profile/tab/info';
import TrainerActivities from 'src/r_page/trainer-profile/tab/activities';
import { useGetCoachReviews } from '#api/reviews';
import { CustomerReviews } from 'src/components/templates/CoachDetailContent/DetailInfo/CustomerReviews';
import { useGetCoachIdFromMyUrl } from '#api/my-url';

const TrainerDetail = () => {
  const { myUrl } = useParams();
  const decodedMyUrl = decodeURIComponent(myUrl || '');
  const { data: id } = useGetCoachIdFromMyUrl(decodedMyUrl);
  // const setShowLoginModal = useSetRecoilState($modalFamily(MODAL_TYPE.LOGIN));

  const { data: trainerDetails } = useQuery({
    queryKey: ['get-trainer-account-detail', id],
    queryFn: async () => await axios.get(`accounts/coach/${id}`),
    select: data => data.data,
    enabled: !!id
  });

  const { data: questionDetails } = useQuery({
    queryKey: ['get-trainer-question-detail', id],
    queryFn: async () =>
      await axios.get(`consumer/exercise/coach/questions/${id}`),
    select: data => data.data,
    enabled: !!id
  });

  const { data: portfolioDetail } = useQuery({
    queryKey: ['get-trainer-portfolio', id],
    queryFn: async () => await axios.get(`clinical-data/list/${id}`),
    select: data => data.data,
    enabled: !!id
  });

  const { data: qnaQuestions } = useQuery({
    queryKey: ['get-trainer-qna-questions', id],
    queryFn: async () => await axios.get(`consumer/coach/questions/${id}`),
    select: data => data.data,
    enabled: !!id
  });

  const { data: reviewDetail } = useGetCoachReviews({ coachId: id ? id : '' });

  const hasReviews = reviewDetail?.total
    ? ['홈', '정보', '활동', '후기']
    : ['홈', '정보', '활동'];

  if (!trainerDetails) return null;

  return (
    <PrevHeaderBarWithTitleTemplate
      defaultBackground
      title={`${trainerDetails.account.name} 트레이너`}
    >
      <div className="pb-100">
        <TrainerProfile profile={trainerDetails.account} />
        <TabListViewer initTab={0}>
          <TabListViewer.List tabs={hasReviews} />
          <TabListViewer.Viewer
            tabs={[
              <TrainerHome
                data={trainerDetails}
                lecturesData={trainerDetails.lectures}
                informationQuestion={questionDetails}
                portfolio={portfolioDetail}
                qnaQuestion={qnaQuestions}
              />,
              <TrainerInfo data={trainerDetails} id={id ? id : ''} />,
              <TrainerActivities />,
              <CustomerReviews coachId={id ? id : ''} />
            ]}
          />
        </TabListViewer>
      </div>
    </PrevHeaderBarWithTitleTemplate>
  );
};

export default TrainerDetail;
