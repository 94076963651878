import { useFormContext } from 'react-hook-form';
import * as S from './style';
import { IKind } from '#types/painPartKinds';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  options: IKind;
  setSelectedValue: Dispatch<SetStateAction<number | null>>;
  buttonName: string;
}

export default function ChipButtons({
  options,
  setSelectedValue,
  buttonName
}: Props) {
  const { watch } = useFormContext();

  const hasAlreadySelected = watch(buttonName).find(
    (value: { id: string }) => value.id === options.id
  );

  return (
    <S.Wrapper>
      <S.ChipItem key={options.id}>
        <S.Input
          type="radio"
          id={`chip-${options.id}`}
          name={buttonName}
          value={options.index}
          onChange={e => {
            setSelectedValue(Number(e.currentTarget.value));
          }}
          disabled={hasAlreadySelected}
        />
        <S.ChipButton htmlFor={`chip-${options.id}`}>
          {options.name}
        </S.ChipButton>
      </S.ChipItem>
    </S.Wrapper>
  );
}
