import { axios } from '#api/axios';
import { PATH } from '#const/path';
import { ClassEvaluationModal } from '#molecules';
import { useQuery } from '@tanstack/react-query';
import { PropsWithChildren, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BookMark from 'src/assets/svg/bookmark_my.svg';
import Chat from 'src/assets/svg/chat_my.svg';
import Setting from 'src/assets/svg/setting_my.svg';
import Tooltip from 'src/refactor_components/common/Tooltip';
import EmptyContent from 'src/refactor_shared/ui/empty-content';

type CardType = PropsWithChildren<{
  trainerName: string;
  trainerProfile: string;
  round?: number;
  totalRounds: number;
  gymName: string;
  lectureTitle: string;
}>;

type CardButtonType = {
  id: string;
  coachName: string;
  coachProfile: string;
  gymName: string;
  lectureTitle: string;
  lectureId: string;
  totalRound: number;
  registeredLectureId?: string;
  isRateRequested?: boolean;
  round?: number;
};

const MyPage = () => {
  const { data: subscribed } = useQuery({
    queryKey: ['get-confirmation-forms-subscribed'],
    queryFn: async () =>
      await axios.get('confirmation-forms/consumer/subscribed'),
    select: data => data.data
  });

  const { data: active } = useQuery({
    queryKey: ['get-confirmation-forms-active'],
    queryFn: async () => await axios.get('confirmation-forms/consumer/active'),
    select: data => data.data
  });

  const { data: inactive } = useQuery({
    queryKey: ['get-confirmation-forms-inactive'],
    queryFn: async () =>
      await axios.get('confirmation-forms/consumer/inactive'),
    select: data => data.data
  });

  return (
    <>
      <div className="flex items-center justify-between py-16 px-20 fixed top-0 left-1/2 -translate-x-1/2 max-w-939 w-full text-[20px] text-[#C9C7D1] font-semibold leading-26px bg-[#181818]">
        <>마이 피벗</>
        <Link to="/mypage/setting">
          <img src={Setting} />
        </Link>
      </div>
      <div className="py-120 pt-70">
        <Profile />
        <div className="flex flex-col gap-40 mx-20 mt-40">
          <ProfileSection
            buttonSection="subscribed"
            title="결제된 수업"
            data={subscribed}
            noneTitle="아직 결제된 수업이 없습니다."
            tooltipDescription="결제가 완료 이후 첫 수업 진행이 필요한 수업입니다."
          />
          <ProfileSection
            buttonSection="active"
            title="진행중인 수업"
            data={active}
            noneTitle="아직 진행중인 수업이 없습니다."
            tooltipDescription={`매 회차별 평점을 남길 수 있습니다. 회차별 평점은 트레이너에게 공개되지 않습니다.`}
          />
          <ProfileSection
            buttonSection="inactive"
            title="종료된 수업"
            data={inactive}
            noneTitle="아직 종료된 수업이 없습니다."
          />
        </div>
      </div>
    </>
  );
};

const Card = ({
  trainerName,
  trainerProfile,
  round,
  totalRounds,
  gymName,
  lectureTitle,
  children
}: CardType) => {
  return (
    <div className="">
      <div className="flex flex-col gap-12 p-20 border-12 bg-[#24222A] flex-[0_0_auto] w-310 h-full rounded-12">
        <div className="pb-12 flex flex-col justify-start gap-12 border-b-1 border-solid border-[#2E2B36] w-full">
          <div className="flex items-center gap-8">
            <img
              src={trainerProfile}
              className="object-cover w-48 h-48 rounded-full"
            />
            <div className="text-[#C9C7D1] flex flex-col">
              <p className="text-[#EAE8ED]">{trainerName} 트레이너</p>
              <p className="text-[14px]">{gymName}</p>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-[14px] text-[#8E899F] flex items-center gap-8">
              <p>{lectureTitle}</p>
              <p>
                {round ? <span>{round}/</span> : null}
                <span>{totalRounds}회</span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-8 py-8">{children}</div>
      </div>
    </div>
  );
};

const CardWrap = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex items-center flex-grow gap-20 overflow-x-scroll">
      {children}
    </div>
  );
};

const Profile = () => {
  const { data: userInfo } = useQuery({
    queryKey: ['get-consumer-data'],
    queryFn: async () => await axios.get('accounts/consumer/dashboard'),
    select: data => data.data
  });

  if (!userInfo) return <></>;

  return (
    <div className="mx-20 pb-40 border-b-1 border-solid border-[#2E2B36]">
      <div className="flex items-center justify-between gap-6 h-218">
        <Link
          to=""
          className="rounded-16 bg-[#1F1E25] min-w-193 w-full h-full flex flex-col justify-center items-center gap-12"
        >
          <img
            src={userInfo.profileImage}
            className="object-cover rounded-full w-88 h-88 bg-slate-600"
          />
          <p className="text-[#C9C7D1] text-[14px]">
            <span className="text-[#EAE8ED] text-[16px]">{userInfo.name}</span>{' '}
            님
          </p>
        </Link>
        <div className="flex flex-col w-full h-full gap-6 min-w-136">
          <Link
            to="question"
            className="rounded-16 bg-[#1F1E25] h-full flex justify-center items-center flex-col gap-8"
          >
            <div className="flex flex-col items-center gap-4">
              <img src={BookMark} />
              <span className="text-[#9170F2] font-bold">
                {userInfo.questionCount}개
              </span>
            </div>
            <span className="text-[#C9C7D1] text-[14px]">작성한 질문 글</span>
          </Link>
          <Link
            to="/community/information/save"
            className="rounded-16 bg-[#1F1E25] h-full flex justify-center items-center flex-col gap-8"
          >
            <div className="flex flex-col items-center gap-4">
              <img src={Chat} />
              <span className="text-[#9170F2] font-bold">
                {userInfo.bookmarkCount}개
              </span>
            </div>
            <span className="text-[#C9C7D1] text-[14px]">
              저장한 운동 가이드
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

const ProfileSection = ({
  buttonSection,
  title,
  data,
  noneTitle,
  tooltipDescription
}: {
  buttonSection: string;
  title: string;
  data: CardButtonType[];
  noneTitle: string;
  tooltipDescription?: string;
}) => {
  return (
    <div className="flex flex-col gap-20">
      <div className="text-[18px] text-[#EAE8ED] flex items-center gap-4">
        <p className="flex items-center gap-4">
          <span>{title}</span>
          <span className="text-[#8E899F] text-[16px] font-semibold">
            {data ? data?.length : 0}개
          </span>
        </p>
        {tooltipDescription && <Tooltip description={tooltipDescription} />}
      </div>
      {data && data.length ? (
        <CardWrap>
          {data.map(card => {
            return (
              <Card
                trainerName={card.coachName}
                trainerProfile={card.coachProfile}
                gymName={card.gymName}
                lectureTitle={card.lectureTitle}
                totalRounds={card.totalRound}
                round={card.round}
              >
                <ButtonSection
                  buttonSection={buttonSection}
                  cardButton={card}
                />
              </Card>
            );
          })}
        </CardWrap>
      ) : (
        <EmptyContent>{noneTitle}</EmptyContent>
      )}
    </div>
  );
};

const ButtonSection = ({
  buttonSection,
  cardButton
}: {
  buttonSection: string;
  cardButton: CardButtonType;
}) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const renderButtonsByValue = () => {
    switch (buttonSection) {
      case 'subscribed':
        return (
          <Link
            className="text-[#7750E9] text-[14px]"
            to={`/application?confirmation-form-id=${cardButton.id}`}
          >
            수강 신청서 작성하기
          </Link>
        );
      case 'active':
        return (
          <>
            <div className="flex items-center justify-end w-full gap-8 text-center">
              {cardButton.isRateRequested && (
                <button
                  onClick={() => {
                    setModal(true);
                  }}
                  className="w-full text-[#7750E9] text-[14px] border border-solid border-[#7750E9] rounded-8 py-[11.5px]"
                >
                  수업 평점 남기기
                </button>
              )}
              <Link
                className="bg-[#7750E9] rounded-8 py-[11.5px] text-[14px] text-[#F7F5FF] w-full  max-w-[50%]"
                to={`/chart/record?id=${cardButton.registeredLectureId}`}
              >
                운동 차트 보기
              </Link>
            </div>
            {modal && (
              <ClassEvaluationModal
                registeredLectureId={cardButton.registeredLectureId || ''}
                round={cardButton.round || 0}
                isFinal={false}
                lectureId={cardButton.lectureId}
                onClose={() => setModal(false)}
              />
            )}
          </>
        );
      case 'inactive':
        return (
          <>
            {cardButton.isRateRequested ? (
              <button
                onClick={() =>
                  navigate(PATH.FINAL_RATING, {
                    state: {
                      lectureId: cardButton.lectureId,
                      registeredLectureId: cardButton.registeredLectureId
                    }
                  })
                }
                className="bg-[#7750E9] rounded-8 py-[11.5px] text-[14px] text-[#F7F5FF] px-18"
              >
                리뷰 작성하기
              </button>
            ) : (
              <Link
                className="text-[#8E899F] text-[14px] py-[11.5px] px-18"
                to={`/chart/lecture?id=${cardButton.lectureId}`}
              >
                운동 차트 보기
              </Link>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return <>{renderButtonsByValue()}</>;
};

export default MyPage;
