import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';

import { axios } from '#api/axios';

import useThreadLikeMutations from 'src/r_page/Trainer_Community/useThreadLikeMutations';

import PlaceholderIcon from '#assets/svg/placeholder_icon.svg';
import useDateFormatter from 'src/refactor_shared/hooks/useDateFormatter';

const QuestionComment = ({ children }: { children: ReactNode }) => {
  const { id } = useParams();

  const { data: answerCount } = useQuery({
    queryKey: ['get-trainer-community-thread-comment-count', id],
    queryFn: async () => await axios.get(`/consumer/qna/${id}/answers-count`),
    enabled: !!id,
    select: ({ data }) => data
  });

  return (
    <div className="p-20 border-t-10 border-solid border-[#0A0A0A]">
      <div className="flex gap-4 pb-16">
        <h1 className="font-bold text-[18px] leading-24 text-[#EAE8ED]">
          전문가 답변
        </h1>
        <span className="text-[16px] font-medium text-[#9170F2]">
          {answerCount ? answerCount : 0}
        </span>
      </div>
      <div className="flex flex-col gap-40">{children}</div>
    </div>
  );
};

const Wrap = ({ children }: { children: ReactNode }) => {
  return <div className="flex flex-col gap-12 text-[#C9C7D1]">{children}</div>;
};

const TrainerProfile = ({
  profileImage,
  questionerName,
  shortIntroduction,
  myUrl,
  createdAt
}: {
  profileImage: string | null;
  questionerName: string;
  shortIntroduction: string | null;
  myUrl: string;
  createdAt: string;
}) => {
  const navigate = useNavigate();
  const date = useDateFormatter(createdAt);

  return (
    <div className="flex flex-col gap-16 bg-[#24222A] rounded-16 py-12 px-20 text-[#C9C7D1]">
      <div className="flex items-center justify-between">
        <Link to={`/expert/${myUrl}`} className="flex items-center gap-9">
          {profileImage ? (
            <img
              src={profileImage ? profileImage : ''}
              className="object-cover w-48 h-48 rounded-full bg-[#383441]"
              alt=""
            />
          ) : (
            <img src={PlaceholderIcon} className="w-48 h-48 rounded-full" />
          )}
          <div className="flex flex-col gap-4">
            <p className="font-semibold text-[14px] text-[#EAE8ED]">
              {questionerName} 트레이너
            </p>
            <p className="text-[#5C576B] text-[10px]">{date} 작성됨</p>
          </div>
        </Link>
        <button
          onClick={() => navigate(`/expert/${myUrl}`)}
          className="bg-[#7750E9] rounded-8 text-center text-[#EAE8ED] py-8 px-16 text-[14px]"
        >
          문의하기
        </button>
      </div>
      {shortIntroduction ? (
        <p className="w-full text-center text-[14px]">“{shortIntroduction}”</p>
      ) : null}
    </div>
  );
};

const Content = ({ id, content }: { id?: string; content?: string }) => {
  const queryClinet = useQueryClient();
  const { likeMutate } = useThreadLikeMutations('consumer/qna/likes', false);

  const { data: likeCount } = useQuery({
    queryKey: ['get-qna-community-answer-likes', id],
    queryFn: async () => await axios.get(`/consumer/qna/${id}/likes-count`),
    enabled: !!id,
    select: ({ data }) => data
  });

  return (
    <>
      <p
        className="text-[#EAE8ED] px-20 break-words"
        dangerouslySetInnerHTML={{
          __html: content ? content : ''
        }}
      />
      {/* <button
        type="button"
        className="flex items-center justify-end gap-4 py-16 my-8"
        onClick={() =>
          likeMutate(
            {
              targetId: id ? id : '',
              kind: 'COMMENT'
            },
            {
              onSuccess: () => {
                queryClinet.invalidateQueries({
                  queryKey: ['get-qna-community-answer-likes', id]
                });
              }
            }
          )
        }
      >
       </button> */}
    </>
  );
};

QuestionComment.Wrap = Wrap;
QuestionComment.Content = Content;
QuestionComment.Profile = TrainerProfile;

export default QuestionComment;
