export const COMMUNITY_REPORT_TYPE = [
  '스팸홍보/도배글',
  '음란성/선정성',
  '개인정보 노출',
  '불법정보 포함',
  '청소년에게 유해한 내용',
  '욕설/생명경시/혐오/차별적 발언',
  '불쾌한 표현',
  '기타'
];
