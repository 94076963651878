import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  background-color: #18161c;
  position: sticky;
  top: 46px;
  z-index: 2;
  padding: 8px 0;
`;

export const Item = styled.div<{ isSelected?: boolean }>`
  font-size: 14px;
  width: max-content;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  border-radius: 12px;
  background: #1f1e25;
  border: 1px solid var(--brand-400-filled-border, #7750e9);

  ${({ isSelected }) =>
    isSelected
      ? css`
          background: var(--brand-400-filled-border, #7750e9);
        `
      : css`
          background: var(--gray-800---bg2, #1f1e25);
          color: var(--brand-300---font, #9170f2);
        `}
`;

export const Slash = styled.div`
  width: 1px;
  height: 28px;

  background: #1f1e25;
`;

export const Wrap = styled.div`
  display: flex;
  align-items: center;

  overflow-x: auto;
  white-space: nowrap; /* 아이템들이 한 줄에 표시되도록 설정 */

  gap: 6px;
`;
