// import MoreIcon from '#assets/icon/MoreIcon';
import { TRANIER_COMMUNITY_TPYE_LIST } from 'src/r_page/Trainer_Community/constant';
import * as S from './style';

interface Props {
  tag: string;
  setTag: React.Dispatch<React.SetStateAction<string>>;
  targetValue: string;
}

const CommunityTag = ({ tag, setTag, targetValue }: Props) => {
  return (
    <S.Container>
      <S.Wrap>
        {TRANIER_COMMUNITY_TPYE_LIST[targetValue].map((tmmunityType, idx) => (
          <S.Item
            key={idx}
            onClick={() => {
              setTag(tmmunityType);
            }}
            isSelected={tag === tmmunityType ? true : false}
          >
            {tmmunityType === 'ALL' ? '전체' : tmmunityType}
          </S.Item>
        ))}
      </S.Wrap>
    </S.Container>
  );
};

export default CommunityTag;
