import { useLocation } from 'react-router';

const CommunityTitle = ({
  category,
  title
}: {
  category: string;
  title: string;
}) => {
  const { pathname } = useLocation();

  return (
    <div className="px-20 pt-20">
      <div className="flex flex-col gap-10">
        <span className="text-[14px] font-medium leading-[1.42857] tracking-[-0.3px] text-[#9170F2]">
          {pathname.split('/')[1] === 'trainer-community'
            ? PARSE_CATEGORY[category]
            : QNA_CATEGORY[category]}
        </span>
        <p className="text-[20px] font-semibold leading-[1.3] tracking-[0.38px] text-[#DFDDE4]">
          {title}
        </p>
      </div>
    </div>
  );
};

export default CommunityTitle;

const QNA_CATEGORY: Record<string, string> = {
  METHOD: '운동 방법',
  INJURY_REHABILITATION: '부상 / 재활',
  NUTRITION_DIET: '영양 / 식단',
  LOSE_WEIGHT_BULK_UP: '다이어트 / 벌크업',
  COMPETITION_BODY_PROFILE: '대회 / 바디프로필'
};

const PARSE_CATEGORY: Record<string, string> = {
  KNOWHOW: '노하우',
  DAILY: '일상',
  CONCERN: '고민',
  COLUMN: '칼럼'
};
