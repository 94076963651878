import { useLocation, useNavigate, useParams } from 'react-router';
import { useRecoilState } from 'recoil';

import { CoachDetailApplyModal } from '#templates';
import { useGetCoachIdFromMyUrl } from '#api/my-url';
import { $modalFamily, MODAL_TYPE } from '#states/atoms/modalState';
import { useChat } from '#providers/Chat';
import { PATH } from '#const/path';
import { IGym } from '#types/gyms';

import { useTabListView } from 'src/components/molecules/TabListViewer';
import TrainerActivities from 'src/refactor_components/trainer-profile/trainer-activities';
import TrainerLecture from 'src/refactor_components/trainer-profile/trainer-lecture';
import TrainerIntro from 'src/refactor_components/trainer-profile/trainer-intro';
import TrainerQualification from 'src/refactor_components/trainer-profile/trainer-qualification';
import TrainerSchedule from 'src/refactor_components/trainer-profile/trainer-schedule';
import TrainerLocation from 'src/refactor_components/trainer-profile/trainer-location';
import RowContent from 'src/refactor_shared/ui/row-content';

type TrainerQualificationProps = {
  degree: {
    major: string;
  }[];
  licenses: {
    name: string;
  }[];
  awards: number;
};

type LecturesDataType = {
  id: string;
  lectureType: string;
  title: string;
  body: string;
  price: number;
  rounds: number;
};

type TrainerActivitiesInformationProps = {
  title: string;
  content: string;
  _count: {
    comments: number;
    likes: number;
  };
  image: { resizedLink: string };
  id: string;
};

type TrainerHomeProps = {
  data: {
    account: {
      shortIntroduction: string;
      introduction: string;
      name: string;
    };
    degree: {
      name: string;
      count: number;
    };
    career: {
      name: string;
      count: number;
    };
    license: {
      name: string;
      count: number;
    };
    award: {
      name: string;
      count: number;
    };
    education: {
      name: string;
      count: number;
    };
    qualifications: TrainerQualificationProps;
    schedules: Array<object>;
    gyms: IGym[];
  };
  lecturesData: LecturesDataType[];
  informationQuestion: TrainerActivitiesInformationProps[];
  portfolio: { data: object[] };
  qnaQuestion: { id: string; questionTitle: string; content: string }[];
};

const TrainerHome = ({
  data,
  lecturesData,
  informationQuestion,
  portfolio,
  qnaQuestion
}: TrainerHomeProps) => {
  const noContentForQualification =
    data?.degree?.name ||
    data?.career?.name ||
    data?.license?.name ||
    data?.award?.name ||
    data?.education?.name;

  const { myUrl } = useParams();

  const { setCurrentTab } = useTabListView();
  const navigate = useNavigate();
  const decodedMyUrl = decodeURIComponent(myUrl || '');

  const { data: id } = useGetCoachIdFromMyUrl(decodedMyUrl);

  const { pathname } = useLocation();
  const { openChat } = useChat();
  const [showApplyModal, setApplyModal] = useRecoilState(
    $modalFamily(MODAL_TYPE.APPLY)
  );

  const handleChatButtonClick = () => {
    const onSuccess = () => {
      navigate(PATH.CHAT_ROOM, {
        state: pathname
      });
    };
    const onFail = () => {
      // setShowLoginModal(true);
      navigate(PATH.SIGN, {
        state: { blockedPagePath: location.pathname + location.search }
      });
    };
    openChat(id ? id : '', onSuccess, onFail);
  };
  const handleApplyButtonClick = () => {
    setApplyModal(true);
  };

  return (
    <>
      <div className="flex flex-col w-full px-20 gap-60">
        {/* <TrainerBadge badge={[]} /> */}
        <TrainerIntro
          shortIntroduction={data.account.shortIntroduction}
          introduction={data.account.introduction}
        />
        {noContentForQualification && (
          <TrainerQualification data={data.qualifications} title="정보">
            <>
              <RowContent
                title="학력"
                content={data.degree.name}
                totalCounts={data.degree.count}
              />
              <RowContent
                title="경력"
                content={data.career.name}
                totalCounts={data.career.count}
              />
              <RowContent
                title="자격"
                content={data.license.name}
                totalCounts={data.license.count}
              />
              <RowContent
                title="수상"
                content={data.award.name}
                totalCounts={data.award.count}
              />
              <RowContent
                title="교육"
                content={data.education.name}
                totalCounts={data.education.count}
              />
              <TrainerQualification.More
                onHandleCustomClick={setCurrentTab}
                targetTab={1}
              />
            </>
          </TrainerQualification>
        )}
        {portfolio && informationQuestion ? (
          <TrainerActivities
            name={data.account.name}
            isVisible={
              informationQuestion?.length || qnaQuestion?.length ? true : false
            }
          >
            {/* <TrainerActivities.port clinicalDataList={portfolio} /> */}
            <TrainerActivities.info data={informationQuestion} />
            <TrainerActivities.qna data={qnaQuestion} />
          </TrainerActivities>
        ) : null}
        {lecturesData && (
          <TrainerLecture
            lecturesData={lecturesData}
            setShowModal={setApplyModal}
          />
        )}
        {data?.schedules && <TrainerSchedule data={data?.schedules} />}
        {data.gyms && <TrainerLocation recommendGyms={data.gyms} />}
      </div>
      <div className="fixed flex text-center bottom-0 max-w-939 w-full gap-17 bg-[#181818] py-8 px-20 z-30">
        <button
          className="bg-[#2E2B36] rounded-8 text-[#8E899F] w-[calc(50%+10px)] py-16 min-w-[62px] px-29 z-3"
          onClick={handleChatButtonClick}
        >
          채팅 상담
        </button>
        <button
          type="button"
          onClick={() => {
            handleApplyButtonClick();
          }}
          className="bg-[#7750E9] rounded-8 text-[#EAE8ED] py-16 w-[calc(100%-62px)] z-1000"
        >
          수업 신청하기
        </button>
      </div>
      {showApplyModal && (
        <CoachDetailApplyModal
          setShowModal={setApplyModal}
          coachId={id ? id : ''}
        />
      )}
    </>
  );
};

export default TrainerHome;
