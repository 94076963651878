import { Logo, XButton } from '#assets/svg';
import { KakaoButton } from '#molecules';

interface Props {
  onClose: () => void;
  kakaoLogin: () => void;
}

const CommunityOauthModal = ({ onClose, kakaoLogin }: Props) => {
  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex flex-col gap-48">
        <div className="flex flex-col gap-40">
          <div className="flex flex-col gap-12">
            <div className="flex items-center justify-between w-full">
              <Logo width={50} height={16} />
              <XButton onClick={onClose} />
            </div>
          </div>
          <h1 className="text-center">
            질문 등록과 답변 알림을 위해 <br /> 로그인을 진행해 주세요
          </h1>
        </div>
        <div className="flex flex-col gap-[13.5px]">
          <KakaoButton
            text="카카오로 3초만에 시작하기"
            icon
            onClick={kakaoLogin}
          />
          <span className="text-[10px] text-[#8E899F] text-center">
            정확한 답변을 위해 나이와 성별 정보를 자동으로 불러와요
          </span>
        </div>
      </div>
    </div>
  );
};

export default CommunityOauthModal;
