import * as S from './style';
import { useNavigate } from 'react-router';
import { SCHEDULE_REGISTRATION_TAB_LIST } from '../../constant';
import SaveIcon from '../../Icon/SaveIcon';
import SearchIcon from '../../Icon/SearchIcon';
import { Dispatch, SetStateAction, useState } from 'react';
import SearchBar from 'src/refactor_components/common/SearchBar';
import SearchBarCloseIcon from 'src/refactor_components/common/SearchBarCloseIcon';

interface Props {
  route: string;
  isQna: boolean;
  setSearchData: Dispatch<SetStateAction<string>>;
}

const CommunitySecion = ({ route, isQna, setSearchData }: Props) => {
  const [isSearching, setIsSearching] = useState(false);
  const navigate = useNavigate();
  return (
    <S.TabListSticky>
      <div className="flex max-w-939 mx-auto justify-between items-center w-full bg-[#121114] px-20">
        {!isSearching ? (
          <>
            <S.TabList>
              {SCHEDULE_REGISTRATION_TAB_LIST.map(item => (
                <S.TabItem
                  key={item.route}
                  isActive={item.route === route}
                  onClick={() => {
                    navigate(item.pathname);
                  }}
                >
                  {item.label}
                </S.TabItem>
              ))}
            </S.TabList>
            <div className="flex gap-16">
              <SaveIcon onClick={() => navigate('save')} />
              <SearchIcon onClick={() => setIsSearching(true)} />
            </div>
          </>
        ) : (
          <>
            <SearchBar
              setSearchData={setSearchData}
              placeholder={
                isQna
                  ? '궁금한 내용을 검색해 보세요'
                  : '알고 싶은 내용을 검색해 보세요'
              }
            />
            <button
              type="button"
              onClick={() => {
                setIsSearching(false);
                setSearchData('');
              }}
            >
              <SearchBarCloseIcon />
            </button>
          </>
        )}
      </div>
    </S.TabListSticky>
  );
};

export default CommunitySecion;
