import { ReactNode } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { INFORMATION_QUESTION_TYPE_LIST } from 'src/r_page/Information-community/constant';
import Title from 'src/refactor_components/common/Title';
import Select from 'src/shared/components/select/Select';

const CommunityWriteForms = ({ children }: { children: ReactNode }) => {
  return (
    <div className="flex flex-col justify-between gap-40 p-20 pb-184">
      {children}
    </div>
  );
};

const Categories = ({
  registerName,
  defaultValue
}: {
  registerName: string;
  defaultValue?: string;
}) => {
  const { setValue } = useFormContext();

  return (
    <div className="flex flex-col gap-8">
      <Title text="질문 분야" essential={true} />
      <Select
        selectContainerStyle={{ height: '53px' }}
        width={'100%'}
        defaultValue={defaultValue || '카테고리 선택'}
        options={INFORMATION_QUESTION_TYPE_LIST}
        onChange={value => {
          setValue(registerName, value);
        }}
      />
    </div>
  );
};

const TitleInput = ({ registerName }: { registerName: string }) => {
  const { register } = useFormContext();
  const titleValue = useWatch({ name: registerName }); // Watch for the title field value
  const titleLength = titleValue?.length || 0; // Calculate length or fallback to 0

  return (
    <div className="flex flex-col gap-8">
      <div className="items-center flexjustify-between ">
        <Title text="제목" essential={true}>
          <span className="text-[14px] text-[#8E899F]">{titleLength}자</span>
        </Title>
      </div>
      <input
        {...register(registerName)}
        className="w-full bg-transparent px-16 py-[11.5px] text-16 h-53 placeholder:text-[#5C576B] border border-solid border-[#4D495A] rounded-8"
        placeholder="최소 10자 이상 작성해 주세요"
      />
    </div>
  );
};

const ContentInput = ({ registerName }: { registerName: string }) => {
  const { register } = useFormContext();
  const titleValue = useWatch({ name: registerName }); // Watch for the title field value
  const titleLength = titleValue?.length || 0; // Calculate length or fallback to 0

  return (
    <div className="flex flex-col gap-8">
      <Title text="내용" essential={true}>
        <span className="text-[14px] text-[#8E899F]">{titleLength}자</span>
      </Title>

      <textarea
        {...register(registerName)}
        className="w-full focus:outline-none bg-transparent border border-[#494949] p-10 text-16 rounded-[5px] h-300 placeholder:text-[#4D495A]"
        placeholder="최소 20자 이상 작성해 주세요"
      />
    </div>
  );
};

CommunityWriteForms.Categories = Categories;
CommunityWriteForms.Title = TitleInput;
CommunityWriteForms.Content = ContentInput;

export default CommunityWriteForms;
