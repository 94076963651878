import { useNavigate } from 'react-router';
import {
  LatestReviewResponse,
  usePatchReviews,
  usePostReviewImgs,
  usePostReviewsMutation
} from '#api/reviews';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { createImgToFormData } from '#utils/createImgToFormData';
import { Image } from 'src/components/molecules/ImageWithUpload/hooks';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeyFactory } from '#api/queryKeyFactory';

interface Props {
  data: LatestReviewResponse | undefined;
}

export const useSubmitFinalReview = ({ data }: Props) => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const queryClient = useQueryClient();

  const { mutateAsync: postReviewImgsMutate } = usePostReviewImgs();
  const postReviewsMutation = usePostReviewsMutation();
  const patchReviewsMutation = usePatchReviews();

  const [reviewData, setReviewData] = useState({
    coachNice: 0,
    lectureQuality: 0,
    timeNice: 0,
    desc: ''
  });

  useEffect(() => {
    if (!data?.rate) return;

    setReviewData({
      coachNice: data.rate.coachNice,
      desc: data.rate.desc,
      lectureQuality: data.rate.lectureQuality,
      timeNice: data.rate.timeNice
    });
  }, [data]);

  const handleSubmit = (localImgs: Image[]) => {
    if (!data || !reviewData)
      return alert('일시적인 에러 입니다. 다시 시도해주세요.');

    if (data.hasPreviousReview) {
      patchReviewsMutation.mutate(
        {
          ...reviewData,
          registeredLectureId: data.registeredLectureId
        },
        {
          onSuccess: res => {
            queryClient.invalidateQueries(
              queryKeyFactory.GET_REGISTERED_LECTURES()
            );
            if (localImgs.length > 0)
              postReviewImgsMutate(
                {
                  rateId: res,
                  payload: createImgToFormData(localImgs)
                },
                {
                  onError: () => {
                    showToast({
                      type: 'fail',
                      message: '사진 등록에 실패했습니다.'
                    });
                  },
                  onSettled: () => {
                    navigate(PATH.FINAL_RATING_SUCCESS);
                  }
                }
              );
            navigate(PATH.HOME);
            showToast({
              type: 'success',
              message: '리뷰 등록에 성공했습니다.'
            });
          }
        }
      );
    } else {
      //둘다 없으면 post 별점 + desc post 보내기 lecture타입으로
      postReviewsMutation.mutate(
        {
          ...reviewData,
          type: 'LECTURE',
          registeredLectureId: data?.registeredLectureId
        },
        {
          onSuccess: (res: string) => {
            queryClient.invalidateQueries(
              queryKeyFactory.GET_REGISTERED_LECTURES()
            );
            if (localImgs.length > 0)
              postReviewImgsMutate(
                {
                  rateId: res,
                  payload: createImgToFormData(localImgs)
                },
                {
                  onError: () => {
                    showToast({
                      type: 'fail',
                      message: '사진 등록에 실패했습니다.'
                    });
                  },
                  onSettled: () => {
                    navigate(PATH.FINAL_RATING_SUCCESS);
                  }
                }
              );
            else navigate(PATH.FINAL_RATING_SUCCESS);
          },
          onError: () => {
            showToast({
              type: 'fail',
              message: '리뷰 등록에 실패했습니다.'
            });
          }
        }
      );
    }
  };

  return {
    patchReviewsMutation,
    handleSubmit,
    reviewData,
    setReviewData
  };
};
