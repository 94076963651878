import { Link } from 'react-router-dom';
import WriteIcon from '#assets/svg/write_icon.svg';

const WriteButton = ({ to }: { to: string }) => {
  return (
    <div className="fixed w-full -translate-x-1/2 pointer-events-none bottom-85 left-1/2 max-w-939 pr-25 z-1000">
      <Link
        to={to}
        className="ml-auto w-44 h-44 flex items-center justify-center bg-[#7750E9] rounded-full z-11 drop-shadow-lg pointer-events-auto"
      >
        <img src={WriteIcon} alt="Write" />
      </Link>
    </div>
  );
};

export default WriteButton;
