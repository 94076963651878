import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { axios } from '#api/axios';
import { useToast } from '#providers/ToastProvider';
import { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import { set } from 'lodash';

type InformationCommunityOptionsProps = {
  setIsModalOpen: Dispatch<SetStateAction<string>>;
  data: {
    title: string;
    content: string;
    category: string;
  };
};

const InformationCommunityOptions = ({
  data,
  setIsModalOpen
}: InformationCommunityOptionsProps) => {
  const { getValues, setValue } = useFormContext();
  const { id } = useParams();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: deleteThread } = useMutation({
    mutationFn: async () => await axios.delete(`/consumer/qna/questions/${id}`),
    onSuccess: () => {
      showToast({
        message: '글이 삭제되었습니다.'
      });

      queryClient.invalidateQueries({
        queryKey: ['get-information-community']
      });
      navigate('/community/qna');
    },
    onError: () => {
      showToast({
        type: 'fail',
        message: '글 삭제에 실패했습니다.'
      });
    }
  });

  const { mutate: deleteThreadComment } = useMutation({
    mutationFn: async (sequence: number) =>
      await axios.delete(`/consumer/exercise/answers?sequence=${sequence}`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get-information-community-thread-comment-count', id]
      });
      queryClient.invalidateQueries({
        queryKey: ['get-information-community-thread-comment', id]
      });

      showToast({
        message: '댓글을 삭제했습니다.'
      });
      setValue('sequence', undefined);
      setValue('edit', false);
      setIsModalOpen('');
      return;
    },
    onError: () => {
      showToast({
        type: 'fail',
        message: '일시적인 에러입니다. 다시 시도해 주세요.'
      });
    }
  });

  return (
    <div className="no-doc-scroll">
      <div className="flex flex-col justify-center items-center fixed bottom-0 left-0 w-full bg-[#18171C] z-12 text-center rounded-t-16">
        <button
          type="button"
          className="p-20 text-[#E13A3A] w-full"
          onClick={() =>
            getValues('sequence')
              ? deleteThreadComment(getValues('sequence'))
              : deleteThread()
          }
        >
          삭제
        </button>
        <button
          type="button"
          onClick={() => {
            if (getValues('sequence')) {
              setValue('edit', true);
              setIsModalOpen('');
            } else {
              navigate(`/community/qna/edit/${id}`, {
                state: {
                  title: data.title,
                  content: data.content,
                  category: data.category
                }
              });
            }
          }}
          className="p-20 text-[#EAE8ED] border-y-1 border-solid border-[#1F1E25] w-full"
        >
          수정
        </button>
        <button
          type="button"
          className="w-full p-20"
          onClick={() => {
            setIsModalOpen('');
            setValue('sequence', undefined);
          }}
        >
          닫기
        </button>
      </div>
      <div
        className="bg-[#2E2B36] opacity-70 h-full w-full absolute top-0 left-0 z-11"
        onClickCapture={() => {
          setIsModalOpen('');
          setValue('sequence', undefined);
        }}
      />
    </div>
  );
};

export default InformationCommunityOptions;
