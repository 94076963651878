import { PropsWithChildren, ReactElement, useMemo } from 'react';
import { Navigate } from 'react-router';

interface FunnelStepInterface
  extends PropsWithChildren<{ branchName: string }> {}

const useBranch = (initial: string) => {
  const Branch = (props: FunnelStepInterface): ReactElement => {
    return <>{props.children}</>;
  };

  const Trunk = ({
    branchTarget,
    children
  }: {
    branchTarget?: string;
    children: Array<ReactElement<{ branchName: string }>>;
  }) => {
    const memeChild = useMemo(() => children, [children]);
    const currentBranch = useMemo(
      () =>
        memeChild.find(
          childStep =>
            childStep.props.branchName ===
            (branchTarget ? branchTarget : initial)
        ),
      [branchTarget, memeChild]
    );

    return <>{currentBranch ?? <Navigate to={'/profile'} />}</>;
  };

  return { Trunk, Branch } as const;
};

export default useBranch;
