import Select from 'src/shared/components/select/Select';
import { HEALTH_INFO_LIST, HEALTH_INFO_SELECT_LIST } from './constant';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  healthInfoType: string;
  setIntensity: Dispatch<SetStateAction<string | number | null>>;
}

const HealthInfoSelect = ({ healthInfoType, setIntensity }: Props) => {
  const selectTitle = HEALTH_INFO_LIST.find(
    item => item.type === healthInfoType
  )?.selectTitle;

  return (
    <>
      {healthInfoType !== 'disease' && (
        <div className="flex flex-col gap-12">
          <div className="text-[#bcb8c3] text-base font-medium font-['Pretendard Variable'] leading-[21px]">
            {selectTitle}
          </div>
          <Select
            options={HEALTH_INFO_SELECT_LIST[healthInfoType]}
            onChange={value => setIntensity(value)}
          />
        </div>
      )}
    </>
  );
};

export default HealthInfoSelect;
