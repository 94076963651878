import styled from 'styled-components';

export const CoachInformationList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 56px;
`;

export const CoachInformation = styled.li`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

export const CoachShortsIntro = styled.p`
  padding: 24px 0;
  display: flex;
  gap: 10px;
  justify-content: center;
  font-weight: 700;
  white-space: pre-line;
  text-align: center;
  font-size: 18px;
  .grey {
    color: #5c576b;
  }
  border-bottom: 1px solid #7750e9;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const LabelBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const QualificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const QualificationBox = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  item-align: center;
`;

export const QualificationItem = styled.div`
  width: calc(34% - 12px);
  height: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin: auto 0;
  color: #eae8ed; /* 텍스트 색상 */
`;

export const CoachInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CoachInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  border-top: 1px solid #24222a;
`;

export const DetailList = styled.ul`
  gap: 0.4rem;
  overflow: hidden;
  transition: height 0.3s ease;
`;

export const InfoDetailBox = styled.li`
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0.8rem 0;
  justify-content: space-between;
`;

export const ClinicalDataContainer = styled.div`
  display: inline-block;
  width: 100%;
`;

export const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const WritingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const WritingList = styled.ul`
  display: flex;
  width: 100%;
  overflow-x: auto;
  touch-action: auto;
  gap: 12px;
`;
export const ArticleItemBox = styled.li`
  padding: 16px;
  display: flex;
  max-width: 320px;
  flex-direction: column;
  gap: 10px;
  background-color: #1f1e25;
  border-radius: 8px;
  flex-shrink: 0;
`;
export const ArticleInfo = styled.div`
  display: flex;
  gap: 12px;
  .title {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .description {
    font-size: 12px;
    color: #8e899f;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ImageBox = styled.img`
  border-radius: 4px;
  width: 64px;
  height: 64px;
`;

export const Line = styled.div`
  height: 0;
  border-top: 1px solid #24222a;
`;

export const CommentLikeCountBox = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
`;

export const CommentLikeItem = styled.div`
  display: flex;
  gap: 6px;
  font-size: 12px;
  align-items: center;
`;

export const AnswerItemBox = styled.li`
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 200px;
  gap: 6px;
  background-color: #1f1e25;
  border-radius: 8px;
  flex-shrink: 0;
  font-size: 13px;
  .description {
    color: #eae8ed;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const BadgeContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const BadgeItem = styled.div`
  width: 100%;
  display: flex;
  min-width: 160px;
  flex-direction: column;
  gap: 10px;
  font-size: 12px;
  align-items: center;
`;

export const IconBox = styled.div`
  width: 80px;
  height: 80px;
  padding: 12px;
  border-radius: 20px;
  border: 1px solid #24222a;
`;

const GoldGradation = 'linear-gradient(180deg, #FFDEA4 0%, #B78E5C 75.57%)';

export const BadgeLabel = styled.p<{ isTop: boolean }>`
  ${({ isTop }) =>
    isTop
      ? ` background: linear-gradient(180deg, #ffdea4 0%, #b78e5c 75.57%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;`
      : ''}

  color: ${({ isTop }) => (isTop ? GoldGradation : '#B098F9')};
  text-align: center;
`;

export const SubLabel = styled.p`
  color: ${GoldGradation};
  text-align: center;
`;
