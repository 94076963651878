import { useEffect, useState } from 'react';
import React from 'react';
import { getClinicalData, getClinicalDataList } from '#api/clinical-data';
import { IGetClinicalData, IPostClinicalData } from '#types/clinicalData';
import { IFileResponse } from '#types/images';

interface Props {
  children?: React.ReactNode;
}

interface ClinicalDataContext {
  coachId: string | null;
  setCoachId: React.Dispatch<React.SetStateAction<string | null>>;
  clinicalDataList: IGetClinicalData[];
  clinicalDataId: string | null;
  setClinicalDataId: React.Dispatch<React.SetStateAction<string | null>>;
  clinicalData: IPostClinicalData | null;
  setClinicalData: React.Dispatch<
    React.SetStateAction<IPostClinicalData | null>
  >;
  beforeImages: IFileResponse[];
  setBeforeImages: React.Dispatch<React.SetStateAction<IFileResponse[]>>;
  afterImages: IFileResponse[];
  setAfterImages: React.Dispatch<React.SetStateAction<IFileResponse[]>>;
  isLoading: boolean;
  isError: boolean;
  isListLoading: boolean;
  isListError: boolean;
  resetClinicalData: () => void;
}

const ClinicalDataContext = React.createContext<ClinicalDataContext | null>(
  null
);

export const useClinicalData = () => {
  const context = React.useContext(ClinicalDataContext);
  if (!context) {
    throw new Error(
      'This component must be used within a <ClinicalData> component.'
    );
  }
  return context;
};

function ClinicalData({ children }: Props) {
  const [coachId, setCoachId] = useState<string | null>(null);
  const [clinicalDataList, setClinicalDataList] = useState<IGetClinicalData[]>(
    []
  );
  const [clinicalDataId, setClinicalDataId] = useState<string | null>(null);
  const [clinicalData, setClinicalData] = useState<IPostClinicalData | null>(
    null
  );
  const [beforeImages, setBeforeImages] = useState<IFileResponse[]>([]);
  const [afterImages, setAfterImages] = useState<IFileResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isListLoading, setIsListLoading] = useState<boolean>(false);
  const [isListError, setIsListError] = useState<boolean>(false);

  const resetClinicalData = () => {
    setClinicalDataId(null);
    setClinicalData(null);
    setBeforeImages([]);
    setAfterImages([]);
    setIsLoading(false);
    setIsError(false);
  };

  useEffect(() => {
    if (coachId) {
      setIsListLoading(true);
      setIsListError(false);
      getClinicalDataList({ coachId, offset: 100 })
        .then(data => {
          setClinicalDataList(data.data);
        })
        .catch(() => setIsListError(true))
        .finally(() => setIsListLoading(false));
    } else {
      setClinicalDataList([]);
    }
  }, [coachId]);

  useEffect(() => {
    if (clinicalDataId) {
      setIsLoading(true);
      setIsError(false);
      getClinicalData(clinicalDataId)
        .then(data => {
          setClinicalData({
            lectureId: data.lectureId,
            rounds: data.rounds,
            body: data.body,
            lectureName: data.lectureName
          });
          setBeforeImages(data.befores);
          setAfterImages(data.afters);
        })
        .catch(() => setIsError(true))
        .finally(() => setIsLoading(false));
    } else {
      resetClinicalData();
    }
  }, [clinicalDataId]);

  const memorizedValue = React.useMemo(
    () => ({
      coachId,
      setCoachId,
      clinicalDataList,
      clinicalDataId,
      setClinicalDataId,
      clinicalData,
      setClinicalData,
      beforeImages,
      setBeforeImages,
      afterImages,
      setAfterImages,
      isLoading,
      isError,
      isListLoading,
      isListError,
      resetClinicalData
    }),
    [
      coachId,
      setCoachId,
      clinicalDataList,
      clinicalDataId,
      setClinicalDataId,
      clinicalData,
      setClinicalData,
      beforeImages,
      setBeforeImages,
      afterImages,
      setAfterImages,
      isLoading,
      isError,
      isListLoading,
      isListError,
      resetClinicalData
    ]
  );

  return (
    <ClinicalDataContext.Provider value={memorizedValue}>
      {children}
    </ClinicalDataContext.Provider>
  );
}

function IsLoading({ children }: Props) {
  const { isLoading } = useClinicalData();
  return isLoading ? <>{children}</> : null;
}

function IsError({ children }: Props) {
  const { isLoading, isError } = useClinicalData();
  return !isLoading && isError ? <>{children}</> : null;
}

function EmptyClinicalData({ children }: Props) {
  const { isLoading, isError, clinicalData } = useClinicalData();
  return !isLoading && !isError && !clinicalData ? <>{children}</> : null;
}

function HasClinicalData({ children }: Props) {
  const { isLoading, isError, clinicalData } = useClinicalData();
  return !isLoading && !isError && clinicalData ? <>{children}</> : null;
}

function IsListLoading({ children }: Props) {
  const { isListLoading } = useClinicalData();
  return isListLoading ? <>{children}</> : null;
}

function IsListError({ children }: Props) {
  const { isListLoading, isListError } = useClinicalData();
  return !isListLoading && isListError ? <>{children}</> : null;
}

function EmptyClinicalDataList({ children }: Props) {
  const { isListLoading, isListError, clinicalDataList } = useClinicalData();
  return !isListLoading && !isListError && !clinicalDataList.length ? (
    <>{children}</>
  ) : null;
}

function HasClinicalDataList({ children }: Props) {
  const { isListLoading, isListError, clinicalDataList } = useClinicalData();
  return !isListLoading && !isListError && clinicalDataList.length ? (
    <>{children}</>
  ) : null;
}

ClinicalData.IsError = IsError;
ClinicalData.IsLoading = IsLoading;
ClinicalData.EmptyClinicalData = EmptyClinicalData;
ClinicalData.HasClinicalData = HasClinicalData;
ClinicalData.IsListError = IsListError;
ClinicalData.IsListLoading = IsListLoading;
ClinicalData.EmptyClinicalDataList = EmptyClinicalDataList;
ClinicalData.HasClinicalDataList = HasClinicalDataList;

export default ClinicalData;
