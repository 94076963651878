import styled from 'styled-components';

export const Container = styled.div<{ type: string }>`
  display: flex;
  gap: 1rem;
  height: ${props => (props.type === 'schedule' ? '100%' : '22rem')};
`;

export const TitleBox = styled.div`
  flex-shrink: 0;
  width: 100%;
`;

export const BoxContainer = styled.div<{ type: string }>`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 2rem;
  border-radius: 1rem;
  background: ${props =>
    ({
      location: 'var(--color-point-gradient-r180)',
      diet: 'var(--color-white)',
      body_profile: '#D9FB8A',
      posture_fix: `linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%)`,
      cardio: '#FF5833',
      schedule: 'var(--color-white)'
    })[props.type] || 'var(--color-point-gradient-r180'};
  background-repeat: no-repeat;
  background-size: cover;
  gap: ${props => (props.type === 'schedule' ? '1.5rem' : '0')};
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const GridBox = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  /* align-items: center; */

  > p {
    align-self: end;
  }

  > img {
    justify-self: end;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  margin-top: 3rem;
  > :first-child {
    text-align: center;
  }

  & > div {
    overflow-x: auto;
    display: flex;
    gap: 1rem;
    align-items: center;
    scroll-snap-type: x mandatory;
  }
`;

export const PreviewTitleContainer = styled.div`
  > :first-child {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  border-radius: 0.8rem;
  padding: 1.5rem;
  height: 30rem;
  width: 310px;
  flex: none;
  scroll-snap-align: start;
  background-color: var(--color-gray);

  > :last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 0.8rem;
  padding: 1.5rem;
  height: 26rem;
  width: 95%;
  max-width: 35.5rem; // 트레이닝 성과 최대 크기와 맞춤
  background-color: var(--color-gray-scale);
  flex-shrink: 0;
  scroll-snap-align: start;

  > :last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;

  &.preview {
    overflow: hidden;

    > div > img {
      width: 16rem;
      height: 16rem;
      object-fit: contain;
      overflow: hidden;
    }
  }

  > div {
    position: relative;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;

    > div > img {
      width: 14rem;
      height: 14rem;
      border-radius: 0.5rem;
      object-fit: contain;
    }

    > div > video {
      width: 14rem;
      height: 14rem;
      border-radius: 0.5rem;
      object-fit: contain;
      border: 1px solid gray;
    }

    p {
      position: absolute;
      width: 24px;
      height: 24px;
      text-align: center;

      &.before {
        bottom: 0;
        left: 0;
        background-color: var(--color-gray);
        border-radius: 0 0.4rem 0 0.4rem;
      }

      &.after {
        bottom: 0;
        right: 0;
        background-color: var(--color-point);
        border-radius: 0.4rem 0 0.4rem 0;
      }
    }
  }
`;

export const ReviewLists = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ReviewCategory = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--color-gray-text-second);
`;

export const StarArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 14.6rem;
`;

export const IntroContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 1.6rem;
  padding: 1.5rem;
  background: var(--color-point-gradient);
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  > span {
    position: absolute;
    right: 1.5rem;
  }
`;
