import styled from 'styled-components';

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(63, 61, 67, 0.65);
  backdrop-filter: blur(1px);
  z-index: 40;
  display: flex;
  justify-content: center;
`;

export const ModalWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  max-width: 939px;
  border-radius: 0.8rem;
  max-height: 100%;
  overflow-y: auto;
  background-color: var(--color-black-800);
  z-index: 41; // ModalBackground보다 큰 값으로 설정

  &.transparent {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    /* width: fit-content; */
    width: 80%;
    max-width: 939px;

    > span {
      align-self: flex-end;
    }
  }

  > span {
    flex-shrink: 0;
  }

  > img.modal-img {
    position: relative;
    object-fit: contain;
    width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  > video.modal-video {
    position: relative;
    object-fit: contain;
    width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
`;
