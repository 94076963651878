import { useCallback, useState } from 'react';
import { useGetDiseaseKinds } from '#api/disease_kind';
import { useGetPainPartKinds } from '#api/pain-part-kinds';
import { ArrowBigButton, Button, Text } from '#atoms';
import {
  RegisterApplicationType,
  useApplications
} from '#providers/Applications';
import { FibudContainerTemplate, useProgress } from '#templates';
import { HealthSelectPage } from './HealthSelectPage';
import { Container, SelectBoxContainer, TextContainer } from './styles';

export type HealthData = Partial<RegisterApplicationType>['healthInfo'];

export function ApplicationHealth() {
  const { next } = useProgress();
  const diseasesData = useGetDiseaseKinds('병력').data;
  const painPartsData = useGetPainPartKinds('수술 이력 / 통증').data;
  const { currentApplication, updateCurrentApplications } = useApplications();
  const [diseasePageOpen, setDiseasePageOpen] = useState<boolean>(false);
  const [painPageOpen, setPainPageOpen] = useState<boolean>(false);
  const [careerPageOpen, setCareerPageOpen] = useState<boolean>(false);

  const updateDiseases = useCallback(
    ({
      diseases
    }: {
      diseases: { name: string; diseaseKindId: string }[];
      diseaseEtc?: string;
    }) =>
      updateCurrentApplications({
        healthInfo: {
          diseases
        }
      }),
    []
  );

  const updatePainParts = useCallback(
    ({
      painParts
    }: {
      painParts: { name: string; kindId: string; level?: number }[];
      painPartEtc?: string;
    }) => {
      updateCurrentApplications({
        healthInfo: {
          painParts
        }
      });
    },
    []
  );

  const onDiseaseSelectComplete = (healthData: HealthData) => {
    updateDiseases({
      diseases: healthData?.diseases ?? []
    });

    setDiseasePageOpen(false);
  };

  const onPainSelectComplete = (healthData: HealthData) => {
    updatePainParts({
      painParts: healthData?.painParts ?? []
    });

    setPainPageOpen(false);
  };

  return (
    <FibudContainerTemplate type="paddingWithBottomButton">
      <FibudContainerTemplate type="bottomButtonContainer">
        <Container>
          <TextContainer>
            <Text textStyle="t1">
              더 정확한 트레이닝을 위해, <br />
              추가 정보를 입력해주세요
            </Text>
          </TextContainer>
          <SelectBoxContainer>
            <div>
              <ArrowBigButton
                label="운동 경력"
                status={(
                  currentApplication?.healthInfo?.diseases?.map(
                    e => diseasesData?.find(d => d.id === e.diseaseKindId)?.name
                  ) || []
                ).join(', ')}
                onClick={() => setCareerPageOpen(true)}
              />
              <ArrowBigButton
                label="앓고 있는 질환"
                status={(
                  currentApplication?.healthInfo?.diseases?.map(
                    e => diseasesData?.find(d => d.id === e.diseaseKindId)?.name
                  ) || []
                ).join(', ')}
                onClick={() => setDiseasePageOpen(true)}
              />
              <ArrowBigButton
                label="수술 또는 통증 부위"
                status={
                  (
                    currentApplication?.healthInfo?.painParts?.map(
                      e => painPartsData?.find(d => d.id === e.kindId)?.name
                    ) || []
                  ).join(', ') || '-'
                }
                onClick={() => setPainPageOpen(true)}
              />
            </div>
          </SelectBoxContainer>
        </Container>
        {diseasePageOpen && (
          <HealthSelectPage
            onComplete={onDiseaseSelectComplete}
            data={diseasesData}
            type="disease"
          />
        )}
        {painPageOpen && (
          <HealthSelectPage
            onComplete={onPainSelectComplete}
            data={painPartsData}
            type="pain"
          />
        )}
        {careerPageOpen && (
          <HealthSelectPage
            onComplete={onPainSelectComplete}
            data={painPartsData}
            type="pain"
          />
        )}
      </FibudContainerTemplate>
      <FibudContainerTemplate type="bottomButton">
        <Button onClick={() => next()}>다음</Button>
      </FibudContainerTemplate>
    </FibudContainerTemplate>
  );
}
