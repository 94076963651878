import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-black-alpha75);
  z-index: 50;
  max-width: var(--max-width);
  margin: 0 auto;
`;

export const ApplyModalWrap = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4rem;
  padding: 2rem 2rem 3rem;
  background-color: var(--color-gray);
  border-radius: 1.25rem 1.25rem 0 0;
`;

export const SelectArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const PriceArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-white);
  position: relative;

  .unit {
    margin-left: 0.25rem;
  }

  .discount {
    position: absolute;
    top: 40px;
    left: 0;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  div {
    width: 5rem;
    text-align: center;
  }
`;

export const SelectBoxWrap = styled.ul`
  height: 5rem;
  padding: 0 1.5rem;
  overflow: hidden;
  background-color: var(--color-gray-second);
  border-radius: 0.8rem;

  &.on {
    height: 100%;

    > li > svg {
      transform: scaleY(-1) rotate(90deg);
    }
  }
`;

export const PlaceholderItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5rem;
  padding: 1rem 0;
  color: var(--color-gray-text-second);

  &.selected {
    color: var(--color-white);
  }

  svg {
    stroke: var(--color-gray-text-second);
    transform: rotate(90deg);
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  height: 5rem;
  padding: 1rem 0;
  color: var(--color-white);
  border-top: 1px solid var(--color-gray-text);
`;

export const CautionContainer = styled.div`
  text-align: center;
`;

export const QuantityInput = styled.div`
  input {
    text-align: center;
  }
`;
