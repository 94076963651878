import React from 'react';
import { useNavigate } from 'react-router';
import { useGetClinicalDataList } from '#api/clinical-data';
import { BoxIcon, Text } from '#atoms';
import { PATH } from '#const/path';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { CoachInfo } from 'src/components/templates/CoachDetailContent/CoachInfo/CoachInfo';
import { ProfileImgUploader } from './ProfileImgUploader';
import { InformationContainer, HR, UpdateItem } from './styles';

export function ProfileUpdate() {
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();
  const { data: clinicalDataList } = useGetClinicalDataList({
    coachId: userInfo?.id ?? ''
  });
  // InformationContainer 컴포넌트화 고려
  return (
    <PrevHeaderBarWithTitleTemplate
      prevRoute={PATH.PROFILE}
      title="프로필 등록"
      defaultBackground
    >
      {userInfo?.profile && (
        <ProfileImgUploader profileImgs={userInfo?.profile} />
      )}
      {userInfo && (
        <CoachInfo
          name={userInfo.name}
          tags={userInfo.tags}
          gymLocation={userInfo.recommendGyms[0]}
          grade={userInfo.grade}
          reviewCount={userInfo.reviewCount}
          clinicalDataCount={clinicalDataList?.total ?? 0}
          startAthletic={userInfo.startAthletic}
        />
      )}
      <HR />
      <InformationContainer>
        <Text textStyle="t2">수업 안내</Text>
        <UpdateItem onClick={() => navigate(PATH.PROFILE_SCHEDULE)}>
          <div>
            <Text textStyle="t3">수업 가능 시간</Text>
            <Text textColor="gray2">수업 가능한 시간을 선택해주세요</Text>
          </div>
          <BoxIcon type="next-arrow" onlyIcon={true} size={18} />
        </UpdateItem>
        <UpdateItem onClick={() => navigate(PATH.PROFILE_GYM)}>
          <div>
            <Text textStyle="t3">수업 가능 공간</Text>
            <Text textColor="gray2">수업 가능한 공간을 설정해주세요</Text>
          </div>
          <BoxIcon type="next-arrow" onlyIcon={true} size={18} />
        </UpdateItem>
        <UpdateItem onClick={() => navigate(PATH.PROFILE_CLASS)}>
          <div>
            <Text textStyle="t3">정규 수업</Text>
            <Text textColor="gray2">정규 수업을 등록해주세요</Text>
          </div>
          <BoxIcon type="next-arrow" onlyIcon={true} size={18} />
        </UpdateItem>
        <UpdateItem onClick={() => navigate(PATH.PROFILE_OT)}>
          <div>
            <div>
              <Text textStyle="t3" type="inline">
                체험 수업
              </Text>
              <Text textColor="gray2" type="inline">
                (선택)
              </Text>
            </div>
            <Text textColor="gray2">체험 수업을 등록해주세요</Text>
          </div>
          <BoxIcon type="next-arrow" onlyIcon={true} size={18} />
        </UpdateItem>
      </InformationContainer>
      <HR />
      <InformationContainer>
        <Text textStyle="t2">소개</Text>
        <UpdateItem onClick={() => navigate(PATH.PROFILE_CATEGORY)}>
          <div>
            <Text textStyle="t3">운동 카테고리</Text>
            <Text textColor="gray2">수업 가능한 운동을 선택해주세요</Text>
          </div>
          <BoxIcon type="next-arrow" onlyIcon={true} size={18} />
        </UpdateItem>
        <UpdateItem onClick={() => navigate(PATH.PROFILE_CLINICAL_DATA)}>
          <div>
            <Text textStyle="t3">트레이닝 성과</Text>
            <Text textColor="gray2">트레이닝 성과를 등록해주세요</Text>
          </div>
          <BoxIcon type="next-arrow" onlyIcon={true} size={18} />
        </UpdateItem>
        <UpdateItem onClick={() => navigate(PATH.PROFILE_SHORT_INTRODUCE)}>
          <div>
            <Text textStyle="t3">한 줄 소개</Text>
            <Text textColor="gray2">한 줄 소개를 입력해주세요</Text>
          </div>
          <BoxIcon type="next-arrow" onlyIcon={true} size={18} />
        </UpdateItem>
        <UpdateItem onClick={() => navigate(PATH.PROFILE_INTRODUCE)}>
          <div>
            <Text textStyle="t3">소개 글</Text>
            <Text textColor="gray2">소개글을 입력해주세요</Text>
          </div>
          <BoxIcon type="next-arrow" onlyIcon={true} size={18} />
        </UpdateItem>
        <UpdateItem onClick={() => navigate(PATH.PROFILE_DEGREE)}>
          <div>
            <div>
              <Text textStyle="t3" type="inline">
                학력사항
              </Text>
              <Text textColor="gray2" type="inline">
                (선택)
              </Text>
            </div>
            <Text textColor="gray2">학력사항을 입력해주세요</Text>
          </div>
          <BoxIcon type="next-arrow" onlyIcon={true} size={18} />
        </UpdateItem>
        <UpdateItem onClick={() => navigate(PATH.PROFILE_CERTIFICATE)}>
          <div>
            <div>
              <Text textStyle="t3" type="inline">
                자격사항
              </Text>
              <Text textColor="gray2" type="inline">
                (선택)
              </Text>
            </div>
            <Text textColor="gray2">자격사항을 입력해주세요</Text>
          </div>
          <BoxIcon type="next-arrow" onlyIcon={true} size={18} />
        </UpdateItem>
        <UpdateItem onClick={() => navigate(PATH.PROFILE_CARRER)}>
          <div>
            <div>
              <Text textStyle="t3" type="inline">
                경력사항
              </Text>
              <Text textColor="gray2" type="inline">
                (선택)
              </Text>
            </div>
            <Text textColor="gray2">경력사항을 입력해주세요</Text>
          </div>
          <BoxIcon type="next-arrow" onlyIcon={true} size={18} />
        </UpdateItem>
      </InformationContainer>
    </PrevHeaderBarWithTitleTemplate>
  );
}
