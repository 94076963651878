import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { IGetHealthInfo } from '#types/healthInfo';

const getHealthInfos = async () => {
  const res = await request<IGetHealthInfo>({
    method: 'get',
    url: '/health-infos'
  });

  return res.data;
};

export const useGetHealthInfos = () => {
  return useQuery(queryKeyFactory.GET_HEALTH_INFOS(), getHealthInfos);
};
