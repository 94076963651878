import { useGetAddress } from '#api/location';
import { LocationPin } from '#assets/svg';
import { HEADER_HEIGHT } from '#const/layout';
import { IGym } from '#types/gyms';
import { getCoachCareer } from '#utils/timeFormatter';
import { useEffect, useRef, useState } from 'react';
import {
  CoachInfoWrap,
  GradeArea,
  LocationArea,
  NameArea,
  TagArea
} from './styles';

type Tags = { id: string; name: string };

interface Props {
  name: string;
  tags: Tags[];
  gymLocation: IGym;
  grade: string;
  reviewCount: number;
  startAthletic?: string;
  clinicalDataCount: number;
  nameLocation?: React.MutableRefObject<number>;
}

export const CoachInfo = ({
  name,
  tags,
  gymLocation,
  // grade,
  // reviewCount,
  startAthletic,
  clinicalDataCount,
  nameLocation
}: Props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const careerYear = getCoachCareer(startAthletic);
  const { data: gymAddressData } = useGetAddress(
    gymLocation?.long,
    gymLocation?.lat
  );
  const [gymAddress, setGymAddress] = useState<string>('');

  useEffect(() => {
    if (gymAddressData && gymAddressData.documents?.length > 0) {
      setGymAddress(
        `${gymAddressData.documents[0].address.region_2depth_name} ${gymAddressData.documents[0].address.region_3depth_name}`
      );
      // pre-rendering할 때는 제외
    } else if (window.navigator.userAgent === 'react-hydratable') {
      setGymAddress('');
    } else {
      setGymAddress('위치정보 없음');
    }
  }, [gymAddressData]);

  useEffect(() => {
    const nameRef = divRef.current;
    if (!nameRef) return;
    const nameRefLocation = nameRef.getBoundingClientRect();
    if (!nameLocation) return;
    nameLocation.current =
      nameRefLocation.top + nameRefLocation.height - HEADER_HEIGHT;
  }, []);

  return (
    <CoachInfoWrap>
      <TagArea data-text-type="c1">
        {tags.map(tag => (
          <p key={tag.id}>{tag.name}</p>
        ))}
      </TagArea>
      <NameArea data-text-type="t1" ref={divRef}>
        <p>{name} 전문가</p>
      </NameArea>
      <LocationArea data-text-type="c1">
        <LocationPin />
        <p>{gymAddress}</p>
      </LocationArea>
      <GradeArea data-text-type="c1">
        {/* <Star />
        <p>{Number(grade).toFixed(1)}</p>
        <p>수업리뷰 {reviewCount ?? 0}건</p> */}
        <p>경력 {careerYear}년</p>
        <p>트레이닝 성과 {clinicalDataCount}건</p>
      </GradeArea>
    </CoachInfoWrap>
  );
};
