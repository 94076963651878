import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #181818;
`;

export const HeaderConatiner = styled.div<{
  headerbarType: 'absolute' | 'relative';
  defaultBackground?: boolean;
  hasBorder?: boolean;
}>`
  position: ${({ headerbarType }) => headerbarType};

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 5.8rem;
  padding: 0 2rem;
  z-index: 10;
  color: var(--color-white);
  box-sizing: border-box;
  ${({ hasBorder }) =>
    hasBorder && 'border-bottom: 1px solid var(--color-gray)'};
  background-color: ${({ defaultBackground }) =>
    defaultBackground ? 'var(--color-background)' : 'none'};

  & > p {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
`;

export const ChildrenContainer = styled.article<{
  headerbarType: 'absolute' | 'relative';
}>`
  position: relative;
  width: 100%;
  height: ${({ headerbarType }) =>
    headerbarType === 'absolute' ? '100svh' : 'calc(100svh - 5.8rem)'};
  padding-top: ${({ headerbarType }) =>
    headerbarType === 'absolute' ? '5.8rem' : '0'};
  overflow-x: hidden;
  overflow-y: auto;
`;

export const ButtonPrev = styled.button``;

export const RightContents = styled.div``;
