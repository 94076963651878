import { ReactNode } from 'react';

const Title = (props: {
  essential?: boolean;
  text: string;
  children?: ReactNode;
}) => {
  return (
    <div className="flex items-center justify-between w-full">
      <p>
        <span className="text-[#C9C7D1]">{props.text}</span>
        {props.essential && <span className="pl-5 text-[#9170F2]">*</span>}
      </p>
      {props.children}
    </div>
  );
};

export default Title;
