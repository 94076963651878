import { useQuery } from '@tanstack/react-query';
import request from '#api/axios';
import { queryKeyFactory } from '#api/queryKeyFactory';
import { IGetCoachSchedules } from '#types/api';
import {
  IGetCoachSchedule,
  IGetCoachSchedulePerWeek,
  Schedule,
  SendingLecture
} from '#types/schedule';

export const getCoachSchedules = async (
  coachId: string,
  startAt: string,
  gymId?: string
) => {
  const res = await request<IGetCoachSchedules>({
    method: 'get',
    url: `/schedules`,
    params: {
      coachId,
      gymId,
      startAt
    }
  });

  return res.data;
};

export const useGetCoachSchedules = (
  coachId: string,
  startAt: string,
  gymId?: string
) => {
  return useQuery(
    queryKeyFactory.GET_COACH_SCHEDULES(coachId, startAt, gymId),
    () => getCoachSchedules(coachId, startAt, gymId)
  );
};

export const postCoachSchedules = async (payload: Schedule[]) => {
  const res = await request({
    method: 'post',
    url: '/schedules',
    data: payload
  });

  return res.data;
};

export const postImpossibleSchedules = async (at: string) => {
  const res = await request({
    method: 'post',
    url: `/impossible-schedules`,
    data: { at }
  });

  return res.data;
};

export const getCoachSchedule = async ({
  coachId,
  gymId,
  startAt
}: {
  coachId: string;
  gymId?: string;
  startAt: string;
}) => {
  const res = await request<IGetCoachSchedule>({
    method: 'get',
    url: `/schedules`,
    params: {
      coachId,
      gymId,
      startAt
    }
  });

  return res.data;
};

export const deleteImpossibleSchedules = async (id: string) => {
  const res = await request({
    method: 'delete',
    url: `/impossible-schedules/${id}`
  });

  return res.data;
};

export const useGetCoachSchedule = ({
  coachId,
  gymId,
  startAt
}: {
  coachId: string;
  gymId?: string;
  startAt: string;
}) => {
  return useQuery(
    queryKeyFactory.GET_COACH_SCHEDULE({ coachId, gymId, startAt }),
    () => getCoachSchedule({ coachId, gymId, startAt }),
    { enabled: !!coachId }
  );
};

export const deleteCoachSchedule = async () => {
  const res = await request<IGetCoachSchedule>({
    method: 'delete',
    url: `/schedules`
  });

  return res.data;
};

export const getCoachSchedulePerWeek = async ({
  startAt,
  durationDays
}: {
  startAt: string;
  durationDays: number;
}) => {
  const res = await request<IGetCoachSchedulePerWeek>({
    method: 'get',
    params: {
      startAt: startAt,
      durationDays: durationDays ? 7 : durationDays
    },
    url: `/schedules/coach`
  });
  return res.data;
};

export const useGetCoachSchedulePerWeek = ({
  startAt,
  durationDays
}: {
  startAt: string;
  durationDays: number;
}) => {
  return useQuery(
    queryKeyFactory.GET_SCHEDULE_PER_WEEK(startAt, durationDays),
    () => getCoachSchedulePerWeek({ startAt, durationDays }),
    { enabled: !!startAt }
  );
};

export const postScheduleCoach = async (payload: SendingLecture) => {
  const res = await request({
    url: `/schedules/coach`,
    method: 'POST',
    data: payload
  });

  return res.data;
};

export const deleteScheduleCoach = async (id: string, status: string) => {
  const res = await request({
    method: 'DELETE',
    url: `/schedules/coach/${id}`,
    params: {
      status: status,
      applyToAll: false
    }
  });
  return res.data;
};

export interface TodaySchedules {
  registerdLectureId: string;
  round: number;
  totalRound: number;
  reservationAt: Date;
  confirmationFormId: string;
  lectureId: string;
  title: string;
  gymId: string;
  gymName: string;
}

export const getTodaySchedules = async () => {
  const res = await request<TodaySchedules>({
    method: 'get',
    url: '/consumer/home/schedules'
  });
  return res;
};

export const useGetTodaySchedulesQuery = () =>
  useQuery(['todaySchedules'], getTodaySchedules);
