type FlexProps = {
  children: React.ReactNode;
  direction: 'row' | 'column';
  justify:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  align: 'flex-start' | 'flex-end' | 'center' | 'stretch';
  wrap: 'nowrap' | 'wrap' | 'wrap-reverse';
  gap: number;
  style?: React.CSSProperties;
  className?: string;
  fullWidth?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export function Flex({
  children,
  direction,
  justify,
  align,
  wrap,
  gap,
  style,
  className,
  fullWidth,
  ...props
}: FlexProps) {
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection: direction,
        justifyContent: justify,
        alignItems: align,
        flexWrap: wrap,
        gap: `${gap}px`,
        width: fullWidth ? '100%' : 'auto',
        ...style
      }}
      {...props}
    >
      {children}
    </div>
  );
}

Flex.defaultProps = {
  direction: 'row',
  justify: 'flex-start',
  align: 'stretch',
  wrap: 'nowrap',
  gap: 0
};
