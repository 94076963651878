interface Props {
  isLiked?: boolean;
}

const LikeIcon = ({ isLiked }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      {isLiked ? (
        <path
          d="M9.44665 0.666667L4.66665 5.45333L4.66665 14L12.8733 14L15.3333 8.26667L15.3333 5.33333L9.79331 5.33333L10.54 1.74667L9.44665 0.666667ZM3.33331 14L0.666647 14L0.666648 6L3.33331 6L3.33331 14Z"
          fill="#9170F2"
        />
      ) : (
        <>
          <path
            d="M0.666678 14L3.33334 14L3.33334 6L0.666678 6L0.666678 14Z"
            fill="#C9C7D1"
          />
          <path
            d="M15.3333 8.26667L15.3333 5.33333L9.79334 5.33333L10.54 1.74667L9.44668 0.666667L4.66668 5.45333L4.66668 14L12.8733 14L15.3333 8.26667ZM6.00001 12.6667L6.00001 6.00667L8.89334 3.10667L8.15334 6.66667L14 6.66667L14 7.99333L11.9933 12.6667L6.00001 12.6667Z"
            fill="#C9C7D1"
          />
        </>
      )}
    </svg>
  );
};

export default LikeIcon;
