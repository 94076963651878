import { atom } from 'recoil';

const ATOM_KEY = 'confirmationformstate';
const LECTURE_KEY = 'selectedLecturestate';

interface ISchedule {
  year: string;
  month: string;
  date: string;
  time?: string;
}

export interface IConfirmationformState {
  isValidData: boolean;
  coachId: string | null;
  lectureId: string | null;
  gymId: string | null;
  recommendRoundPerWeek: number | null;
  quantity: number | null;
  height: number | null;
  weight: number | null;
  sleepingHourAvg: number | null;
  qualityOfSleep: number | null;
  diseases: string[] | null;
  diseasesNames: string[] | null;
  diseaseEtc: string | null;
  exerciseExperienceDuration: number | null;
  jobName: string | null;
  painParts: string[] | null;
  painPartNames: string[] | null;
  etc: string | null;
  purposeIds: string[] | null;
  purposeNames: string[] | null;
  selectedScheduleList: ISchedule[] | null;
  lectureName: string | null;
  coachName: string | null;
  gymName: string | null;
  unitPrice: number | null;
}

export const $confirmationFormState = atom<IConfirmationformState>({
  key: ATOM_KEY,
  default: {
    isValidData: false,
    coachId: null,
    lectureId: null,
    gymId: null,
    recommendRoundPerWeek: null,
    quantity: null,
    height: null,
    weight: null,
    sleepingHourAvg: null,
    qualityOfSleep: null,
    diseases: null,
    diseasesNames: null,
    diseaseEtc: null,
    exerciseExperienceDuration: null,
    jobName: null,
    painParts: null,
    painPartNames: null,
    purposeIds: null,
    purposeNames: null,
    etc: null,
    selectedScheduleList: null,
    lectureName: null,
    coachName: null,
    gymName: null,
    unitPrice: null
  }
});

export const $selectedLectureState = atom<{
  name: string;
  id: string;
}>({
  key: LECTURE_KEY,
  default: {
    name: '',
    id: ''
  }
});
