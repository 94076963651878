import HomeIcon from '#assets/Icon/ic_home_icon';
import CommunityIcon from '#assets/Icon/navigation/CommunityIcon';
// import PortfolioIcon from '#assets/Icon/navigation/PortfolioIcon';
import ProfileIcon from '#assets/Icon/navigation/ProfileIcon';
import SearchIcon from '../community/Icon/SearchIcon';

export const NAVIGATION_LIST = [
  {
    name: '홈',
    route: '/main',
    icon: HomeIcon
  },
  {
    name: '트레이너',
    route: '/trainerList',
    icon: SearchIcon
  },
  {
    name: '운동톡',
    route: '/community/qna',
    icon: CommunityIcon,
    incldues: '/community'
  },
  {
    name: '마이 피벗',
    route: '/mypage',
    icon: ProfileIcon
  }
];
