import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useGetCoachLectures } from '#api/lectures';
import { Button, SelectBox, Spacing, Text, Textarea } from '#atoms';
import { PATH } from '#const/path';
import { BorderlessInput, ImageWithUpload, TitleItemBox } from '#molecules';
import { useClinicalData } from '#providers/ClinicalData';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import { IPostClinicalData } from '#types/clinicalData';
import { imageFileType } from '#types/fileType';
import { isNumeric } from '#utils/Numbers';
import { useUploadImages } from 'src/components/molecules/ImageWithUpload/hooks';
import { useModifyClinicalData, useSubmitClinicalData } from './hooks';
import { ButtonContainer, Container, InputArea } from './styles';

const maxCount = 6;

export function ProfileClinicalDataFormPage() {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { userInfo } = useUserInfo();
  const { data: lectures } = useGetCoachLectures(userInfo?.id ?? '');
  const lectureIdnNames =
    lectures?.data.map(e => ({
      id: e.id,
      name: e.title
    })) ?? [];
  const {
    setCoachId,
    clinicalDataId,
    clinicalData,
    beforeImages,
    afterImages,
    resetClinicalData
  } = useClinicalData();

  const [form, setForm] = useState<Partial<IPostClinicalData> | null>(
    clinicalData ?? null
  );

  const {
    localImgs: localBeforeImgs,
    handleUploadImages: handleUploadBeforeImages,
    handleDeleteLocalImage: handleDeleteLocalBeforeImage,
    postedImgs: postedBeforeImgs,
    handleDeletePostedImage: handleDeletePostedBeforeImage
  } = useUploadImages({
    fileType: 'clinical-data',
    images: beforeImages.map(e => ({
      id: e.id,
      resizedLink: e.resizedLink,
      shortLink: e.shortLink,
      uploadedLink: e.link,
      type: e.type
    })),
    maxCount
  });

  const {
    localImgs: localAfterImgs,
    handleUploadImages: handleUploadAfterImages,
    handleDeleteLocalImage: handleDeleteLocalAfterImage,
    postedImgs: postedAfterImgs,
    handleDeletePostedImage: handleDeletePostedAfterImage
  } = useUploadImages({
    fileType: 'clinical-data',
    images: afterImages.map(e => ({
      id: e.id,
      resizedLink: e.resizedLink,
      shortLink: e.shortLink,
      uploadedLink: e.link,
      type: e.type
    })),
    maxCount
  });
  const onFormChange = (value: Partial<IPostClinicalData>) => {
    setForm(prev => ({
      ...prev,
      ...value
    }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    return isNumeric(value)
      ? onFormChange({ rounds: Number(value) })
      : value === ''
      ? onFormChange({ rounds: undefined })
      : null;
  };

  const submitClinicalData = useSubmitClinicalData();
  const modifyClinicalData = useModifyClinicalData();

  const handleSubmitButtonClick = () => {
    if (form?.body && form.body.length < 30) {
      showToast({
        type: 'fail',
        message: '내용을 30자 이상 등록해주세요.'
      });
    } else if (
      localBeforeImgs.length + postedBeforeImgs.length === 0 &&
      localAfterImgs.length + postedAfterImgs.length === 0
    ) {
      showToast({
        type: 'fail',
        message: '사진을 추가해주세요.'
      });
    } else if (
      localBeforeImgs.length + postedBeforeImgs.length !==
      localAfterImgs.length + postedAfterImgs.length
    ) {
      showToast({
        type: 'fail',
        message: '전후 사진 수를 맞춰주세요.'
      });
    } else {
      clinicalDataId ? handleModifyButtonClick() : handlePostButtonClick();
    }
  };

  const handlePostButtonClick = () => {
    submitClinicalData({
      payload: {
        lectureId: form?.lectureId ?? '',
        rounds: form?.rounds ?? 0,
        body: form?.body ?? '',
        beforeImages: localBeforeImgs,
        afterImages: localAfterImgs
      },
      onSuccess: () => {
        setCoachId(null);
        showToast({
          message: '트레이닝 성과 등록에 성공하였습니다.'
        });
        navigate(PATH.PROFILE_CLINICAL_DATA);
      },
      onFail: () =>
        showToast({
          type: 'fail',
          message: '트레이닝 성과 등록에 실패하였습니다.'
        })
    });
  };

  const handleModifyButtonClick = () => {
    const deletedBeforeImgIds = beforeImages
      .filter(
        value => !postedBeforeImgs.some(postedImg => postedImg.id === value.id)
      )
      .map(value => value.id);
    const deletedAfterImgIds = afterImages
      .filter(
        value => !postedAfterImgs.some(postedImg => postedImg.id === value.id)
      )
      .map(value => value.id);

    clinicalDataId &&
      form &&
      form.rounds &&
      form.body &&
      modifyClinicalData({
        payload: {
          clinicalDataId,
          rounds: form.rounds,
          body: form.body,
          deletedBeforeImgIds,
          deletedAfterImgIds,
          localBeforeImgs,
          localAfterImgs
        },
        onSuccess: () => {
          //reset후 clinicalDataPage에서 coachId로 refetch
          setCoachId(null);
          resetClinicalData();
          showToast({
            message: '트레이닝 성과 수정에 성공하였습니다.'
          });
          navigate(PATH.PROFILE_CLINICAL_DATA);
        },
        onFail: () => {
          showToast({
            type: 'fail',
            message: '트레이닝 성과 수정에 실패하였습니다.'
          });
        }
      });
  };

  return (
    <>
      <PrevHeaderBarWithTitleTemplate
        // prevRoute={PATH.PROFILE_CLINICAL_DATA}
        title="트레이닝 성과"
        defaultBackground
        onPrevCallback={() => {
          resetClinicalData();
          navigate(PATH.PROFILE_CLINICAL_DATA);
        }}
      >
        <Container>
          <TitleItemBox title="수업 이름">
            {clinicalDataId ? (
              <Text>{form?.lectureName ?? ''}</Text>
            ) : (
              <SelectBox
                placeholder="수업을 선택해주세요"
                data={lectureIdnNames}
                onChange={e => onFormChange({ lectureId: e })}
              />
            )}
          </TitleItemBox>
          <TitleItemBox title="수업 횟수" type="row">
            <InputArea>
              <Text textColor="gray2">총</Text>
              <BorderlessInput
                name="secondDigit"
                value={form?.rounds ?? ''}
                maxLength={3}
                onChange={handleInputChange}
                textAlign="center"
                placeholder="예)10"
              />{' '}
              <Text textColor="gray2">회</Text>
            </InputArea>
          </TitleItemBox>
          <TitleItemBox title="내용">
            <Textarea
              value={form?.body ?? ''}
              onChange={e => onFormChange({ body: e })}
              height={12.8}
              limit={300}
              placeholder="회원님들이 참고할 수 있도록 운동 커리큘럼과 운동 효과에 대해 알려주세요(최소 30자)."
            />
          </TitleItemBox>
          <TitleItemBox title="사진 및 영상" type="images">
            <Spacing size={10} />
            <div>
              <Text textStyle="c1">수업 전</Text>
              <ImageWithUpload
                postedImages={postedBeforeImgs}
                localImages={localBeforeImgs}
                onUploadImage={handleUploadBeforeImages}
                onDeleteLocalImage={handleDeleteLocalBeforeImage}
                onDeletePostedImage={handleDeletePostedBeforeImage}
                maxCount={maxCount}
                fileType={imageFileType}
              />
            </div>
            <Spacing size={20} />
            <Text textStyle="c1">수업 후</Text>
            <ImageWithUpload
              postedImages={postedAfterImgs}
              localImages={localAfterImgs}
              onUploadImage={handleUploadAfterImages}
              onDeleteLocalImage={handleDeleteLocalAfterImage}
              onDeletePostedImage={handleDeletePostedAfterImage}
              maxCount={maxCount}
              fileType={''}
            />
          </TitleItemBox>
        </Container>
      </PrevHeaderBarWithTitleTemplate>
      <ButtonContainer>
        <Button
          disabled={!form || !form.lectureId || !form.rounds || !form.body}
          onClick={handleSubmitButtonClick}
        >
          완료
        </Button>
      </ButtonContainer>
    </>
  );
}
