import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useUserInfo } from '#providers/UserInfo';
import { PrevHeaderBarWithTitleTemplate } from '#templates';

import Title from 'src/refactor_components/common/Title';
import { useMutation } from '@tanstack/react-query';
import { patchAccount, useGetAccountMyPage } from '#api/accounts';
import { useToast } from '#providers/ToastProvider';
import { axios } from '#api/axios';
import { Modal } from '#molecules';
import { useNavigate } from 'react-router';

const SettingsAccount = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const { session, logout } = useUserInfo();
  const [onEdit, setOnEdit] = useState(false);
  const [doomsday, setDoomsday] = useState(false);

  const { data } = useGetAccountMyPage();

  const { showToast } = useToast();

  useEffect(() => {
    methods.reset({
      nickname: data?.nickname,
      email: data?.email,
      phone: data?.phone
    });
  }, [data]);

  const { mutate } = useMutation({
    mutationKey: ['update-nickname'],
    mutationFn: patchAccount,
    onSuccess: () => {
      showToast({
        type: 'success',
        message: '닉네임 수정에 성공하였습니다.'
      });
      setOnEdit(false);
    },
    onError: () => {
      showToast({
        type: 'fail',
        message: '닉네임 수정에 실패하였습니다.'
      });
    }
  });

  const { mutate: doomsdayMutate } = useMutation({
    mutationFn: () =>
      axios.delete('/accounts', {
        data: {
          reason: ''
        }
      }),
    onSuccess: () => {
      showToast({
        type: 'success',
        message: '그동안 이용해주셔서 감사합니다. 다시 만나요!!'
      });
    }
  });

  useEffect(() => {
    !session && navigate('/');
  }, [session]);

  return (
    <PrevHeaderBarWithTitleTemplate title="계정 관리" prevRoute="../.">
      <FormProvider {...methods}>
        <form className="flex flex-col h-full gap-20 p-20">
          <div className="flex gap-8 items-center p-16 rounded-16 bg-[#1F1E25]">
            <img
              src={data?.profile[0]?.resizedLink}
              className="object-cover w-48 h-48 rounded-full"
            />
            <p className="flex flex-col gap-4">
              <span className="text-[14px] text-[#C9C7D1]">
                {data?.name} 트레이너님
              </span>
              <span className="text-[14px] text-[#8E899F]">
                {data?.lType === 'KAKAO'
                  ? '카카오로 로그인'
                  : '이메일로 로그인'}
              </span>
            </p>
          </div>
          <div className="flex flex-col gap-20 p-16 rounded-16 bg-[#1F1E25]">
            <div className="flex items-center justify-between w-full">
              <p className="text-[18px] text-[#EAE8ED]">개인 정보 관리</p>
              <button
                type="button"
                className={`${
                  !onEdit ? 'text-[#C9C7D1]' : 'text-[#9170F2]'
                } text-[14px]`}
                onClick={() => {
                  if (!onEdit) {
                    setOnEdit(prev => !prev);
                  } else {
                    mutate({ nickname: methods.getValues('nickname') });
                  }
                }}
              >
                {!onEdit ? '정보 수정' : '정보 수정 완료'}
              </button>
            </div>
            <div className="flex flex-col gap-4">
              <Title text="활동 닉네임" />
              <input
                {...methods.register('nickname')}
                className="py-8 px-16 rounded-4 disabled:bg-[#24222A] bg-[#2E2B36] disabled:text-[#C9C7D1]"
                disabled={!onEdit}
              />
            </div>
            <div className="flex flex-col gap-4">
              <Title text="휴대전화 번호" />
              <input
                {...methods.register('phone')}
                className="py-8 px-16 rounded-4 disabled:bg-[#24222A] bg-[#2E2B36] disabled:text-[#C9C7D1]"
                disabled
              />
            </div>
            <div className="flex flex-col gap-4">
              <Title text="이메일" />
              <input
                {...methods.register('email')}
                className="py-8 px-16 rounded-4 disabled:bg-[#24222A] bg-[#2E2B36] disabled:text-[#C9C7D1]"
                disabled
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-20 mt-auto">
            <button
              type="button"
              onClick={() => logout()}
              className="w-full py-16 rounded-8 text-[#8E899F] bg-[#2E2B36] text-center"
            >
              로그아웃 하기
            </button>
            {/* <button
              type="button"
              onClick={() => setDoomsday(true)}
              className="mx-auto text-[#8E899F] text-[12px]"
            >
              회원 탈퇴하기
            </button> */}
          </div>
        </form>
      </FormProvider>
      {doomsday && (
        <Modal onClose={() => setDoomsday(false)}>
          <div className="flex flex-col w-full gap-20 p-16 rounded-16 bg-[#1F1E25]">
            <p className="text-[18px] text-[#EAE8ED] text-left">
              회원 탈퇴하기
            </p>
            <p className="text-[14px] text-[#8E899F]">
              회원 탈퇴 시, 모든 정보가 삭제되며 복구가 불가능합니다. 정말로
              탈퇴하시겠습니까?
            </p>
            <button
              type="button"
              onClick={() => doomsdayMutate()}
              className="w-fit self-end px-20 py-16 mt-10 text-[14px] rounded-8 text-[#E13A3A] bg-[#2E2B36] text-center"
            >
              회원 탈퇴하기
            </button>
          </div>
        </Modal>
      )}
    </PrevHeaderBarWithTitleTemplate>
  );
};

export default SettingsAccount;
