import { $selectedLectureState } from '#states/atoms/confirmationFormState';
import { Dispatch, SetStateAction } from 'react';
import { useSetRecoilState } from 'recoil';
import EmptyContent from 'src/refactor_shared/ui/empty-content';

const LECTURE_TYPE: { [key: string]: string } = {
  isOnePoint: '원포인트',
  isOt: '체험수업',
  lecture: '정규'
};

type LecturesDataType = {
  id: string;
  lectureType: string;
  title: string;
  body: string;
  price: number;
  rounds: number;
};

const TrainerLecture = ({
  setShowModal,
  lecturesData
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  lecturesData: Array<LecturesDataType>;
}) => {
  const setSelectedLecture = useSetRecoilState($selectedLectureState);

  return (
    <div className="flex flex-col gap-20">
      <p className="text-[18px] font-bold">수업 정보</p>
      {lecturesData ? (
        lecturesData.map((lecture: LecturesDataType, index: number) => {
          return (
            <button
              type="button"
              onClick={() => {
                setShowModal(true);
                setSelectedLecture({
                  name: lecture.title,
                  id: lecture.id
                });
              }}
              className="flex flex-col gap-8 w-full p-16 rounded-8 border-[#24222A] border-1 border-solid bg-[#1F1E2580]"
              key={index}
            >
              <div className="flex flex-col gap-4">
                <p className="text-[#9170F2] text-[14px]">
                  {LECTURE_TYPE[lecture.lectureType]}
                </p>
                <span>{lecture.title}</span>
                <span className="text-[#8E899F] text-[14px] line-clamp-2">
                  {lecture.body}
                </span>
              </div>
              <p className="text-[#EAE8ED] self-end text-[14px]">
                <span className="text-[18px] font-bold">
                  {lecture.price.toLocaleString('ko-KR')}원
                </span>{' '}
                / {lecture.rounds}회
              </p>
            </button>
          );
        })
      ) : (
        <EmptyContent>수업 정보가 없습니다</EmptyContent>
      )}
    </div>
  );
};

export default TrainerLecture;
