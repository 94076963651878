import { LocationPin } from '#assets/svg';
import { ProfileViewer } from 'src/components/templates/CoachDetailContent/ProfileViewer';
import { parseExperience } from 'src/refactor_shared/lib/parseExperience';

type TrainerProfileProps = {
  tags: Array<{ id: string; name: string }>;
  name: string;
  gymAddress: string;
  careerYear: Date;
  clinicalDataCount: number;
  regionSigu: string;
  regionDong: string;
  profileImage: string[];
  startAthletic: Date;
};

const TrainerProfile = ({ profile }: { profile: TrainerProfileProps }) => {
  return (
    <div>
      <ProfileViewer imgSrcList={profile.profileImage} />
      <div className="p-20 text-[14px] text-[#C9C7D1] flex flex-col gap-12">
        <div className="flex items-center gap-4 text-[#9170F2]">
          {profile.tags.map(tag => (
            <p
              key={tag.id}
              className="py-2 px-8 rounded-full text-[12px] bg-[#7750E933]"
            >
              {tag.name}
            </p>
          ))}
        </div>
        <p className="text-[24px] font-bold text-[#EAE8ED]">
          {profile.name} 트레이너
        </p>
        <div className="flex flex-col gap-5">
          {profile.regionSigu && profile.regionDong ? (
            <p className="flex items-center justify-start gap-2">
              <LocationPin />
              <span>
                {profile.regionSigu} {profile.regionDong}
              </span>
            </p>
          ) : null}
          <p>
            경력 {parseExperience(profile.startAthletic)}년 | 트레이닝 성과 {2}
            건
          </p>
        </div>
      </div>
    </div>
  );
};

export default TrainerProfile;
