const CommunityIcon = ({ isSelect }: { isSelect: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clipPath="url(#clip0_4423_175)">
        <path
          d="M7.46 14.64V7.20001H2.9C1.58 7.20001 0.5 8.28001 0.5 9.60001V16.8C0.5 18.12 1.58 19.2 2.9 19.2H4.1V22.8L7.7 19.2H13.7C15.02 19.2 16.1 18.12 16.1 16.8V14.616C16.0211 14.6329 15.9407 14.6414 15.86 14.6412H7.46V14.64ZM22.1 1.20001H11.3C9.98 1.20001 8.9 2.28001 8.9 3.60001V13.2H17.3L20.9 16.8V13.2H22.1C23.42 13.2 24.5 12.1212 24.5 10.8V3.60001C24.5 2.28001 23.42 1.20001 22.1 1.20001Z"
          fill={isSelect}
        />
      </g>
    </svg>
  );
};

export default CommunityIcon;
