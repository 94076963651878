import styled, { css } from 'styled-components';

interface TabItemProps {
  isActive?: boolean;
}

export const TabListSticky = styled.div`
  width: 100%;

  position: fixed;
  left: 0;
  top: 0;
  // background-color: #181818;
  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;a

  padding: 8px 20px;
`;

export const TabList = styled.div`
  display: flex;
  gap: 12px;
  color: #d7d3de;
  row-gap: 4px;
  width: 100%;
  height: 46px;
`;

export const TabItem = styled.button<TabItemProps>`
  position: relative;
  width: 96px;
  height: 46px;
  text-align: center;
  color: #d7d3de;
  background: none !important;
  font-weight: 500;
  font-size: 17px;
  font-style: normal;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.41px;

  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #7750e9;
      }
    `}
`;
