const ProfileIcon = ({
  isSelect,
  width,
  height,
  onClick,
  cursorPointer
}: {
  width?: string;
  height?: string;
  isSelect?: string;
  onClick?: () => void;
  cursorPointer?: boolean;
}) => {
  return (
    <>
      {cursorPointer ? (
        <svg
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
          width={width || '24'}
          height={height || '24'}
          viewBox="0 0 24 24"
          fill="none"
          cursor={'pointer'}
        >
          <path
            d="M7.5 6.5C7.5 8.981 9.519 11 12 11C14.481 11 16.5 8.981 16.5 6.5C16.5 4.019 14.481 2 12 2C9.519 2 7.5 4.019 7.5 6.5ZM20 21H21V20C21 16.141 17.859 13 14 13H10C6.14 13 3 16.141 3 20V21H20Z"
            fill={isSelect || '#756F7E'}
          />
        </svg>
      ) : (
        <svg
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
          width={width || '24'}
          height={height || '24'}
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M7.5 6.5C7.5 8.981 9.519 11 12 11C14.481 11 16.5 8.981 16.5 6.5C16.5 4.019 14.481 2 12 2C9.519 2 7.5 4.019 7.5 6.5ZM20 21H21V20C21 16.141 17.859 13 14 13H10C6.14 13 3 16.141 3 20V21H20Z"
            fill={isSelect || '#756F7E'}
          />
        </svg>
      )}
    </>
  );
};

export default ProfileIcon;
