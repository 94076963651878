import { Dispatch, SetStateAction } from 'react';

interface Props {
  setIsOpen: Dispatch<SetStateAction<string>>;
  onModalSubmit: () => void;
}

const Step2ConfirmButton = ({ setIsOpen, onModalSubmit }: Props) => {
  return (
    <div className="flex w-full gap-12">
      <div
        onClick={() => {
          setIsOpen('');
        }}
        className="text-center flex-1 h-[45px] px-40 py-12 bg-[#2e2a3a] rounded-lg justify-center items-centerinline-flex"
      >
        <div className="text-[#bcb8c3] text-base font-medium font-['Pretendard Variable'] leading-[21px]">
          취소
        </div>
      </div>
      <div
        onClick={onModalSubmit}
        className="text-center flex-1 h-[45px] px-40 py-12 bg-[#7750e9] rounded-lg justify-center items-center inline-flex"
      >
        <div className="text-[#ebe9f4] text-base font-medium font-['Pretendard Variable'] leading-[21px]">
          확인
        </div>
      </div>
    </div>
  );
};

export default Step2ConfirmButton;
