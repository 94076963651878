import { Dispatch, SetStateAction } from 'react';
import XButton from 'src/assets/svg/Vector.svg';

const WriteCustomHeader = ({
  setFunnelStep
}: {
  setFunnelStep: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <div className="my-16 h-30 flex items-center justify-end relative">
      <p className="self-center font-bold text-[20px] mx-auto absolute top-0 left-1/2 -translate-x-1/2">
        추가 정보 입력
      </p>
      <button onClick={() => setFunnelStep('main')}>
        <img src={XButton} alt="close-button" />
      </button>
    </div>
  );
};

export default WriteCustomHeader;
