import styled from 'styled-components';
import diet from '#assets/img/diet.png';
import notification from '#assets/img/Notice icon.png';
import pain from '#assets/img/pain.png';
import QR from '#assets/img/QR icon.png';
import rehabilitation from '#assets/img/rehabilitation.png';
import running from '#assets/img/running.png';
import strength from '#assets/img/strength.png';
import trophy from '#assets/img/trophy.png';
import weightlifting from '#assets/img/weightlifting.png';
import yoga from '#assets/img/yoga.png';
import alert from '#assets/svg/alert.svg';
import alt_image from '#assets/svg/alt_image.svg';
import picture from '#assets/svg/alt_image.svg';
import bath_off_purple from '#assets/svg/bath_off_purple.svg';
import bath_on_purple from '#assets/svg/bath_on_purple.svg';
import board from '#assets/svg/board.svg';
import calendar from '#assets/svg/calendar.svg';
import camera_white from '#assets/svg/camera_white.svg';
import car_off_purple from '#assets/svg/car_off_purple.svg';
import car_on_purple from '#assets/svg/car_on_purple.svg';
import chat from '#assets/svg/chat.svg';
import check_white from '#assets/svg/check.svg';
import check_circle_point from '#assets/svg/check_circle.svg';
import check_circle_white from '#assets/svg/check_circle_white.svg';
import purple_check from '#assets/svg/check_purple.svg';
import check_point_circle from '#assets/svg/check_purple_circle.svg';
import coin from '#assets/svg/coin.svg';
import confirm from '#assets/svg/confirmation_form.svg';
import dots_vertical_rounded from '#assets/svg/dots_vertical_rounded.svg';
import down_arrow from '#assets/svg/down_arrow.svg';
import down_arrow_gray from '#assets/svg/down_arrow_gray.svg';
import dumbbell from '#assets/svg/dumbbell.svg';
import eye from '#assets/svg/eye.svg';
import filter from '#assets/svg/filter.svg';
import group from '#assets/svg/group.svg';
import hanger_off_purple from '#assets/svg/hanger_off_purple.svg';
import hanger_on_purple from '#assets/svg/hanger_on_purple.svg';
import speaker from '#assets/svg/help.svg';
import id_card from '#assets/svg/id_card.svg';
import inbox_off_purple from '#assets/svg/inbox_off_purple.svg';
import inbox_on_purple from '#assets/svg/inbox_on_purple.svg';
import kakao from '#assets/svg/kakao.svg';
import link_white from '#assets/svg/link_white.svg';
import location_pin_point from '#assets/svg/location_pin.svg';
import person from '#assets/svg/myinfo.svg';
import next_arrow from '#assets/svg/next_arrow.svg';
import next_arrow_white from '#assets/svg/next_arrow_white.svg';
import phone from '#assets/svg/phone.svg';
import plus_circle from '#assets/svg/plus_circle.svg';
import plus_gray from '#assets/svg/plus_gray.svg';
import plus_white from '#assets/svg/plus_white.svg';
import policy from '#assets/svg/policy.svg';
import prev_arrow from '#assets/svg/prev_arrow.svg';
import purple_arrow from '#assets/svg/purple_arrow.svg';
import schedule from '#assets/svg/schedule.svg';
import setting from '#assets/svg/settings.svg';
import shirt_off_purple from '#assets/svg/shirt_off_purple.svg';
import shirt_on_purple from '#assets/svg/shirt_on_purple.svg';
import star_gray from '#assets/svg/star_gray.svg';
import star_orange from '#assets/svg/star_orange.svg';
import star_purple from '#assets/svg/star_purple.svg';
import thin_arrow from '#assets/svg/thin_arrow.svg';
import up_arrow from '#assets/svg/up_arrow_tail.svg';
import wallpaper_off_purple from '#assets/svg/wallpaper_off_purple.svg';
import wallpaper_on_purple from '#assets/svg/wallpaper_on_purple.svg';
import location_pin_white from '#assets/svg/white_location_pin.svg';
import x_circle from '#assets/svg/x_circle.svg';
import x_circle_white from '#assets/svg/x_circle_white.svg';
import x_gray from '#assets/svg/x_gray.svg';
import x_white from '#assets/svg/x_header_white.svg';
import comment_sharp from '#assets/svg/CommentSharp.svg';
import thumb_up_alt from '#assets/svg/ThumbUpAltSharp.svg';

export type BoxColorType = 'background' | 'transparent';
export type IconType =
  | 'QR'
  | 'notification'
  | 'board'
  | 'calendar'
  | 'group'
  | 'rehabilitation'
  | 'running'
  | 'weightlifting'
  | 'yoga'
  | 'strength'
  | 'pain'
  | 'trophy'
  | 'diet'
  | 'plus-circle'
  | 'plus-gray'
  | 'prev-arrow'
  | 'next-arrow'
  | 'setting'
  | 'person'
  | 'confirm'
  | 'schedule'
  | 'chat'
  | 'policy'
  | 'speaker'
  | 'id-card'
  | 'coin'
  | 'eye'
  | 'x-gray'
  | 'location-pin-white'
  | 'phone'
  | 'thin-arrow'
  | 'purple-check'
  | 'plus-white'
  | 'camera-white'
  | 'check-white'
  | 'x-white'
  | 'filter'
  | 'x-circle'
  | 'up-arrow'
  | 'picture'
  | 'star_purple'
  | 'star_gray'
  | 'star_orange'
  | 'purple-arrow'
  | 'dumbbell'
  | 'down-arrow'
  | 'check-circle-white'
  | 'check-circle-point'
  | 'x-circle-white'
  | 'location-pin-point'
  | 'kakao'
  | 'alert'
  | 'next-arrow-white'
  | 'hanger-on-purple'
  | 'hanger-off-purple'
  | 'car-on-purple'
  | 'car-off-purple'
  | 'inbox-on-purple'
  | 'inbox-off-purple'
  | 'wallpaper-on-purple'
  | 'wallpaper-off-purple'
  | 'shirt-on-purple'
  | 'shirt-off-purple'
  | 'bath-on-purple'
  | 'bath-off-purple'
  | 'down-arrow-gray'
  | 'check-point-circle'
  | 'dots_vertical_rounded'
  | 'link-white'
  | 'comment-sharp'
  | 'thumb-up-alt';

export const Container = styled.div<{ boxColor: BoxColorType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background-color: ${({ boxColor }) =>
    boxColor === 'background' ? 'var(--color-gray)' : 'transparent'};
  border: none;

  &.focus {
    border: solid 1px var(--color-point);
  }
`;

export const Icon = styled.span<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background-position: center;
  background-size: contain;
  background-image: url(${alt_image});
  background-repeat: no-repeat;

  &.rehabilitation {
    background-image: url(${rehabilitation});
  }

  &.running {
    background-image: url(${running});
  }

  &.weightlifting {
    background-image: url(${weightlifting});
  }

  &.yoga {
    background-image: url(${yoga});
  }

  &.strength {
    background-image: url(${strength});
  }

  &.pain {
    background-image: url(${pain});
  }

  &.trophy {
    background-image: url(${trophy});
  }

  &.diet {
    background-image: url(${diet});
  }

  &.plus-circle {
    background-image: url(${plus_circle});
  }

  &.plus-gray {
    background-image: url(${plus_gray});
  }

  &.prev-arrow {
    background-image: url(${prev_arrow});
  }

  &.next-arrow {
    background-image: url(${next_arrow});
  }

  &.setting {
    background-image: url(${setting});
  }

  &.QR {
    background-image: url(${QR});
  }

  &.notification {
    background-image: url(${notification});
  }

  &.board {
    background-image: url(${board});
  }

  &.calendar {
    background-image: url(${calendar});
  }

  &.group {
    background-image: url(${group});
  }

  &.chat {
    background-image: url(${chat});
  }

  &.policy {
    background-image: url(${policy});
  }

  &.speaker {
    background-image: url(${speaker});
  }

  &.confirm {
    background-image: url(${confirm});
  }

  &.schedule {
    background-image: url(${schedule});
  }

  &.person {
    background-image: url(${person});
  }

  &.id-card {
    background-image: url(${id_card});
  }

  &.coin {
    background-image: url(${coin});
  }

  &.eye {
    background-image: url(${eye});
  }

  &.x-gray {
    background-image: url(${x_gray});
  }

  &.location-pin-white {
    background-image: url(${location_pin_white});
  }

  &.phone {
    background-image: url(${phone});
  }

  &.thin-arrow {
    background-image: url(${thin_arrow});
  }

  &.purple-check {
    background-image: url(${purple_check});
  }

  &.plus-white {
    background-image: url(${plus_white});
  }

  &.camera-white {
    background-image: url(${camera_white});
  }

  &.check-white {
    background-image: url(${check_white});
  }

  &.x-white {
    background-image: url(${x_white});
  }

  &.filter {
    background-image: url(${filter});
  }

  &.x-circle {
    background-image: url(${x_circle});
  }

  &.up-arrow {
    background-image: url(${up_arrow});
  }

  &.picture {
    background-image: url(${picture});
  }

  &.star_purple {
    background-image: url(${star_purple});
  }

  &.star_gray {
    background-image: url(${star_gray});
  }

  &.star_orange {
    background-image: url(${star_orange});
  }

  &.purple-arrow {
    background-image: url(${purple_arrow});
  }

  &.dumbbell {
    background-image: url(${dumbbell});
  }

  &.down-arrow {
    background-image: url(${down_arrow});
  }

  &.check-circle-white {
    background-image: url(${check_circle_white});
  }

  &.check-circle-point {
    background-image: url(${check_circle_point});
  }

  &.x-circle-white {
    background-image: url(${x_circle_white});
  }

  &.location-pin-point {
    background-image: url(${location_pin_point});
  }

  &.kakao {
    background-image: url(${kakao});
  }

  &.alert {
    background-image: url(${alert});
  }

  &.next-arrow-white {
    background-image: url(${next_arrow_white});
  }

  &.bath-on-purple {
    background-image: url(${bath_on_purple});
  }

  &.bath-off-purple {
    background-image: url(${bath_off_purple});
  }

  &.car-on-purple {
    background-image: url(${car_on_purple});
  }

  &.car-off-purple {
    background-image: url(${car_off_purple});
  }

  &.inbox-on-purple {
    background-image: url(${inbox_on_purple});
  }

  &.inbox-off-purple {
    background-image: url(${inbox_off_purple});
  }

  &.hanger-on-purple {
    background-image: url(${hanger_on_purple});
  }

  &.hanger-off-purple {
    background-image: url(${hanger_off_purple});
  }

  &.shirt-on-purple {
    background-image: url(${shirt_on_purple});
  }

  &.shirt-off-purple {
    background-image: url(${shirt_off_purple});
  }

  &.wallpaper-on-purple {
    background-image: url(${wallpaper_on_purple});
  }

  &.wallpaper-off-purple {
    background-image: url(${wallpaper_off_purple});
  }

  &.down-arrow-gray {
    background-image: url(${down_arrow_gray});
  }

  &.check-point-circle {
    background-image: url(${check_point_circle});
  }

  &.link-white {
    background-image: url(${link_white});
  }
  &.dots_vertical_rounded {
    background-image: url(${dots_vertical_rounded});
  }
  &.comment-sharp {
    background-image: url(${comment_sharp});
  }
  &.thumb-up-alt {
    background-image: url(${thumb_up_alt});
  }
`;
