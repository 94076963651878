import { useLocation, useNavigate } from 'react-router';

import { NAVIGATION_LIST } from './constant';

import * as S from './style';
import { ReactNode } from 'react';

const Navigation = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const exceptionNavigation =
    !pathname.split('/')[1] ||
    EXCEPTION_PATH.find(value => value === pathname.split('/')[1]);

  return (
    <>
      {children}
      {pathname.split('/').length < (exceptionNavigation ? 4 : 3) ? (
        <div className="w-full fixed bottom-0 left-0 z-20">
          <div className="max-w-939 mx-auto bg-[#181818] h-50 py-8 flex items-center justify-center rounded-t-16 border-t-1 border-solid border-[#2E2B36] shadow-custom backdrop-blur-custom">
            <S.Container>
              {NAVIGATION_LIST.map(item => {
                return (
                  <S.Box onClick={() => navigate(item.route)} key={item.name}>
                    <item.icon
                      isSelect={
                        pathname.includes(
                          item.incldues ? item.incldues : item.route
                        )
                          ? '#EAE8ED'
                          : '#8E899F'
                      }
                    />
                    <span
                      className={`${
                        pathname.includes(
                          item.incldues ? item.incldues : item.route
                        )
                          ? 'text-[#EAE8ED]'
                          : 'text-[#8E899F]'
                      }`}
                    >
                      {item.name}
                    </span>
                  </S.Box>
                );
              })}
            </S.Container>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Navigation;

const EXCEPTION_PATH = ['community'];
