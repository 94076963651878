import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 12rem;
  background-color: var(--color-background);
  box-shadow: 0 -0.25rem 0.75rem var(--color-black-alpha30);
  border-radius: 1.25rem 1.25rem 0 0;
  z-index: 999;
  max-width: var(--max-width);
  margin: 0 auto;
  transition: all 0.3s linear;
  gap: 2rem;

  &.expand {
    height: fit-content;
    overflow-y: auto;
  }

  & > div.textInfoArea {
    padding: 3rem 2rem;
  }
`;

export const HandleBar = styled.div`
  position: absolute;
  top: 1.25rem;
  left: 50%;
  transform: translateX(-50%);
  width: 12%;
  border: 1px solid white;
`;

export const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
  color: var(--color-white);
  padding-bottom: 2rem;
  transition: all 0.3s linear;
  height: 100%;

  & > img {
    display: flex;
    width: 7.5rem;
    height: 7.5rem;
    border: 1px solid var(--color-white);
    border-radius: 0.75rem;
  }

  & > div.titleTextArea {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;

    div.introductionArea {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      color: var(--color-gray-text-second);

      p.fold {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }

      span {
        display: flex;
        &.expand {
          span {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
`;

export const ExtraInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  color: var(--color-white);
  padding-bottom: 2rem;

  & > div {
    display: flex;
    align-items: center;
    gap: 1.25rem;
  }
`;

export const ImageList = styled.ul`
  display: flex;
  gap: 1rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  padding-top: 2rem;
  border-top: 1px solid var(--color-border);
`;

export const ImageItem = styled.li`
  width: 10rem;
  height: 10rem;

  img {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    border-radius: 0.75rem;
  }
`;

export const AltImageBox = styled.div`
  flex-shrink: 0;
  width: 7.5rem;
  height: 7.5rem;
  display: flex;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-second);
`;
