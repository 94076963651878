const SearchBarCloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      cursor={'pointer'}
    >
      <path
        d="M13 1L7 7M1 13L7 7M7 7L13 13L1 1"
        stroke="#A9A5B6"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchBarCloseIcon;
