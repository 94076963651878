import { useFormContext, useWatch } from 'react-hook-form';
import { Images } from '#types/images';
import { useToast } from '#providers/ToastProvider';
import { useUploadFiles } from 'src/shared/components/file-upload/useFileUpload';

export const FileUpload = ({
  registerName,
  maxCount
}: {
  registerName: string;
  maxCount: number;
}) => {
  const { showToast } = useToast();
  const { getValues, setValue, control } = useFormContext();
  const { uploadFiles: uploadFilesToS3 } = useUploadFiles();

  const images = useWatch({
    control,
    name: registerName
  });

  const uploadFiles = async (filesArray: File[]) => {
    if (filesArray.length > 0) {
      uploadFilesToS3({
        selectedFiles: filesArray,
        path: 'exercise-image',
        onError: () => {
          showToast({
            type: 'fail',
            message: '이미지 업로드에 실패하였습니다.'
          });
        },
        onSuccess: ({ id, file }: { id: string; file: File }) => {
          const filteredFiles = {
            id: id,
            uploadedLink: URL.createObjectURL(file)
          };

          setValue(registerName, [...getValues(registerName), filteredFiles]);
        }
      });
    }
  };

  const handleOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (!selectedFiles) return;
    const filesArray = Array.from(selectedFiles);

    if (images?.length + selectedFiles.length > maxCount) {
      showToast({
        type: 'fail',
        message: `${maxCount}개 이상의 이미지를 업로드할 수 없습니다.`
      });

      return;
    }

    if (filesArray.some(file => file.size > 30 * 1024 * 1024)) {
      showToast({
        type: 'fail',
        message: `30MB 이상의 이미지를 업로드할 수 없습니다.`
      });

      return;
    }

    await uploadFiles(filesArray);

    e.target.value = '';
  };

  return (
    <div className="flex w-full gap-12">
      <label
        htmlFor="fileInput"
        className="mt-8 w-120 h-120 border border-solid border-[#373245] rounded-12 flex flex-col text-[14px] text-[#595463] justify-center items-center"
      >
        {/* <ImageUpload /> */}
        {images?.length ? images.length : 0} / {maxCount}
      </label>
      <input
        id="fileInput"
        type="file"
        className="hidden"
        multiple={true}
        accept="image/*"
        onChange={handleOnChange}
      />
      <div className="w-[calc(100%-132px)] overflow-x-scroll pt-8">
        <div className="flex w-full gap-12">
          {images &&
            images.map((image: Images, index: number) => (
              <div
                key={images.id}
                className="min-w-[120px] w-120 h-120 border border-soild border-[#373245] relative"
              >
                <img
                  src={image.resizedLink ?? image.uploadedLink}
                  alt="image"
                  className="object-cover w-full h-full rounded-12"
                />
                <button
                  type="button"
                  className="absolute -top-3 -right-3 bg-[#413d50] rounded-full p-4 drop-shadow-md"
                  onClick={() =>
                    setValue(
                      registerName,
                      images.filter((_: object, i: number) => i !== index)
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M13.1202 4.46004L11.7002 3.04004L8.1602 6.59004L4.6202 3.04004L3.2002 4.46004L6.7502 8.00004L3.2002 11.54L4.6202 12.96L8.1602 9.41004L11.7002 12.96L13.1202 11.54L9.5702 8.00004L13.1202 4.46004Z"
                      fill="#D7D3DE"
                    />
                  </svg>
                </button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
