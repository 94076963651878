const TrainerSchedule = ({ data }: { data: Array<object> }) => {
  const days = ['일', '월', '화', '수', '목', '금', '토'];

  return Object.keys(data)?.length ? (
    <div className="flex flex-col gap-20">
      <p className="text-[18px] font-bold">수업 일정</p>
      {Object.entries(data).map(([day, value]) =>
        value && day !== '7' ? (
          <div className="flex gap-24" key={day}>
            <p className="text-[16px]  text-[#8e899f]">{days[Number(day)]}</p>
            <div className="flex flex-col">
              {(value as Array<{ startAt: string; endAt: string }>).map(
                ({ startAt, endAt }, index) => {
                  return (
                    <p className="text-[16px]" key={index}>
                      {startAt} ~ {endAt}
                    </p>
                  );
                }
              )}
            </div>
          </div>
        ) : null
      )}
    </div>
  ) : null;
};

export default TrainerSchedule;
