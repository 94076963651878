import { postRefreshAuthToken } from '#api/auth/token';
import logo from '#assets/svg/logo.svg';
import { Text } from '#atoms';
import { PATH } from '#const/path';
import { useToast } from '#providers/ToastProvider';
import { useUserInfo } from '#providers/UserInfo';
import sessionStorageManager from '#utils/sessionStorageManager';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Container, LogoArea } from './styles';
import { usePostQnaQuestionMutation } from '#api/community';
import TokenController from '#utils/tokenController';

export function KakaoLoginSuccessPage() {
  const { pathname } = useLocation();

  const { showToast } = useToast();
  const { createSession } = useUserInfo();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { blockedPagePath, setBlockedPagePath } = sessionStorageManager();
  const refreshToken = searchParams.get('refreshToken') ?? '';
  const { mutate: postQnaMutation } = usePostQnaQuestionMutation();

  TokenController.setRefreshToken(refreshToken);
  useEffect(() => {
    postRefreshAuthToken()
      .then(({ accessToken, refreshToken }) => {
        createSession(
          accessToken,
          refreshToken,
          pathname === '/newSocialAccount' ? true : false
        );
      })
      .catch(() => {
        showToast({
          type: 'fail',
          message: '카카오 로그인에 실패하였습니다.'
        });
        navigate(PATH.SIGN);
      })
      .finally(() => {
        if (blockedPagePath === '/community/qna') {
          postQnaMutation(
            JSON.parse(sessionStorage.getItem('prevState') || '{}')
          );
          return setBlockedPagePath('');
        }
        navigate(blockedPagePath || PATH.HOME);
        setBlockedPagePath('');
      });
  }, []);

  return (
    <Container>
      <LogoArea>
        <img src={logo} />
        <Text textStyle="t3">숫자만 세주는 트레이너 말고 진짜 전문가.</Text>
        {/* <Text textStyle="t3">PT는 피벗.</Text> */}
      </LogoArea>
    </Container>
  );
}
