const CommunityContents = ({
  content,
  isInformation
}: {
  content: string;
  isInformation?: boolean;
}) => {
  return (
    <>
      <div className="border-t-1 border-solid border-[#1F1E25] pt-16 px-20 pb-32">
        {isInformation ? (
          <div
            dangerouslySetInnerHTML={{
              __html: content
            }}
          />
        ) : (
          <div>{content}</div>
        )}
      </div>
    </>
  );
};

export default CommunityContents;
