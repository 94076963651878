import { Link } from 'react-router-dom';

import Header from './header';
import Section from './section';
import SelectTag from 'src/refactor_components/common/SelectTag';
import TodaySchedule from './today-schedule';
import { ImageOrVideoRenderer } from 'src/components/organisims/ClinicalDataItem';

import {
  useGetPopularExerciseQuestionsQuery,
  useGetQnaCommunityQuery,
  useGetRecentQnaQuery,
  useGetWhiteListCoach
} from '#api/community';
import { useGetRecentReviews } from '#api/reviews';
import { useGetRecentClinicalData } from '#api/clinical-data';

import Next_Arrow_Icon from './icon/next_arrow_icon';
import ChatIcon from '#assets/svg/comment.svg';
import LikeIcon from '#assets/svg/like.svg';
import colorStar from '#assets/svg/colorstar.svg';
import { useState } from 'react';
import SearchIcon from '../community/Icon/SearchIcon';
import recursiveParser from 'src/refactor_shared/lib/recursive-parser';

const MainPage = () => {
  const [selectvalue, setSelectValue] = useState('EXERCISE');

  const { data: getRecentReviewsData } = useGetRecentReviews();
  const { data: recentClinicalDataList } = useGetRecentClinicalData();
  const { data: getRecentQnaData } = useGetRecentQnaQuery();
  const { data: popularExerciseQuestionsData } =
    useGetPopularExerciseQuestionsQuery();
  const { data: whiteList } = useGetWhiteListCoach(selectvalue);

  return (
    <>
      <div className="w-full h-[100vh] px-20 pt-73">
        <Header />
        {/* <div className="flex items-center gap-8 px-20 py-16 rounded-8 border border-solid border-[#4D495A] mt-20 mb-12">
          <SearchIcon />
          <input
            className="w-full h-full bg-[#121114]"
            placeholder="트레이너 또는 운동고민을 검색해보세요"
          />
        </div> */}
        <div className="flex flex-col gap-40 pb-100">
          <TodaySchedule />
          <Section sectionTitle="운동 전문가">
            <SelectTag
              selectvalue={selectvalue}
              setSelectValue={setSelectValue}
              selectList={[
                {
                  label: '체육학 전공',
                  value: 'EXERCISE'
                },
                {
                  label: '석사 이상',
                  value: 'MASTER'
                },
                {
                  label: '경력 10년 이상',
                  value: 'CAREER'
                },
                {
                  label: '물리 치료사',
                  value: 'PHYSICAL'
                }
              ]}
            />
            <div className="flex w-full gap-8 overflow-auto">
              {whiteList &&
                whiteList.map((trainer, index) => (
                  <Link
                    key={`${trainer.name}${index}`}
                    to={`https://fibud.kr/expert/${trainer.myUrl}`}
                    className="flex flex-col gap-8"
                  >
                    <div className="flex flex-col min-w-[166px]">
                      <img
                        className="object-cover rounded-t-16 h-124"
                        src={trainer.profileImage}
                      />
                      <div className="flex flex-col gap-8 p-12 rounded-b-16 bg-[#1F1E25]">
                        <span className="font-semibold leading-21 text-[#EAE8ED]">
                          {trainer.name}
                        </span>
                        <div className="text-[12px] text-[#C9C7D1] px-6 py-4 bg-[#2E2B36] w-fit rounded-4 line-clamp-1 text-ellipsis overflow-hidden whitespace-nowrap">
                          {trainer.shortIntroduction}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </Section>
          <Section
            sectionTitle="최신 운동 질문"
            rightContent={
              <Link
                to="/community/qna"
                className="flex items-center h-16 gap-4 text-[#8E899F]"
              >
                <span className="text-[12px]">전체보기</span>
                <Next_Arrow_Icon />
              </Link>
            }
          >
            <div className="flex flex-col gap-12">
              {getRecentQnaData?.slice(0, 3).map(item => (
                <Link
                  key={item.questionId}
                  to={`/community/qna/${item.questionId}`}
                  className="p-20 bg-[#24222A] flex flex-col rounded-12 min-w-335 w-full"
                >
                  <p className="flex items-start line-clamp-2 leading-16">
                    <span className="text-[#5C576B] text-[13px] font-semibold mr-6 pt-2">
                      Q.
                    </span>
                    <span>{item.title}</span>
                  </p>
                  <p className="flex items-start line-clamp-2">
                    <span className="text-[#9170F2] text-[13px] font-semibold mr-6 pt-2">
                      A.
                    </span>
                    <div
                      className="line-clamp-2"
                      dangerouslySetInnerHTML={{ __html: item.answer }}
                    />
                  </p>
                </Link>
              ))}
            </div>
          </Section>
          {/* <Section
            sectionTitle="전문가 트레이닝 성과"
            // rightContent={
            //   <Link
            //     to="/trainer-community"
            //     className="flex items-center h-16 gap-4 text-[#8E899F]"
            //   >
            //     <span className="text-[12px]">전체보기</span>
            //     <Next_Arrow_Icon />
            //   </Link>
            // }
          >
            <div className="flex w-full gap-16 overflow-auto">
              {recentClinicalDataList?.slice(0, 5).map(data => (
                <Link
                  key={data.id}
                  to={`/expert/${data.lecture.coach.myUrl}`}
                  className="flex flex-col min-w-[180px] gap-12"
                >
                  <div className="flex w-full rounded-6">
                    <div className="relative w-1/2 ">
                      <ImageOrVideoRenderer
                        isLeft={true}
                        url={data.befores[0]?.resizedLink}
                      />
                      <p className="absolute top-0 px-6 py-4 w-21 h-21 bg-[#1F1E25] text-[10px] text-[#8E899F] rounded-tl-[6px] rounded-br-[6px]">
                        전
                      </p>
                    </div>
                    <div className="relative w-1/2 ">
                      <ImageOrVideoRenderer url={data.afters[0]?.resizedLink} />
                      <p className="absolute top-0  px-6 py-4 w-21 h-21 bg-[#1F1E25] text-[#9170F2] text-[10px] rounded-br-[6px]">
                        후
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col gap-8 text-[14px] text-[#EAE8ED] text-ellipsis font-semibold">
                    <p className="line-clamp-2">{data.body}</p>
                    <div className="flex flex-col gap-4">
                      <span className="text-[12px] text-[#C9C7D1]">
                        {data?.title}
                      </span>
                      <span className="text-[12px] text-[#8E899F] leading-16">
                        {data?.lecture?.coach?.name}
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </Section> */}
          <Section
            sectionTitle="인기 운동 가이드"
            rightContent={
              <Link
                to="/community/information"
                className="flex items-center h-16 gap-4 text-[#8E899F]"
              >
                <span className="text-[12px]">전체보기</span>
                <Next_Arrow_Icon />
              </Link>
            }
          >
            <div className="flex w-full gap-12 overflow-x-auto">
              {popularExerciseQuestionsData?.map(data => (
                <Link
                  key={data.id}
                  to={`/community/information/${data.id}`}
                  className="px-16 pt-16 pb-12 bg-[#1F1E25] flex flex-col gap-20 min-w-[320px] rounded-8"
                >
                  <div className="flex justify-between gap-12">
                    <div className="flex flex-col gap-6">
                      <p className="text-[14px] font-semibold text-[#C9C7D1] line-clamp-1">
                        {data.title}
                      </p>
                      <p
                        className="text-ellipsis overflow-hidden line-clamp-2 pt-6 text-[12px] text-[#8E899F]"
                        dangerouslySetInnerHTML={{
                          __html: recursiveParser(data.content)
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end gap-12 text-[#EAE8ED]">
                    <div className="flex items-center gap-6">
                      <img src={ChatIcon} alt="" width={14} height={14} />
                      <span className="text-[12px]">{data.sequence}</span>
                    </div>
                    <div className="flex items-center gap-6">
                      <img src={LikeIcon} alt="" width={14} height={14} />
                      <span className="text-[12px]">{data.likeCount}</span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </Section>
          <Section sectionTitle="전문가 리얼 리뷰">
            <div className="flex gap-20 overflow-auto ">
              {getRecentReviewsData?.slice(0.5).map(data => (
                <Link
                  key={data.myUrl}
                  to={`/expert/${data.myUrl}`}
                  className="bg-[#1F1E25] rounded-8 flex flex-col p-16 gap-16 min-w-[291px] justify-between"
                >
                  <div className="flex flex-col">
                    <span className="font-semibold">
                      <span className="text-[#9170F2]">
                        {data.userNickName[0]}**
                      </span>{' '}
                      님
                    </span>
                    <div className="flex items-center justify-between">
                      <span className="text-[#8E899F] text-[10px] leading-13">
                        {data.lectureName} {data.totalRegisteredLectureCount}회
                      </span>
                      <span className="flex items-center gap-2">
                        <img src={colorStar} alt="" /> {data.lectureQuality}.0
                      </span>
                    </div>

                    <p className="text-[13px] overflow-hidden overflow-ellipsis text-[#C9C7D1] line-clamp-3">
                      {data.description}
                    </p>
                  </div>
                  <div className="overflow-scroll w-full flex items-center gap-4">
                    {data.images.length > 0 &&
                      data.images.map(image => {
                        return (
                          <div className="w-64 h-64 rounded-8 flex-shrink-0">
                            <img src={image.resizedLink} />
                          </div>
                        );
                      })}
                  </div>
                  <div className="border-t border-solid border-[#2E2B36] pt-16 text-[14px] font-medium leading-20 text-[#EAE8ED]">
                    {data.coachName} 트레이너
                  </div>
                </Link>
              ))}
            </div>
          </Section>
        </div>
      </div>
    </>
  );
};

export default MainPage;
