import { Dispatch, SetStateAction, useState } from 'react';

import { Modal } from '#molecules';

import HealthInfoSelectModal from 'src/r_page/Information-community/Write/modal';
import WriteBodyInfo from 'src/r_page/Information-community/Write/components/body-info';
import WriteCustomHeader from 'src/r_page/Information-community/Write/components/custom-header';
import WriteSwitch from 'src/r_page/Information-community/Write/components/switch';
import WriteHealthInfo from 'src/r_page/Information-community/Write/components/health-info';

import { Logo } from '#assets/svg';
import BoldCancelIcon from 'src/assets/Icon/BoldCancelIcon.svg';
import KakaoSvg from 'src/assets/svg/kakao.svg';

const CommunityWriteStep2 = ({
  setCurrentFunnelStep
}: {
  setCurrentFunnelStep: Dispatch<SetStateAction<string>>;
}) => {
  const [testModal, setTestModal] = useState(false);
  const [isBodyInfoVisible, setIsBodyInfoVisible] = useState(false);
  const [isHealthInfoVisible, setIsHealthInfoVisible] = useState(false);
  const [additionalHealthInfoModalOpen, setAdditionalHealthInfoModalOpen] =
    useState('');

  return (
    <div className="p-20 pt-0 h-full flex flex-col">
      <WriteCustomHeader setFunnelStep={setCurrentFunnelStep} />
      <p className="text-[18px] pb-40 mt-12">
        보다 정확한 답변을 원하시면
        <br /> 추가 정보를 입력해주세요
      </p>
      <div className="flex flex-col justify-between gap-40">
        <div className="flex flex-col border-[#1F1E25] border-solid border-y-1 py-20 gap-20">
          <WriteSwitch
            title="신체 정보"
            onToggle={setIsBodyInfoVisible}
            initState={isBodyInfoVisible}
          />
          <WriteBodyInfo />
        </div>
        <div className="flex flex-col">
          <WriteSwitch
            title="건강 정보 공개"
            onToggle={setIsHealthInfoVisible}
            initState={isHealthInfoVisible}
          />
          <WriteHealthInfo
            setAdditionalHealthInfoModalOpen={setAdditionalHealthInfoModalOpen}
          />
        </div>
      </div>
      <button
        className="mt-auto py-16 px-20 rounded-8 text-[#EAE8ED] bg-[#5c5966] w-full text-center text-17"
        onClick={() => {
          setTestModal(true);
          setCurrentFunnelStep('main');
        }}
      >
        추가 정보 기입 완료
      </button>
      {additionalHealthInfoModalOpen && (
        <HealthInfoSelectModal
          setAdditionalHealthInfoModalOpen={setAdditionalHealthInfoModalOpen}
          healthInfoType={additionalHealthInfoModalOpen}
        />
      )}
      {testModal && (
        <>
          <Modal onClose={() => setTestModal(false)}>
            <div className="flex flex-col justify-between gap-48">
              <div className="flex flex-col w-full gap-40">
                <div className="flex items-center justify-between w-full">
                  <Logo width={50.5} height={16} />
                  <button onClick={() => setTestModal(false)}>
                    <img src={BoldCancelIcon} alt="" />
                  </button>
                </div>
                <p className="text-center">
                  질문 등록과 답변 알림을 위해
                  <br /> 로그인을 진행해 주세요
                </p>
              </div>
              <div className="flex flex-col gap-13">
                <button className="bg-[#FAE100] flex items-center gap-4 px-[44.5px] py-16 rounded-12 text-[#371C1D] text-[17px] font-medium">
                  <img src={KakaoSvg} alt="" /> 카카오로 3초만에 등록하기
                </button>
                <p className="text-[10px] text-[#8E899F] text-center">
                  정확한 답변을 위해 나이와 성별 정보를 자동으로 불러와요
                </p>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default CommunityWriteStep2;
