// import { Spacing, Text } from '#atoms';
import { LocationItem, PriceModal } from '#molecules';
import { CoachGridSelector } from '#organisims';
import { $modalState } from '#states/atoms/modalState';
// import { $selectedCoachTagState } from '#states/atoms/tagState';
import MetaHelmet from '#utils/MetaHelmet';
import { useRecoilState } from 'recoil';
import { CategorySelectorContainer, Container, Category } from './styles';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
const categories = ['전체', '원포인트 레슨'];
export const CoachPage = () => {
  const [showModal, setShowModal] = useRecoilState($modalState);
  // const selectedCoachTag = useRecoilValue($selectedCoachTagState);
  const [searchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState(
    searchParams.get('tab') || '전체'
  );
  const navigate = useNavigate();
  const [loc, setLoc] = useState<string>('');
  return (
    <>
      <MetaHelmet title="피벗 내 주변 트레이너 찾기" />
      <Container>
        <LocationItem setLoc={setLoc} />
        {/* <Spacing size={20} /> */}
        <CategorySelectorContainer>
          {/* <CategorySelector /> */}
          {categories.map(category => (
            <Category
              key={category}
              isSelected={selectedCategory === category}
              onClick={() => {
                navigate(`/expert?tab=${category}`);
                setSelectedCategory(category);
              }}
            >
              {category}
            </Category>
          ))}
        </CategorySelectorContainer>
        {selectedCategory && (
          <CoachGridSelector searchData="" tab={selectedCategory} loc={loc} />
        )}
        {showModal && <PriceModal setShowModal={setShowModal} />}
      </Container>
    </>
  );
};
