import { Link } from 'react-router-dom';
import { Logo } from '#assets/svg';
import ChatIcon from '#assets/Icon/ChatIcon';
import { isUser } from '#utils/isUser';

const Header = () => {
  return (
    <div className="fixed top-0 left-0 z-30 flex items-center justify-center w-full">
      <div className="bg-[#121114] flex items-center justify-between max-w-[var(--max-width)] w-full py-16 px-20">
        <Logo style={{ width: '62', height: '21px' }} />
        {isUser() && (
          <div className="flex items-center gap-16">
            <Link to="/chat">
              <ChatIcon />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
