export const parseExperience = (startDate: Date) => {
  if (!startDate) return 0;
  // 입력 날짜를 파싱
  const inputDate = new Date(startDate);
  if (!inputDate) {
    throw new Error('유효하지 않은 날짜 형식입니다.');
  }

  // 오늘 날짜
  const today = new Date();

  // 년, 월, 일을 비교하여 몇 년이 지났는지 계산
  let yearsDifference = today.getFullYear() - inputDate.getFullYear();

  // 월/일이 현재 날짜보다 늦으면 1년 덜 계산
  if (
    today.getMonth() < inputDate.getMonth() ||
    (today.getMonth() === inputDate.getMonth() &&
      today.getDate() < inputDate.getDate())
  ) {
    yearsDifference--;
  }

  return yearsDifference;
};
