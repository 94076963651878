const recursiveParser = (data?: string, maxSlicer?: number) => {
  if (!data) return '';

  const splitData = data
    .split(/<\/?p>/)
    .filter(Boolean)
    .reduce((acc: string[], cur: string) => {
      if (!cur.includes('<br>') && cur !== '' && !cur.includes('<img')) {
        return acc.concat(cur);
      }
      return acc;
    }, []);

  const removeHtmlTags = (str: string) => str.replace(/<\/?[^>]+(>|$)/g, '');
  const cleanedData = splitData.map(removeHtmlTags);

  return cleanedData.join('').slice(0, maxSlicer ? maxSlicer : 80);
};

export default recursiveParser;
