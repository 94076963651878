type RowContentProps = {
  title: string;
  content?: string;
  totalCounts?: number;
};
const RowContent = ({ title, content, totalCounts }: RowContentProps) => {
  return content ? (
    <div className="text-[14px] flex justify-start items-center gap-32 text-[#C9C7D1]">
      <p>{title}</p>
      <p>
        {content}
        {totalCounts ? <span> 외 {totalCounts}건</span> : null}
      </p>
    </div>
  ) : null;
};

export default RowContent;
