import { useGetCoachIdFromMyUrl } from '#api/my-url';
import { CoachClinicalDataPage } from '#pages';
import { useState } from 'react';
import { useParams } from 'react-router';

import ActivitiesInformation from 'src/r_page/activties/information';
import ActivitiesQna from 'src/r_page/activties/qna';

import useBranch from 'src/refactor_shared/hooks/useBranch';

const TrainerActivities = () => {
  const { myUrl } = useParams();

  const { data: coachId } = useGetCoachIdFromMyUrl(myUrl ? myUrl : '');
  const [activeTab, setActiveTab] = useState('information');
  const { Branch, Trunk } = useBranch('information');
  return (
    <>
      <div className="px-20 pt-20 flex items-center gap-8 text-[14px]">
        {TAB_LIST.map(tab => (
          <button
            key={tab.key}
            className={`py-8 px-16 border border-solid rounded-full ${
              activeTab === tab.key
                ? 'text-white bg-[#7750E9] border-[#7750E9]'
                : 'text-[#8E899F] border-[#2E2B36]'
            }`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.name}
          </button>
        ))}
      </div>

      <div className="overflow-auto">
        <Trunk branchTarget={activeTab}>
          <Branch branchName="portfolio" children={<CoachClinicalDataPage />} />
          <Branch
            branchName="information"
            children={<ActivitiesInformation id={coachId} />}
          />
          <Branch branchName="qna" children={<ActivitiesQna id={coachId} />} />
        </Trunk>
      </div>
    </>
  );
};

export default TrainerActivities;

const TAB_LIST = [
  // {
  //   name: '트레이닝 성과',
  //   key: 'portfolio'
  // },
  {
    name: '운동 가이드',
    key: 'information'
  },
  {
    name: '질문 답변',
    key: 'qna'
  }
];
