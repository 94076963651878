import TooltipImg from '#assets/svg/tooltip.svg';
import TooltipDown from '#assets/svg/tooltip_down.svg';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

const Tooltip = ({ description }: { description: string }) => {
  const [bubbleAxis, setBubbleAxis] = useState({ top: '', left: '' });

  const tooltipRef = useRef<HTMLDivElement>(null);
  const textBubbleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textBubbleRef?.current && tooltipRef?.current) {
      const textBubbleRect = textBubbleRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      const bubbleTop = textBubbleRect.height + 4;
      const bubbleLeft = Math.floor(tooltipRect.left) + 320 - window.innerWidth;

      setBubbleAxis({
        top: bubbleTop.toString(),
        left: bubbleLeft > 10 ? bubbleLeft.toString() : '10'
      });
    }
  }, [textBubbleRef, tooltipRef]);

  return (
    <div
      className="flex flex-col-reverse items-start relative w-fit"
      ref={tooltipRef}
    >
      <img className="peer" src={TooltipImg} alt="tooltip" />
      <div
        ref={textBubbleRef}
        className={`peer-hover:visible z-2 invisible flex absolute -top-${bubbleAxis.top} w-300 flex-col items-start mb-5`}
      >
        <div className="flex flex-col items-start w-full">
          <p
            className={`text-[12px] bg-[#1F1E25] px-10 py-6 rounded-8 -translate-x-${bubbleAxis.left}`}
          >
            {description}
          </p>
          <img className="ml-1" src={TooltipDown} alt="tooltip-down" />
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
