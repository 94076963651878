export const SCHEDULE_REGISTRATION_TAB_LIST = [
  {
    label: '질문 답변',
    pathname: '/community/qna',
    route: 'qna'
  },
  {
    label: '운동 가이드',
    pathname: '/community/information',
    route: 'information'
  }
];
