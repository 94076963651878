import { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';

import XButton from 'src/assets/svg/Vector.svg';

import { HEALTH_INFO_REGISTERNAMES } from 'src/r_page/Information-community/constant';

const WriteHealthInfo = ({
  setAdditionalHealthInfoModalOpen
}: {
  setAdditionalHealthInfoModalOpen: Dispatch<SetStateAction<string>>;
}) => {
  const methods = useFormContext();

  return (
    <>
      {HEALTH_INFO_REGISTERNAMES.map(({ label, registerName }) => (
        <div
          className="py-24 flex items-center justify-between border-[#1F1E25] border-solid border-b-1"
          key={label}
        >
          <span className="text-[#756F7E] text-[14px]">{label}</span>
          <div className="flex flex-col items-end gap-8">
            {methods
              .getValues(registerName)
              .map((data: { id: string; name: string }) => (
                <div className="flex items-center gap-8" key={data.id}>
                  <button type="button">
                    <img src={XButton} />
                  </button>
                  <div className="px-16 py-8 bg-[#4D495A] rounded-8 font-normal text-[#D7D3DE]">
                    {data.name}
                  </div>
                </div>
              ))}
            <button
              className="w-74 h-37 bg-[#2E2B36] text-[#C9C7D1] text-center rounded-8"
              onClick={() => setAdditionalHealthInfoModalOpen(registerName)}
            >
              + 추가
            </button>
          </div>
        </div>
      ))}
    </>
  );
};

export default WriteHealthInfo;
