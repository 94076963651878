import { useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { axios } from '#api/axios';
import CommunityTitle from 'src/refactor_components/community/threads/community-title';
import CommunityProfile from 'src/refactor_components/community/threads/community-profile';
import CommunityContents from 'src/refactor_components/community/threads/community-contents';
import InformationCommunityOptions from 'src/refactor_components/community/threads/information-community-options';
import QuestionerInfo from 'src/r_page/community/CommunityDetail/QuestionerInfo';
import QuestionComment from 'src/r_page/community/CommunityDetail/QuestionComments';
import QnaThreadNavigation, {
  UserQnaThreadNavigation
} from 'src/refactor_components/community/threads/qna-thread-navigation';
import { IHealthInfo } from '#types/account';
import { PrevHeaderBarWithTitleTemplate } from '#templates';
import QuestionBar from '../Answer/QuestionBar';
import { useToast } from '#providers/ToastProvider';
import { usePostQnaLikesMutaiton } from '#api/community';
import { useUserInfo } from '#providers/UserInfo';
import { BoxIcon } from '#atoms';
import { isUser } from '#utils/isUser';
import { StringChain } from 'lodash';

interface QnaCommunityType {
  id: string;
  writerId: string;
  category: string;
  sequence: number;
  title: string;
  content: string;
  createdAt: string;
  isBookmarked: boolean;
  isLiked: boolean;
  age: string;
  account: {
    nickname: string;
    startAthletic: string;
    sex: 'FEMALE' | 'MALE';
    regionSigu: string;
  };
  healthInfo: IHealthInfo;

  isOp: boolean;
  profileImage?: string;
}

interface Answer {
  id: string;
  writerId: string;
  sequence: 8;
  sort: 7;
  name: string;
  shortIntroduction: string;
  depth: string;
  content: string;
  likeCount: number;
  createdAt: string;
  profile: string | null;
  account: {
    regionSigu: string;
  };
  isLike: boolean;
  myUrl: string;
  _count: {
    likes: number;
  };
  image: {
    resizedLink: string;
  };
}

const QnaCommunityFeed = () => {
  const methods = useForm();
  const { id } = useParams();
  const { userInfo, isLogIn } = useUserInfo();

  const [isModalOpen, setIsModalOpen] = useState('');
  const { data } = useQuery({
    queryKey: ['get-qna-community-question', id],
    queryFn: async () =>
      await axios.get<QnaCommunityType>(
        `/consumer/qna/questions/detail?questionId=${id}`
      ),
    enabled: isLogIn ? !!userInfo?.id && !!id : !!id,
    select: ({ data }) => data
  });

  const { data: answer } = useQuery({
    queryKey: ['get-qna-community-question-answer', id],
    queryFn: async () =>
      await axios.get<Answer[]>(`/consumer/qna/answers`, {
        params: {
          // userId: userInfo?.id ?? id,
          questionId: id,
          limit: 1000
        }
      }),
    enabled: isLogIn ? !!userInfo?.id && !!id : !!id,
    select: ({ data }) => data
  });

  if (!data) return null;

  return (
    <PrevHeaderBarWithTitleTemplate
      defaultBackground
      title=""
      prevRoute="../."
      rightContents={
        data.isOp && (
          <button onClick={() => setIsModalOpen('open')}>
            <BoxIcon
              type="dots_vertical_rounded"
              boxColor="transparent"
              size={18}
              onlyIcon={true}
            />
          </button>
        )
      }
    >
      <div className="relative">
        <FormProvider {...methods}>
          <div className="flex flex-col w-full gap-20 pb-100">
            <CommunityTitle category={data.category} title={data.title} />
            <CommunityProfile createdAt={data.createdAt}>
              <CommunityProfile.Consumer
                profileImage={data.profileImage}
                consumerName={data?.account?.nickname}
                addres={data?.account?.regionSigu}
              />
            </CommunityProfile>
            <QuestionerInfo
              data={data?.healthInfo}
              age={data?.age}
              sex={data?.account?.sex}
            />
            <CommunityContents content={data.content} />
            <QuestionComment>
              {answer &&
                answer.map(value => {
                  return (
                    <QuestionComment.Wrap key={value.id}>
                      <QuestionComment.Profile
                        profileImage={value.profile}
                        questionerName={value.name}
                        shortIntroduction={value.shortIntroduction}
                        createdAt={value.createdAt}
                        myUrl={value.myUrl}
                      />
                      <QuestionComment.Content content={value.content} />
                      <QuestionBar
                        postId={data.id}
                        targetId={value.id}
                        isLike={value.isLike}
                      />
                    </QuestionComment.Wrap>
                  );
                })}
            </QuestionComment>
          </div>
          <div className="fixed bottom-0 w-full">
            {isUser() ? (
              <UserQnaThreadNavigation
                isBookmarked={data.isBookmarked}
                isLike={data.isLiked}
              />
            ) : (
              <QnaThreadNavigation />
            )}
          </div>
          {isModalOpen && (
            <InformationCommunityOptions
              data={data}
              setIsModalOpen={setIsModalOpen}
            />
          )}
        </FormProvider>
      </div>
    </PrevHeaderBarWithTitleTemplate>
  );
};

export default QnaCommunityFeed;
