import { BoxIcon } from '#atoms';
import { ReactNode } from 'react';
import recursiveParser from 'src/refactor_shared/lib/recursive-parser';
import EmptyContent from 'src/refactor_shared/ui/empty-content';
import Portfolio from '../my-page/profile/portfolio';
import { Link } from 'react-router-dom';

const TrainerActivities = ({
  name,
  children,
  isVisible
}: {
  name: string;
  children: ReactNode;
  isVisible: boolean;
}) => {
  return isVisible ? (
    <div className="flex flex-col gap-20">
      <p className="text-[20px] font-bold">
        {name} 트레이너의
        <br />
        트레이닝 활동
      </p>
      {children}
    </div>
  ) : (
    <></>
  );
};

const TrainerActivitiesPortfolio = ({
  clinicalDataList
}: {
  clinicalDataList: { data: object[] };
}) => {
  return clinicalDataList && clinicalDataList.data.length > 0 ? (
    <div className="flex flex-col gap-20">
      <p>
        트레이닝 성과{' '}
        <span>
          {clinicalDataList?.data ? clinicalDataList?.data?.length : 0}
        </span>
      </p>
      {/* <Portfolio clinicalDataList={clinicalDataList.data} /> */}
    </div>
  ) : null;
};

type TrainerActivitiesInformationProps = {
  title: string;
  content: string;
  _count: {
    comments: number;
    likes: number;
  };
  image: {
    resizedLink: string;
  };
  id: string;
};

const TrainerActivitiesInformation = ({
  data
}: {
  data: TrainerActivitiesInformationProps[];
}) => {
  return data && data?.length ? (
    <div className="flex flex-col gap-20">
      <p>
        운동 가이드 <span>{data ? data?.length : 0}</span>
      </p>
      <div className="overflow-x-auto w-full flex items-center gap-12">
        {data.map(item => (
          <Link
            to={`/community/information/${item.id}`}
            className="flex items-center gap-12 max-w-320"
          >
            <div className="p-16 rounded-8 bg-[#1F1E25] text-[14px] text-[#8E899F] min-w-[320px] w-full">
              <div className="flex justify-between items-center gap-12 pb-10 mb-10 border-b-1 border-solid border-[#24222A]">
                <div className="flex flex-col gap-6 max-w-[calc(100%-80px)]">
                  <p className="text-white text-[16px]">{item.title}</p>
                  <p
                    className="line-clamp-2"
                    dangerouslySetInnerHTML={{
                      __html: recursiveParser(item.content, 400)
                    }}
                  />
                </div>
                {item.image && (
                  <img
                    src={item.image.resizedLink}
                    className="rounded-8 w-64 h-64 object-cover"
                  />
                )}
              </div>
              <div className="flex justify-end items-center gap-12">
                <div className="flex items-center gap-6">
                  <BoxIcon type="comment-sharp" onlyIcon size={14} />
                  <p>{item._count.comments}</p>
                </div>
                <div className="flex items-center gap-6">
                  <BoxIcon type="thumb-up-alt" onlyIcon size={14} />
                  <p>{item._count.likes}</p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  ) : null;
};

const TrainerActivitiesQna = ({
  data
}: {
  data: { id: string; questionTitle: string; content: string }[];
}) => {
  return data && data?.length ? (
    <div className="flex flex-col gap-20">
      <p>
        질문 답변 <span>{data ? data.length : 0}</span>
      </p>
      <div className="overflow-x-auto flex items-center gap-12">
        {
          data.map(item => (
            <Link
              to={`/community/qna/${item.id}`}
              className="flex items-center gap-12 h-112"
            >
              <div className="flex flex-col gap-6 p-16 rounded-8 rounded-bl-0 bg-[#1F1E25] text-[14px] text-[#8E899F] min-w-[200px] w-full h-full">
                <div className="flex gap-6">
                  <p className="text-[#5C576B]">Q.</p>
                  <p className="line-clamp-1">{item.questionTitle}</p>
                </div>
                <div className="flex gap-6">
                  <p className="text-[#9170F2]">A.</p>
                  <p
                    className="line-clamp-3"
                    dangerouslySetInnerHTML={{
                      __html: recursiveParser(item.content, 400)
                    }}
                  />
                </div>
              </div>
            </Link>
          ))
          // <EmptyContent>등록된 질문 답변이 없습니다.</EmptyContent>
        }
      </div>
    </div>
  ) : null;
};

TrainerActivities.port = TrainerActivitiesPortfolio;
TrainerActivities.info = TrainerActivitiesInformation;
TrainerActivities.qna = TrainerActivitiesQna;

export default TrainerActivities;
