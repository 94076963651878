import { ReactNode } from 'react';
interface Props {
  children: ReactNode;
  sectionTitle: string | ReactNode;
  rightContent?: ReactNode;
}
const Section = ({ children, sectionTitle, rightContent }: Props) => {
  return (
    <div className="flex flex-col gap-[16px]">
      <div className="flex items-center justify-between">
        <h3 className="text-[18px] font-medium leading-24 text-[#fff]">
          {sectionTitle}
        </h3>
        {rightContent}
      </div>
      {children}
    </div>
  );
};

export default Section;
