import styled from 'styled-components';

export const LecturePlacePageWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LecturePlacePageArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
`;

export const ImageList = styled.ul`
  display: flex;
  gap: 0.75rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const ImageItem = styled.li`
  width: 9.25rem;
  height: 9.25rem;

  img {
    width: 9.25rem;
    height: 9.25rem;
    object-fit: cover;
    border-radius: 0.5rem;
  }
`;

export const MainInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const PlaceInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 0;
`;

export const MapContainer = styled.div`
  width: 100%;
  height: 28rem;
  border-radius: 0.8rem;
  overflow: hidden;
  z-index: 0;
`;

export const GymItemsContainer = styled.div`
  display: flex;
  gap: 1rem;

  > div {
    position: relative;
  }

  > div:hover .label {
    display: block;
  }
`;

export const GymItemLabel = styled.p`
  display: none;
  position: absolute;
  top: 80%;
  left: -50%;
  padding: 0.4rem 0.6rem;
  width: max-content;
  border-radius: 5px;
  background-color: var(--color-border);
`;

export const LabelBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Line = styled.div`
  border-top: 1px solid #24222a;
  height: 0;
`;

export const MoreBtn = styled.span<{ isMore: boolean }>`
  font-size: 14px;
  text-decoration-line: underline;
  color: ${({ isMore }) => (isMore ? '#8E899F' : '#7750E9')};
`;

export const MoreBox = styled.div<{ isMore: boolean }>`
  display: ${({ isMore }) => (isMore ? 'flex' : 'none')};
  flex-direction: column;
  gap: 2rem;
`;
