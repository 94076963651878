import { useToast } from '#providers/ToastProvider';

export const useShareTharedUrl = () => {
  const { showToast } = useToast();

  return (url: string, message: string) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          showToast({ message });
        })
        .catch(() => {
          showToast({
            type: 'fail',
            message: '복사에 실패했습니다. 다시 시도해주세요.'
          });
        });
    } else {
      showToast({
        type: 'fail',
        message: '클립보드 API를 지원하지 않습니다.'
      });
    }
  };
};
