import SearchIcon from 'src/r_page/community/Icon/SearchIcon';
import SearchBarCloseIcon from './SearchBarCloseIcon';

interface Props {
  setSearchData: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
}

const SearchBar = ({ setSearchData, placeholder }: Props) => {
  return (
    <div className="w-full h-40 flex items-center  px-16 py-4 rounded-16 bg-[#1F1E25]">
      <div className="flex items-center w-full h-full gap-12">
        <SearchIcon />
        <input
          onChange={e => setSearchData(e.target.value)}
          placeholder={placeholder}
          className="placeholder-[#C9C7D1]::placeholder text-[#C9C7D1] text-[13px] w-full h-full bg-[#1F1E25]"
        />
      </div>
    </div>
  );
};

export default SearchBar;
