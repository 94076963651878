const WriteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 17.25V21H6.75L17.81 9.93997L14.06 6.18997L3 17.25ZM21.41 6.33997L17.66 2.58997L15.13 5.12997L18.88 8.87997L21.41 6.33997Z"
        fill="#EAE8ED"
      />
    </svg>
  );
};

export default WriteIcon;
