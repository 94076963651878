import Donghwa from 'src/assets/white-list/donghwa.png';
import Hyeonggi from 'src/assets/white-list/hyeonggi.png';
import Yeonggi from 'src/assets/white-list/yeonggi.png';
import Eunhwi from 'src/assets/white-list/eunhwi.png';
import Kyeongmoon from 'src/assets/white-list/kyeongmoon.png';
import Bokyeon from 'src/assets/white-list/bokyeon.png';

export const TrainerWhiteListData = [
  {
    name: '김동화',
    introduce: '물리치료사, 재활병원 출신',
    profileLink: 'https://fibud.kr/expert/shs1623',
    id: 'b25aaba9-0174-4b2d-84fb-89987c8b29d6',
    profileImage: Donghwa,
    tag: ['재활', '통증관리', '다이어트'],
    filterValue: ['physical']
  },
  {
    name: '김형기',
    introduce: '한체대 운동건강관리 석사',
    profileLink: 'https://fibud.kr/expert/ki_fit94',
    id: 'e5463dc5-256f-4163-8f9e-1559c4840045',
    profileImage: Hyeonggi,
    tag: ['기능성 트레이닝', '보디빌딩', '체형교정'],
    filterValue: ['master', 'national']
  },
  {
    name: '조영기',
    introduce: '3대 재벌 전담 트레이너',
    profileLink: 'https://fibud.kr/expert/whdudrl76',
    id: '85f1de05-1407-476a-9440-8327db01e3a5',
    profileImage: Yeonggi,
    tag: ['재활', '통증관리', '다이어트'],
    filterValue: ['caareer']
  },
  {
    name: '정은휘',
    introduce: '서울대 인간운동과학 석사',
    profileLink: 'https://fibud.kr/expert/hwicoach',
    id: '4ea0f15c-6f3f-40e0-ae5e-0b0c454bd618',
    profileImage: Eunhwi,
    tag: ['보디빌딩', '재활', '통증관리'],
    filterValue: ['master', 'career', 'exercise']
  },
  {
    name: '남경문',
    introduce: '10년 차 상위 1% 운동 전문가',
    profileLink: 'https://fibud.kr/expert/victornam',
    id: '33fafb1e-6021-4868-86e6-ed472f344122',
    profileImage: Kyeongmoon,
    tag: ['기능성 트레이닝', '다이어트', '체형교정'],
    filterValue: ['career', 'national']
  },
  {
    name: '정복연',
    introduce: '경희대 스포츠의과학 석사',
    profileLink: 'https://fibud.kr/expert/jungbokyoun',
    id: '63a80682-395c-4885-889d-54ec4e201146',
    profileImage: Bokyeon,
    tag: ['보디빌딩', '다이어트', '체형교정'],
    filterValue: ['master', 'career', 'exercise']
  }
];
