import { axios } from '#api/axios';
import { UseMutateFunction, useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

const useThreadLikeMutations = <T extends boolean>(
  url: string,
  isLiked: T
): {
  likeMutate: UseMutateFunction<
    T extends false
      ? AxiosResponse<{ targetId: string; kind: string }>
      : AxiosResponse<string>,
    AxiosError,
    T extends false ? { targetId: string; kind: string } : string,
    unknown
  >;
} => {
  const { mutate } = useMutation<
    T extends false
      ? AxiosResponse<{ targetId: string; kind: string }>
      : AxiosResponse<string>,
    AxiosError,
    T extends false ? { targetId: string; kind: string } : string
  >({
    mutationFn: async payload => {
      if (isLiked) {
        return await axios.delete(`${url}/${payload}`);
      } else {
        return await axios.post(`${url}`, payload);
      }
    }
  });

  return { likeMutate: mutate };
};

export default useThreadLikeMutations;
