export function parseDateToYearMonth(isoString: Date) {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
  return `${year}.${month}`;
}

export function parseDateToYearMonthDay(isoString: Date | string) {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
  const day = String(date.getDate()).padStart(2, '0'); // 일도 2자리로 포맷
  return `${year}.${month}.${day}`;
}
