import { axios } from '#api/axios';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import recursiveParser from 'src/refactor_shared/lib/recursive-parser';
import EmptyContent from 'src/refactor_shared/ui/empty-content';

type InformationListType = {
  id: string;
  title: string;
  content: string;
  image: {
    resizedLink: string;
  };
  _count: {
    comments: number;
    likes: number;
  };
};

const ActivitiesInformation = ({ id }: { id?: string }) => {
  const { data: informationList } = useQuery({
    queryKey: ['get-information-community-coach-questions', id],
    queryFn: async () =>
      await axios.get(`/consumer/exercise/coach/questions/${id}`),
    select: data => data.data,
    enabled: !!id
  });

  return (
    <>
      {informationList?.length ? (
        informationList.map(
          (information: InformationListType, index: number) => (
            <div
              key={index}
              className="p-20 pb-12 flex flex-col gap-12 border-b-1 border-solid border-[#24222A]"
            >
              <Link
                to={`/community/information/${information.id}`}
                className="flex justify-between font-bold gap-12"
              >
                <div>
                  <p>{information.title}</p>
                  <p
                    className="text-ellipsis overflow-hidden line-clamp-2 pt-6 text-[14px] text-[#8E899F]"
                    dangerouslySetInnerHTML={{
                      __html: recursiveParser(information.content, 150)
                    }}
                  />
                </div>
                {information.image && (
                  <img
                    src={information.image.resizedLink}
                    className="w-64 h-64 rounded-8"
                  />
                )}
              </Link>
              <div className="flex justify-between items-center text-[#8E899F] py-7">
                <p className="text-center w-full">
                  댓글 {information._count.comments}
                </p>
                <p className="text-center w-full">
                  좋아요 {information._count.likes}
                </p>
              </div>
            </div>
          )
        )
      ) : (
        <div className="py-60 px-20">
          <EmptyContent>등록된 운동 가이드가 없습니다.</EmptyContent>
        </div>
      )}
    </>
  );
};

export default ActivitiesInformation;
