import { AltImage } from '#assets/svg';
import { useState } from 'react';
import {
  ImageItem,
  ImageList,
  IndexIcon,
  IndexIconArea,
  ProfileViewerWrap
} from './styles';

interface Props {
  imgSrcList: string[];
  handleImageLoaded?: () => void;
  alt?: string;
}

export const ProfileViewer = ({
  imgSrcList,
  handleImageLoaded,
  alt
}: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleScroll: React.UIEventHandler<HTMLUListElement> = e => {
    const newIndex = Math.round(
      e.currentTarget.scrollLeft / e.currentTarget.clientWidth
    );
    setCurrentIndex(newIndex);
  };
  const isLazyImage = (idx: number) => (idx !== 0 ? 'lazy' : 'eager');
  return (
    <ProfileViewerWrap>
      <ImageList onScroll={handleScroll}>
        {imgSrcList.length ? (
          imgSrcList.map((imgSrc, idx) => {
            const isVideo = imgSrc.includes('mp4');

            return (
              <ImageItem key={imgSrc}>
                {isVideo ? (
                  <video
                    src={imgSrc}
                    onLoad={handleImageLoaded}
                    autoPlay
                    muted
                  />
                ) : (
                  <img
                    onLoad={handleImageLoaded}
                    src={imgSrc}
                    loading={isLazyImage(idx)}
                    alt={alt || '이미지'}
                  />
                )}
              </ImageItem>
            );
          })
        ) : (
          <ImageItem className="alt">
            <AltImage />
          </ImageItem>
        )}
      </ImageList>
      <IndexIconArea>
        {imgSrcList.map((_, index) => (
          <IndexIcon
            key={index}
            className={index === currentIndex ? 'point' : ''}
          />
        ))}
      </IndexIconArea>
    </ProfileViewerWrap>
  );
};
