import { useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';

import { axios } from '#api/axios';

import CommunityTitle from 'src/refactor_components/community/threads/community-title';
import CommunityProfile from 'src/refactor_components/community/threads/community-profile';
import CommunityContents from 'src/refactor_components/community/threads/community-contents';
import InformationCommunityComments from 'src/refactor_components/community/threads/information-community-comments';
import InformationCommunityOptions from 'src/refactor_components/community/threads/information-community-options';
import {
  PrevHeaderBarThreeDotTemplate,
  PrevHeaderBarWithTitleTemplate
} from '#templates';
import { useUserInfo } from '#providers/UserInfo';

interface InformationDetail {
  id: string;
  myUrl: string;
  writerId: string;
  category: 'METHOD';
  sequence: 10;
  title: string;
  content: string;
  createdAt: string;
  name: string;
  startAthletic: Date;
  isBookmarked: boolean;
  likes: number;
  isLike: boolean;
  likeCount: number;
  isOp: boolean;
  image: string;
}

interface Comment {
  id: string;
  writerId: string;
  sequence: number;
  sort: number;
  depth: number;
  content: string;
  createdAt: string;
  name: string;
  startAthletic: Date;
  profile: string | null;
  isLike: boolean;
  likeCount: number;
  _count: {
    answer: number;
    likes: number;
  };
  image: string;
  isOp: boolean;
  likes: [
    {
      id: string;
    }
  ];
}
const InformationCommunityFeed = () => {
  const methods = useForm();
  const { id } = useParams();
  const { userInfo, isLogIn } = useUserInfo();
  const [isModalOpen, setIsModalOpen] = useState('');

  const { data } = useQuery({
    queryKey: ['get-information-community-thread', id],
    queryFn: async () =>
      await axios.get<InformationDetail>(
        `/consumer/exercise/questions/detail?questionId=${id}`
      ),
    enabled: !!id,
    select: ({ data }) => data
  });

  const { data: comment } = useQuery({
    queryKey: ['get-information-community-thread-comment', id],
    queryFn: async () =>
      await axios.get<Comment[]>(`/consumer/exercise/answers`, {
        params: isLogIn
          ? {
              questionId: id,
              userId: userInfo?.id,
              limit: 1000
            }
          : {
              questionId: id,
              limit: 1000
            }
      }),
    enabled: isLogIn ? !!userInfo?.id && !!id : !!id,
    select: ({ data }) => data
  });

  if (!data) return null;

  return (
    <div className="relative">
      <PrevHeaderBarWithTitleTemplate
        title=""
        defaultBackground
        prevRoute="/community/information"
      >
        <FormProvider {...methods}>
          <div className="flex flex-col w-full gap-20 pb-170">
            <CommunityTitle category={data.category} title={data.title} />
            <CommunityProfile createdAt={data.createdAt}>
              <CommunityProfile.Trainer
                profileImage={data.image}
                myUrl={data.myUrl}
                trainerName={data.name}
                startAthletic={data.startAthletic}
              />
            </CommunityProfile>
            <CommunityContents content={data.content} isInformation={true} />
            <InformationCommunityComments />
            {comment &&
              comment.map(value => {
                return value.depth === 1 ? (
                  <InformationCommunityComments.MainThreads
                    key={value.id}
                    targetId={value.id}
                    setIsModalOpen={setIsModalOpen}
                    userName={value.name}
                    userProfile={value.image}
                    content={value.content}
                    createdAt={value.createdAt}
                    sequence={value.sequence}
                    like={value.likeCount}
                    isOp={value.isOp}
                    isLike={value.isLike}
                  />
                ) : (
                  <InformationCommunityComments.SubThreads
                    key={value.id}
                    setIsModalOpen={setIsModalOpen}
                    userName={value.name}
                    userProfile={value.image}
                    content={value.content}
                    createdAt={value.createdAt}
                    sort={value.sort}
                    sequence={value.sequence}
                    isOp={value.isOp}
                  />
                );
              })}
            <InformationCommunityComments.ThreadInput
              receiverId={data.writerId}
              isBookmarked={data.isBookmarked}
              isLike={data.isLike}
              likeCount={data.likeCount}
              questionId={id}
            />
          </div>
          {isModalOpen && (
            <InformationCommunityOptions
              data={data}
              setIsModalOpen={setIsModalOpen}
            />
          )}
        </FormProvider>
      </PrevHeaderBarWithTitleTemplate>
    </div>
  );
};

export default InformationCommunityFeed;
