const RankBadgeIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.50009 7.16671C3.50009 8.73721 4.30721 10.1214 5.52876 10.9266L5.517 10.9647L5.54939 10.9401C6.25479 11.3996 7.09662 11.6667 8.0001 11.6667C8.90086 11.6667 9.74032 11.4012 10.4444 10.9443L10.4782 10.97L10.4659 10.9303C11.6906 10.1256 12.5001 8.73959 12.5001 7.16671C12.5001 4.6839 10.4829 2.66671 8.0001 2.66671C5.51729 2.66671 3.50009 4.6839 3.50009 7.16671ZM8.30312 8.05566L8.00076 7.82528L7.69791 8.05503L7.38986 8.28872L7.51784 7.87422L7.61851 7.5482L7.35162 7.33561L6.93033 7.00004H7.39344H7.75708L7.86911 6.65409L8.0001 6.2496L8.13109 6.65409L8.24313 7.00004H8.60677H9.06503L8.64275 7.33496L8.3748 7.54746L8.4757 7.87422L8.60204 8.2834L8.30312 8.05566ZM4.50009 10.6867V10.4981L4.37555 10.3565C3.62479 9.50283 3.16675 8.39206 3.16675 7.16671C3.16675 4.49619 5.32957 2.33337 8.0001 2.33337C10.6706 2.33337 12.8335 4.49619 12.8335 7.16671C12.8335 8.39206 12.3754 9.50283 11.6247 10.3565L11.5001 10.4981V10.6867V15.1397L8.15822 14.0257L8.0001 13.973L7.84199 14.0257L4.50009 15.1397V10.6867Z"
        fill="#9170F2"
        stroke="#9170F2"
      />
    </svg>
  );
};

export default RankBadgeIcon;
