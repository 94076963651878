import { ReactNode } from 'react';

import { parseExperience } from 'src/refactor_shared/lib/parseExperience';
import { parseDateToYearMonthDay } from 'src/refactor_shared/lib/parseYear';

import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import PlaceholderIcon from '#assets/Icon/PlaceholderIcon';

const CommunityProfile = ({
  children,
  createdAt
}: {
  children: ReactNode;
  createdAt: Date | string;
}) => {
  return (
    <div className="flex items-center justify-between px-20">
      {children}
      <div className="flex gap-[12px] text-[12px] font-normal leading-[1.33333] text-[#5C576B]">
        <span>{parseDateToYearMonthDay(createdAt)}</span>
      </div>
    </div>
  );
};

const Trainer = ({
  trainerName,
  startAthletic,
  myUrl,
  profileImage
}: {
  trainerName: string;
  startAthletic: Date;
  myUrl: string;
  profileImage?: string;
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex gap-[8px] items-center">
      <button onClick={() => navigate(`/expert/${myUrl}`)}>
        {profileImage ? (
          <img
            className="object-cover w-24 h-24 rounded-full"
            src={profileImage}
          />
        ) : (
          <PlaceholderIcon />
        )}
      </button>
      <p className="flex itmes-center gap-8 text-[16px] font-normal leading-[1.42857] tracking-[-0.3px] text-[#C9C7D1]">
        <Link to={`/expert/${myUrl}`}>{trainerName} 트레이너</Link>
        <span className="text-[14px] text-[#8E899F]">
          {parseExperience(startAthletic)}년차
        </span>
      </p>
    </div>
  );
};

const Consumer = ({
  consumerName,
  addres,
  profileImage
}: {
  consumerName: string;
  addres: string;
  profileImage?: string;
}) => {
  return (
    <div className="flex gap-[8px] items-center">
      {profileImage ? (
        <img src={profileImage} className="w-24 h-24 rounded-full" />
      ) : (
        <PlaceholderIcon />
      )}
      <p className="flex itmes-center gap-8 text-[16px] font-normal leading-[1.42857] tracking-[-0.3px] text-[#C9C7D1]">
        {consumerName}
        <span className="text-[14px] text-[#8E899F]">{addres}</span>
      </p>
    </div>
  );
};

CommunityProfile.Trainer = Trainer;
CommunityProfile.Consumer = Consumer;

export default CommunityProfile;
